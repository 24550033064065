import React from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogProps,
  styled,
  IconButton
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { PopupAction } from '../../types/action';
import { PrimaryButton, SecondaryButton } from '../Button';

type PopupProps = {
  title: string;
  content?: string | null;
  closeactiontext: string;
  action: PopupAction;
  styleChoice?: 'style1' | 'style2'; // Voeg de styleChoice prop toe
} & DialogProps;

export const Popup: React.FunctionComponent<PopupProps> = (props: PopupProps) => {
  const { content, closeactiontext, title, action, onClose, styleChoice = 'style1' } = props; // Standaardwaarde toevoegen
  const { onAction, name } = action;

  const handleClosePopup = () => {
    onClose && onClose({}, 'escapeKeyDown');
  };

  // Gebruik een generieke PopupContainer voor styling
  const PopupContainer = styled(Dialog)`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  `;

  const Content = styled(DialogContent)`
    width: 460px;
    height: 100px;
  `;

  const Title = styled(DialogTitle)`
    width: 94%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  `;

  // Aanpassing aan de styling van de buttons om meer tekst te accommoderen
  const StyledSecondaryButton = styled(SecondaryButton)`
    width: auto; /* Zorgt ervoor dat de breedte zich aanpast aan de inhoud */
    min-width: 100px; /* Zorgt voor een minimale breedte */
  `;

  // Vergeet niet de PrimaryButton correctie
  const StyledPrimaryButton = styled(PrimaryButton)`
    min-width: 100px; /* Corrigeert de minWidth stijl */
  `;

  // Render de popup op basis van styleChoice
  switch (styleChoice) {
    case 'style1':
      return (
        <PopupContainer {...props}>
          <Box padding="20px">
            <Title variant="h4">
              {title}
              <IconButton onClick={handleClosePopup}>
                <CloseIcon />
              </IconButton>
            </Title>
            {content && (
              <Content>
                <DialogContentText variant="body1" color="common.black">
                  {content}
                </DialogContentText>
              </Content>
            )}
            <DialogActions>
              {closeactiontext && (
                <SecondaryButton width="100px" onClick={handleClosePopup} content={closeactiontext} />
              )}
              {name && <PrimaryButton min-Width="100px" onClick={onAction} content={name} />}
            </DialogActions>
          </Box>
        </PopupContainer>
      );
    case 'style2':
      return (
        <PopupContainer {...props}>
          <Box padding="20px">
            <Title variant="h4">
              {title}
              <IconButton onClick={handleClosePopup}>
                <CloseIcon />
              </IconButton>
            </Title>
            {content && (
              <Content>
                <DialogContentText variant="body1" color="common.black">
                  {content}
                </DialogContentText>
              </Content>
            )}
            <DialogActions>
              {name && <StyledPrimaryButton onClick={onAction} content={name} />}
              {closeactiontext && <StyledSecondaryButton onClick={handleClosePopup} content={closeactiontext} />}
            </DialogActions>
          </Box>
        </PopupContainer>
      );
    default:
      // Eventueel een default weergave
      return <div>Stijl niet ondersteund.</div>;
  }
};
