import { createHook, createStore, StoreActionApi } from 'react-sweet-state';
import { Location, LocationDataEmergency, LocationResponse } from '../../models';
import { EmergencyInfo } from '../../models/emergency';
import { $get } from '../../utils/http';
import { StatusEmergencyTeam } from './type';
import _ from 'lodash';

type State = {
  emergencyList: EmergencyInfo[];

  locationList: Location[];

  locationsInfo: Location[];
};

const initialState: State = {
  emergencyList: [],

  locationList: [],

  locationsInfo: []
};

export const actions = {
  getLocations:
    (keyword?: string, id?: number) =>
    async ({ setState }: StoreActionApi<State>) => {
      //TODO: will be alinged in https://sioux.atlassian.net/browse/ECA-342
      const response: LocationResponse = await $get('location/all', {
        params: { q: keyword, id: id }
      });
      const { data } = response;

      if (data) {
        setState({ locationList: data.locations });
      }
    },

  getLocationByName:
    (value: string) =>
    async ({ setState }: StoreActionApi<State>) => {
      const response: LocationResponse = await $get('location', {
        params: {
          q: value
        }
      });
      const { data } = response;

      if (data) {
        setState({ locationList: data.locations });
      }
    },

  getEmergencyTeams:
    () =>
    async ({ setState }: StoreActionApi<State>) => {
      const response: LocationDataEmergency = await $get('dashboard/emerencyTeam');

      const { location: locations } = response.data.locationInfo;
      let emergencyMembers: EmergencyInfo[] = [];
      const locationInformation: Location[] = [];

      locations.forEach(location => {
        const teamMembers = location.EmergencyTeam.map(member => ({
          id: member.EmergencyTeamuserId,
          name: member.EmergencyTeamUserName,
          location: location.location,
          status: location.emercengyStatus === 'OK' ? StatusEmergencyTeam.ON : StatusEmergencyTeam.OFF,
          locationId: location.locationId
        }));

        emergencyMembers = [...emergencyMembers, ...teamMembers];

        const locationInfo: Location = {
          id: Number(location.locationId),
          location: location.location,
          minEmergencyTeam: location.totalEmergencyOfficersIn,
          emergencyTeamPersons: location.emergencyTeamOfficers
        };

        locationInformation.push(locationInfo);
      });

      setState({
        emergencyList: _.sortBy(emergencyMembers, [member => Number(member.id)]),
        locationsInfo: locationInformation
      });
    }
};

const Store = createStore({
  initialState,
  actions
});

export const useEmergencyHook = createHook(Store);
