import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Door } from '../../assets/image/door.svg';
import { useDoorHook } from '../Door/store';
import { DoorActions, DoorStatus } from '../Door/types';
import { showErrorToast, showSuccessToast } from '../../components/Toast/actions';
import { $get, $post } from '../../utils/http';

const UnknownBadgesBlock: React.FC = () => {
  const today = new Date().toLocaleDateString('en-GB').replace(/-/g, '/');
  const navigate = useNavigate();
  const [time, setTime] = useState(new Date());
  const [{ doorList }, { getAllDoors, changeDoorStatus }] = useDoorHook();

  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [note, setNote] = useState('');
  const [pendingAction, setPendingAction] = useState<DoorActions | null>(null);
  const [selectedDoorId, setSelectedDoorId] = useState<string | null>(null);

  const sortDoorList = (list: any[]) => {
    return list.sort((a, b) => a.doorName.localeCompare(b.doorName));
  };

  useEffect(() => {
    const fetchAndSortDoors = async () => {
      await getAllDoors();
    };

    fetchAndSortDoors();

    const interval = setInterval(() => {
      fetchAndSortDoors();
    }, 5000);

    return () => clearInterval(interval);
  }, [getAllDoors]);

  useEffect(() => {
    const timer = setInterval(() => setTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; doorId: string | null } | null>(
    null
  );

  const handleContextMenu = (event: React.MouseEvent, doorId: string) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            doorId: doorId
          }
        : null
    );

    // Highlight de rij
    const rows = document.querySelectorAll('.MuiTableRow-root');
    rows.forEach(row => row.classList.remove('highlight'));
    const row = event.currentTarget as HTMLElement;
    row.classList.add('highlight');
  };

  const handleClose = () => {
    setContextMenu(null);

    // Verwijder de highlight van de rijen
    const rows = document.querySelectorAll('.MuiTableRow-root');
    rows.forEach(row => row.classList.remove('highlight'));
  };

  const handleStatusChange = async (action: DoorActions) => {
    if (contextMenu?.doorId) {
      const status = getDoorStatusByAction(action);

      if (action === DoorActions.OPEN_PERMANENT || action === DoorActions.CLOSED_PERMANENT) {
        setPendingAction(action);
        setSelectedDoorId(contextMenu.doorId);
        setNoteDialogOpen(true);
      } else {
        await changeDoorStatus(contextMenu.doorId, status);
        handleClose();
      }
    }
  };

  const getDoorStatusByAction = (action: DoorActions): DoorStatus => {
    switch (action) {
      case DoorActions.OPEN:
        return DoorStatus.OPENING;
      case DoorActions.OPEN_PERMANENT:
        return DoorStatus.OPEN_PERMANENT;
      case DoorActions.CLOSED_PERMANENT:
        return DoorStatus.CLOSED_PERMANENT;
      case DoorActions.RESET_DEFAULT:
        return DoorStatus.CLOSED;
      default:
        return DoorStatus.CLOSED;
    }
  };

  const handleNoteSave = async () => {
    if (selectedDoorId && pendingAction) {
      try {
        await $post(`/objectNote/${selectedDoorId}`, { objectId: selectedDoorId, objectNote: note });
        await changeDoorStatus(selectedDoorId, getDoorStatusByAction(pendingAction));
        showSuccessToast({
          title: 'Success',
          subtitle: 'Note saved successfully'
        });
        setNoteDialogOpen(false);
        setNote('');
        setSelectedDoorId(null);
        setPendingAction(null);
        handleClose();
      } catch (error) {
        showErrorToast({
          title: 'Error',
          subtitle: 'Failed to save note'
        });
      }
    }
  };

  const formattedTime = time.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit'
  });

  return (
    <BlockContainer>
      <Header>
        <TitleContainer>
          <DoorIcon />
          <Title>DOOR SPECIFIC SETTINGS</Title>
        </TitleContainer>
        <HeaderRight>
          <DateText>
            {formattedTime} {today}
          </DateText>
        </HeaderRight>
      </Header>
      <TableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Location</TableHeaderCell>
              <TableHeaderCell>Section</TableHeaderCell>
              <TableHeaderCell>Door</TableHeaderCell>
              <TableHeaderCell>Status</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortDoorList(doorList).map((door, index) => (
              <StyledTableRow key={index} onContextMenu={event => handleContextMenu(event, door.doorId)}>
                <TableCell>{door.doorLocation}</TableCell>
                <TableCell>Voor ingang</TableCell>
                <TableCell>{door.doorName}</TableCell>
                <TableCell>{door.doorStatus}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        PaperProps={{
          style: {
            width: '154px',
            height: 'fit-content',
            padding: '8px 0',
            borderRadius: '4px 0 0 0',
            opacity: 1
          }
        }}>
        <StyledMenuItem onClick={() => handleStatusChange(DoorActions.OPEN)}>Open</StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={() => handleStatusChange(DoorActions.OPEN_PERMANENT)}>Open Permanent</StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={() => handleStatusChange(DoorActions.CLOSED_PERMANENT)}>
          Close Permanent
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={() => handleStatusChange(DoorActions.RESET_DEFAULT)}>Reset Default</StyledMenuItem>
      </Menu>

      <Dialog open={noteDialogOpen} onClose={() => setNoteDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Enter a Note</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Note"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={note}
            onChange={e => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNoteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleNoteSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </BlockContainer>
  );
};

export default UnknownBadgesBlock;

const BlockContainer = styled(Box)`
  width: 545px; /* Exacte breedte */
  height: 450px; /* Exacte hoogte */
  padding: 0px 16px 16px 24px;
  gap: 0px;
  background: #e5e9eb;
  border: 1px solid #d8dfe0;
  border-radius: 4px 0px 0px 0px;
  display: flex;
  flex-direction: column;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
`;

const HeaderRight = styled(Box)`
  display: flex;
  align-items: center;
`;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const DoorIcon = styled(Door)`
  margin-right: 8px;
  width: 20px;
  height: 20px;
`;

const Title = styled(Typography)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  letter-spacing: 0.015em;
  text-align: left;
`;

const DateText = styled(Typography)`
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
`;

const StyledTable = styled(Table)`
  width: 100%;
`;

const TableHeaderCell = styled(MuiTableCell)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
  padding: 6px;
  background-color: white;
`;

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: #f6f9fa;
  }
  &.highlight {
    background-color: #d1ec86;
  }
`;

const TableCell = styled(MuiTableCell)`
  flex: 1;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 6px;
`;

const StyledMenuItem = styled(MenuItem)`
  color: black;
  &:hover {
    color: #96c11f;
  }
`;
