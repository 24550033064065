import React from 'react';
import { Box, Drawer, List, ListItem, ListItemText, Button, styled } from '@mui/material';

interface BlockSelectorProps {
  open: boolean;
  onClose: () => void;
  onSelect: (type: string) => void;
  addedBlocks: string[];
}

const allWidgets = [
  'System Updates',
  'System Alerts',
  'At Present',
  'Emergency Team',
  'Unknown Badges',
  'Door Specific Settings',
  'Expected Visitors',
  'ANPR Camera',
  'Live Cam'
];

const capitalizeFirstWord = (str: string) => {
  const words = str.toLowerCase().split(' ');
  if (words.length > 0) {
    // Maintain "ANPR" in uppercase
    words[0] = words[0] === 'anpr' ? 'ANPR' : words[0][0].toUpperCase() + words[0].substring(1);
  }
  return words.join(' ');
};

const BlockSelector: React.FC<BlockSelectorProps> = ({ open, onClose, onSelect, addedBlocks }) => {
  const availableWidgets = allWidgets.filter(widget => !addedBlocks.includes(widget)).sort();

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <DrawerContent>
        <Box p={2} width={250}>
          <List>
            {availableWidgets.map(widget => (
              <ListItem button key={widget} onClick={() => onSelect(widget)}>
                <ListItemText primary={capitalizeFirstWord(widget)} />
              </ListItem>
            ))}
          </List>
        </Box>
        <CloseButton variant="contained" color="primary" onClick={onClose}>
          Close
        </CloseButton>
      </DrawerContent>
    </Drawer>
  );
};

export default BlockSelector;

const DrawerContent = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 16px;
`;

const CloseButton = styled(Button)`
  width: calc(100% - 32px);
  align-self: center;
  margin-top: auto;
  color: #ffffff;
  background-color: #96c11f;
  &:hover {
    background-color: #85a917;
  }
`;
