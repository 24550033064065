import styled from '@emotion/styled';
import { Box, TableCell, tableCellClasses, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { CellAlignment } from '../../../../types/cell';

type Props = {
  className?: string;
  align?: CellAlignment;
  isHighlightedCell?: boolean;
  value: JSX.Element | string;
  width?: string;
  link?: string | null;
  onContextMenu?: React.MouseEventHandler<HTMLTableCellElement>;
};

const BodyTableCell: React.FunctionComponent<Props> = (props: Props) => {
  const { className, align, isHighlightedCell, value, width, link, onContextMenu } = props;

  return (
    <Cell className={className} align={align} minWidth={width} onContextMenu={onContextMenu}>
      {isHighlightedCell && <HightLightedDot />}

      {link ? <StyledLink to={link}>{value}</StyledLink> : <SimpleText>{value}</SimpleText>}
    </Cell>
  );
};

export default BodyTableCell;

const { grey, primary } = theme.palette;

const { baseMD, inputHeight } = dimensions;

const StyledLink = styled(Link)`
  color: ${primary.dark};
  font-size: 14px;
  font-weight: 600;
`;

const Cell = styled(TableCell)(({ minWidth }: { minWidth: string | undefined }) => ({
  height: inputHeight,
  padding: `0 ${baseMD}`,
  minWidth: minWidth ? minWidth : 'auto',

  [`&.${tableCellClasses.head}`]: {
    color: grey[100]
  }
}));

const HightLightedDot = styled(Box)`
  position: absolute;
  top: 40%;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: ${grey[100]};
`;

const SimpleText = styled(Typography)`
  cursor: context-menu;
`;
