import React from 'react';
import { useTranslation } from 'react-i18next';
import { VisitorInGroup } from '../../../../../models';
import { useUsersHook } from '../../../store';
import { GridTable } from '../../components';
import { TableAction } from '../types';
import _ from 'lodash';
import { Box, styled } from '@mui/material';

type Props = {
  onBelongVisitorIdsChanged: (userIds: string[]) => void;
};

const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Ruimte tussen de tabellen */
`;

const TableContainer = styled(Box)`
  flex: 1;
  min-width: 400px; /* Zorgt ervoor dat de tabellen niet te smal worden */
`;

export const BelongVisitors: React.FunctionComponent<Props> = (props: Props) => {
  const { onBelongVisitorIdsChanged } = props;
  const { t } = useTranslation();

  const [{ groupInformation, existedVisitors }, { getExistedVisitors }] = useUsersHook();
  const { visitorsInGroup } = groupInformation;

  const [belongVisitors, setBelongVisitors] = React.useState<VisitorInGroup[]>([]);
  const [notBelongVisitors, setNotBelongVisitors] = React.useState<VisitorInGroup[]>([]);

  const titles = [
    t('group.visitors_in_group.column.visitor_name'),
    t('group.visitors_in_group.column.location'),
    t('group.visitors_in_group.column.contact_name'),
    t('group.action')
  ];

  const placeholder = t('group.visitors_in_group.placeholder');

  const sortVisitorsByVisitorId = (visitors: VisitorInGroup[]) =>
    visitors.sort((prev, next) => parseInt(prev.userId) - parseInt(next.userId));

  const handleAddVisitorIntoGroup = (visitor: VisitorInGroup) => {
    const newBelongVisitors = [visitor, ...belongVisitors];
    const newNotBelongVisitors = sortVisitorsByVisitorId(
      notBelongVisitors.filter(item => item.userId !== visitor.userId)
    );

    onBelongVisitorIdsChanged(newBelongVisitors.map(item => item.userId));

    setBelongVisitors(newBelongVisitors);
    setNotBelongVisitors(newNotBelongVisitors);
  };

  const handleRemoveVisitorIntoGroup = (visitor: VisitorInGroup) => {
    const newBelongVisitors = belongVisitors.filter(item => item.userId !== visitor.userId);
    const newNotBelongVisitors = [visitor, ...notBelongVisitors];

    onBelongVisitorIdsChanged(newBelongVisitors.map(item => item.userId));

    setNotBelongVisitors(newNotBelongVisitors);
    setBelongVisitors(newBelongVisitors);
  };

  React.useEffect(() => {
    getExistedVisitors();
  }, []);

  React.useEffect(() => {
    if (visitorsInGroup) {
      const notBelongVisitors = sortVisitorsByVisitorId(
        _.differenceBy(existedVisitors, visitorsInGroup.users, 'userId')
      );
      const belongVisitors = visitorsInGroup.users.map(item => ({
        userId: item.userId,
        userName: item.userName,
        locationName: item.locationName,
        contractPersonName: item.contractPersonName // Zorg ervoor dat je dit veld opneemt
      }));

      setNotBelongVisitors(notBelongVisitors);
      setBelongVisitors(belongVisitors);
    } else {
      setNotBelongVisitors(existedVisitors);
    }
  }, [existedVisitors, visitorsInGroup]);

  return (
    <StyledBox>
      <TableContainer>
        <GridTable
          header={t('group.visitors_in_group.belong_to_this_group')}
          titles={titles}
          rows={belongVisitors.map(({ userName, locationName, contractPersonName }) => ({
            userName,
            locationName,
            contractPersonName
          }))}
          actionType={TableAction.REMOVE}
          action={row => {
            const visitor = belongVisitors.find(
              visitor => visitor.userName === row.userName && visitor.locationName === row.locationName
            );
            if (visitor) {
              handleRemoveVisitorIntoGroup(visitor);
            }
          }}
          placeholder={placeholder}
        />
      </TableContainer>
      <TableContainer>
        <GridTable
          header={t('group.visitors_in_group.not_belong_to_this_group')}
          titles={titles}
          rows={notBelongVisitors.map(({ userName, locationName, contractPersonName }) => ({
            userName,
            locationName,
            contractPersonName
          }))}
          actionType={TableAction.ADD}
          action={row => {
            const visitor = notBelongVisitors.find(
              visitor => visitor.userName === row.userName && visitor.locationName === row.locationName
            );
            if (visitor) {
              handleAddVisitorIntoGroup(visitor);
            }
          }}
          placeholder={placeholder}
        />
      </TableContainer>
    </StyledBox>
  );
};
