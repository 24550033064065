import React, { useState, useEffect, MouseEvent } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  Divider
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Visitor } from '../../assets/image/visitor.svg'; // SVG-icoon importeren
import { $get } from '../../utils/http'; // Zorg ervoor dat dit pad correct is
import { showErrorToast } from '../../components/Toast/actions';

interface UnknownBadgesBlockProps {
  locationIds?: number[]; // Optioneel, als geen locatie-IDs worden meegegeven, worden alle locaties opgehaald
}

interface VisitorData {
  time: string;
  location_name: string;
  visitor_name: string;
  visitor_id: string;
  visitor_contactpersonid: string;
  visitor_contactperson: string;
}

const UnknownBadgesBlock: React.FC<UnknownBadgesBlockProps> = ({ locationIds }) => {
  const today = new Date().toLocaleDateString('en-GB').replace(/-/g, '/');
  const navigate = useNavigate();
  const [data, setData] = useState<VisitorData[]>([]); // Set state to array
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; rowIndex: number | null } | null>(
    null
  );
  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Bouw de URL met optionele locatie-IDs
        let url = '/dashboard/expectedVisitors';
        if (locationIds && locationIds.length > 0) {
          url += `?location=${locationIds.join(',')}`;
        }

        console.log('Fetching data from:', url); // Log de URL

        const response = await $get(url);
        console.log('Data received:', response); // Log de ontvangen data

        // Wrap response in an array if it's a single object
        const dataArray = Array.isArray(response) ? response : [response];
        setData(dataArray);
        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to get data'); // Verbeterde foutmelding
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [locationIds]);

  const handleContextMenu = (event: MouseEvent, rowIndex: number) => {
    event.preventDefault();
    setHighlightedRow(rowIndex);
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      rowIndex: rowIndex
    });
  };

  const handleClose = () => {
    setContextMenu(null);
    setHighlightedRow(null);
  };

  const handleOpenVisitor = () => {
    if (contextMenu && contextMenu.rowIndex !== null) {
      const { visitor_id } = data[contextMenu.rowIndex];
      navigate(`/users/visitor/${visitor_id}`); // Pas de navigatie aan indien nodig
    }
    handleClose();
  };

  const handleOpenContact = () => {
    if (contextMenu && contextMenu.rowIndex !== null) {
      const { visitor_contactpersonid } = data[contextMenu.rowIndex];
      navigate(`/users/user/${visitor_contactpersonid}`); // Pas de navigatie aan indien nodig
    }
    handleClose();
  };

  if (loading) {
    return <BlockContainer>Loading...</BlockContainer>;
  }

  if (error) {
    return <BlockContainer>Error: {error}</BlockContainer>;
  }

  return (
    <BlockContainer>
      <Header>
        <TitleContainer>
          <VisitorIcon />
          <Title>EXPECTED VISITORS</Title>
        </TitleContainer>
        <HeaderRight>
          <DateText>{today}</DateText>
        </HeaderRight>
      </Header>
      <TableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Time</TableHeaderCell>
              <TableHeaderCell>Location</TableHeaderCell>
              <TableHeaderCell>Name</TableHeaderCell>
              <TableHeaderCell>Contact Person</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <StyledTableRow
                  key={index}
                  onContextMenu={event => handleContextMenu(event, index)}
                  highlighted={highlightedRow === index}>
                  <StyledTableCell>{row.time}</StyledTableCell>
                  <StyledTableCell>{row.location_name}</StyledTableCell>
                  <StyledTableCell>{row.visitor_name}</StyledTableCell>
                  <StyledTableCell>{row.visitor_contactperson || 'N/A'}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>No data available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        PaperProps={{
          style: {
            width: '220px',
            padding: '8px 0',
            borderRadius: '4px 0 0 0',
            opacity: 1
          }
        }}>
        <StyledMenuItem onClick={handleOpenVisitor}>Open Visitor</StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={handleOpenContact}>Open Contact Person</StyledMenuItem>
      </Menu>
    </BlockContainer>
  );
};

export default UnknownBadgesBlock;

// Styled components
const BlockContainer = styled(Box)`
  width: 545px; /* Behoud de breedte van de originele UnknownBadgesBlock */
  height: 450px; /* Behoud de hoogte van de originele UnknownBadgesBlock */
  padding: 0px 16px 16px 24px;
  background: #e5e9eb;
  border: 1px solid #d8dfe0;
  border-radius: 4px 0px 0px 0px;
  display: flex;
  flex-direction: column;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
`;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const VisitorIcon = styled(Visitor)`
  margin-right: 8px;
`;

const HeaderRight = styled(Box)`
  display: flex;
  align-items: center;
`;

const Title = styled(Typography)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
`;

const DateText = styled(Typography)`
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  text-align: left;
`;

const StyledTable = styled(Table)`
  width: 100%;
`;

const TableHeaderCell = styled(MuiTableCell)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
  padding: 8px;
  background-color: white;
`;

const StyledTableRow = styled(TableRow)<{ highlighted?: boolean }>`
  background-color: ${({ highlighted }) => (highlighted ? '#D1EC86' : 'inherit')};
  &:nth-of-type(even) {
    background-color: ${({ highlighted }) => (highlighted ? '#D1EC86' : '#f6f9fa')};
  }
`;

const StyledTableCell = styled(MuiTableCell)`
  flex: 1;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 8px;
`;

const StyledMenuItem = styled(MenuItem)`
  color: black;
  &:hover {
    color: #96c11f;
  }
`;

const TableCell = styled(MuiTableCell)`
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 8px;
`;
