import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import enLocale from './locales/en.json';
import nlLocale from './locales/nl.json';
import deLocale from './locales/de.json';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enLocale
      },
      nl: {
        translation: nlLocale
      },
      de: {
        translation: deLocale
      }
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false
    }
  });

// Controleer en stel de standaardtaal in als deze niet is ingesteld
const storedLanguage = localStorage.getItem('i18nextLng');
if (!storedLanguage) {
  localStorage.setItem('i18nextLng', 'en');
  i18n.changeLanguage('en');
}

export default i18n;
