import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import WarningAmber from '@mui/icons-material/WarningAmber';
import { $get } from '../../utils/http';

interface AtPresentBlockProps {
  locationIds: number[];
}

const AtPresentBlock: React.FC<AtPresentBlockProps> = ({ locationIds }) => {
  const [employeeCount, setEmployeeCount] = useState<number>(0);
  const [visitorCount, setVisitorCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        let url = '/dashboard/atPresent';
        const params: Record<string, string> = locationIds.length > 0 ? { location: locationIds.join(',') } : {};

        if (Object.keys(params).length > 0) {
          const queryString = new URLSearchParams(params).toString();
          url += `?${queryString}`;
        }

        console.log('Fetching data from URL:', url);

        const response = await $get(url);

        // Controleer of response bestaat en de benodigde velden bevat
        if (response && typeof response.employees === 'number' && typeof response.visitors === 'number') {
          setEmployeeCount(response.employees);
          setVisitorCount(response.visitors);
          setError(null);
        } else {
          throw new Error('Invalid response data');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [locationIds]);

  if (loading) {
    return <BlockContainer>Loading...</BlockContainer>;
  }

  if (error) {
    return <BlockContainer>Error: {error}</BlockContainer>;
  }

  return (
    <BlockContainer>
      <TitleContainer>
        <WarningAmberIcon />
        <Title>AT PRESENT</Title>
      </TitleContainer>
      <ContentContainer>
        <WhiteBlock>
          <NumberAndText>
            <Number>{employeeCount}</Number>
            <Text>Employees</Text>
          </NumberAndText>
        </WhiteBlock>
        <WhiteBlock>
          <NumberAndText>
            <Number>{visitorCount}</Number>
            <Text>Visitors</Text>
          </NumberAndText>
        </WhiteBlock>
      </ContentContainer>
    </BlockContainer>
  );
};

export default AtPresentBlock;

const BlockContainer = styled(Box)`
  width: 350px;
  height: 75px;
  padding: 16px 20px;
  gap: 8px;
  background: #e5e9eb;
  border: 1px solid #d8dfe0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled(Typography)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  letter-spacing: 0.015em;
  text-align: left;
`;

const ContentContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const WhiteBlock = styled(Box)`
  width: 150px;
  height: 30px;
  padding: 8px;
  gap: 14px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8dfe0;
`;

const NumberAndText = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Number = styled(Typography)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: center;
`;

const Text = styled(Typography)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
`;

const WarningAmberIcon = styled(WarningAmber)`
  color: #000000;
  margin-right: 8px;
`;
