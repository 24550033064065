import { InputLabel, styled } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { LocationDropBox } from '../../components';
import { useUsersStates } from '../../store';
import { BLANK_STRING } from '../../types';
import { FormActionType } from '../type';
import { useAppContext } from '../../../../appContext';

type Props = {
  formActionType: FormActionType;
  setLoading: (value: boolean) => void;
  required?: boolean; // Voeg de required prop toe
};

export const LocationForm: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { locations } = useAppContext();
  const { visitorInfo } = useUsersStates();
  const { formActionType, setLoading, required = false } = props; // Destructureer de required prop

  let defaultValue = BLANK_STRING;

  if (visitorInfo) {
    defaultValue = locations.find(item => item.id === Number(visitorInfo.location))?.location ?? BLANK_STRING;
  }

  return (
    <InfoWrapper>
      <Label shrink={false} required={required}>
        {t('visitor.location')}
      </Label>

      <LocationDropBox
        width="74%"
        label={t('location.label')}
        name="location"
        defaultValue={formActionType === FormActionType.EDIT ? defaultValue : BLANK_STRING}
        setLoading={setLoading}
        required={required} // Geef de required prop door
      />
    </InfoWrapper>
  );
};

const { base2XL } = dimensions;
const { grey, error } = theme.palette;

const InfoWrapper = styled(Box)`
  height: ${base2XL};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Label = styled(InputLabel)`
  color: ${grey[200]};
  width: 40%;
  .MuiInputLabel-asterisk {
    color: ${error.main} !important;
  }
`;
