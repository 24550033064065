import { useEffect } from 'react';
import { createStore, StoreActionApi, createHook, createActionsHook } from 'react-sweet-state';
import { Door, GetAllDoorsResponse } from '../../models/door';
import { DoorStatus } from './types';
import { $get, $put } from '../../utils/http';

type State = {
  doorList: Door[];
};

const initialState: State = {
  doorList: []
};

export const actions = {
  getAllDoors:
    () =>
    async ({ setState }: StoreActionApi<State>) => {
      const doorResponse: GetAllDoorsResponse = await $get('door/list');

      const { data } = doorResponse.objects;

      if (data) setState({ doorList: data });
    },

  changeDoorStatus:
    (id: string, status: DoorStatus) =>
    async ({ setState, getState }: StoreActionApi<State>) => {
      let doorStatus = 0;

      switch (status) {
        case DoorStatus.CLOSED: {
          doorStatus = 0;
          break;
        }
        case DoorStatus.OPENING: {
          doorStatus = 1;
          break;
        }
        case DoorStatus.OPEN_PERMANENT: {
          doorStatus = 2;
          break;
        }
        case DoorStatus.CLOSED_PERMANENT: {
          doorStatus = 3;
          break;
        }
        case DoorStatus.DISCONNECTED: {
          doorStatus = 4;
          break;
        }
      }

      await $put('admin/doorStatus', {
        doorStatus,
        doorId: id
      });

      const newDoors = getState().doorList.map(door => {
        if (door.doorId === id)
          return {
            ...door,
            doorStatus: status
          };
        return door;
      });

      setState({
        doorList: newDoors
      });
    }
};

const Store = createStore({
  initialState,
  actions
});

export const useDoorHook = () => {
  const hook = createHook(Store)();
  const [state, actions] = hook;

  useEffect(() => {
    actions.getAllDoors(); // Automatisch de deuren ophalen bij het mounten van de component
  }, [actions]);

  return hook;
};

export const useDoorActions = createActionsHook(Store);
