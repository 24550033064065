import { StorageKeys } from '../../../types';
import { Location } from '../../../models';
import { FilterLevel } from './types';

export const getDoorLocationIds = () => {
  const storageLocations = sessionStorage.getItem(StorageKeys.LOCATIONS);

  const doorLocationIds = storageLocations ? (JSON.parse(storageLocations) as Location[]).map(item => item.id) : [];

  return doorLocationIds.length > 0 ? doorLocationIds.join(',') : null;
};

export const getLogLevel = (): number => {
  const storageLogLevel = sessionStorage.getItem(StorageKeys.LOG_LEVEL);

  if (!storageLogLevel) return 1;

  const level = JSON.parse(storageLogLevel) as FilterLevel;
  switch (level) {
    case FilterLevel.ALL:
      return 1;
    case FilterLevel.ALARMS_WARNINGS:
      return 2;
    case FilterLevel.ALARMS:
      return 3;
    case FilterLevel.WARNINGS:
      return 4;
    default:
      return 1;
  }
};
