import { ReactNode } from 'react';
import MyProfile from '../modules/MyProfile';

export enum SideBarItemType {
  Dashboard = '',
  User = 'user',
  Visitor = 'visitor',
  Group = 'group',
  Location = 'location',
  AdminLocation = 'location',
  Report = 'report',
  Door = 'door',
  EmergencyTeam = 'emergencyTeam',
  MyProfile = 'myprofile',
  TranslationManager = 'TranslationManager',
  AdminSettings = 'adminSettings',
  DataManagement = 'dataManagement',
  Camera = 'camera',
  TempBadges = 'tempBadges'
}

export type SideBarItem = {
  type: SideBarItemType;
  icon: ReactNode;
  title: string;
  linkTo: string;
};
