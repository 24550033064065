import { Box, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../../components/Input';
import { dimensions } from '../../../../styles/dimensions';
import { KeyBoard } from '../../../../types/keyboard';
import { useLocationStates } from '../store';
import { BLANK_STRING } from '../type';

type Props = {
  isEditing?: boolean;
};

export const Body: React.FunctionComponent<Props> = ({ isEditing }: Props) => {
  const { t } = useTranslation();

  const { locationInfo } = useLocationStates();
  const isNumber = (value: string) => (value === null || value === '' ? false : !isNaN(Number(value)));

  return (
    <UserInfoWrapper>
      <TextInput
        required={true}
        label={t('location.location_name.label')}
        placeholder={t('location.location_name.placeholder')}
        inputwidth="70%"
        name="name"
        defaultValue={isEditing ? locationInfo.location : BLANK_STRING}
      />

      <TextInput
        label={t('location.min_emergency_team')}
        placeholder="0"
        inputwidth="70%"
        name="minEmergencyTeam"
        onKeyDown={e => {
          !isNumber(e.key) && e.key !== KeyBoard.BACKSPACE && e.preventDefault();
        }}
        onPaste={e => e.preventDefault()}
        defaultValue={isEditing ? locationInfo.minEmergencyTeam : BLANK_STRING}
      />

      <TextInput
        label={t('location.emergency_team_persons')}
        placeholder="0"
        inputwidth="70%"
        name="emergencyTeamPersons"
        onKeyDown={e => {
          !isNumber(e.key) && e.key !== KeyBoard.BACKSPACE && e.preventDefault();
        }}
        onPaste={e => e.preventDefault()}
        defaultValue={isEditing ? locationInfo.emergencyTeamPersons : BLANK_STRING}
      />
    </UserInfoWrapper>
  );
};

const { base, baseLG } = dimensions;

const UserInfoWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: ${baseLG};
  row-gap: ${base};
  width: 30%;
  min-width: 500px;
`;
