import React from 'react';

import { useTranslation } from 'react-i18next';

import { CheckboxInput } from '../../../../components/Input';
import { BooleanValue } from '../../../../types';
import { useUsersStates } from '../../store';

type Props = {
  onAccessChanged: (value: boolean) => void;
  defaultEnabled?: boolean;
};

const TemporaryAccessCheckbox: React.FunctionComponent<Props> = (props: Props) => {
  const { onAccessChanged, defaultEnabled = false } = props;

  const { t } = useTranslation();

  const { groupInformation } = useUsersStates();

  return (
    <>
      <CheckboxInput
        label={t('group.ask_for_temporary_group_access')}
        defaultValue={groupInformation.groupTmpAccessEnabled === '1' ? BooleanValue.TRUE : BooleanValue.FALSE}
        onChange={(_e, checked) => {
          onAccessChanged(checked);
        }}
      />
    </>
  );
};

export default TemporaryAccessCheckbox;
