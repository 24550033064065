// EmergencyTeamBlock.tsx
import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import WarningAmber from '@mui/icons-material/WarningAmber';
import { $get } from '../../utils/http';

interface EmergencyTeamBlockProps {
  locationIds: number[];
}

const EmergencyTeamBlock: React.FC<EmergencyTeamBlockProps> = ({ locationIds }) => {
  const [presentCount, setPresentCount] = useState<number>(0);
  const [requiredCount, setRequiredCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Alleen setLoading(true) bij initiële load
        if (loading) {
          setLoading(true);
        }

        const baseUrl = '/dashboard/emergencyTeam';
        const url = buildUrl(baseUrl, locationIds);

        const response = await $get(url);

        if (response && typeof response.present === 'number' && typeof response.required === 'number') {
          // Alleen updaten als de data daadwerkelijk veranderd is
          setPresentCount(prev => (prev !== response.present ? response.present : prev));
          setRequiredCount(prev => (prev !== response.required ? response.required : prev));
          setError(null);
        } else {
          throw new Error('Invalid response data');
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };

    // Initial fetch
    fetchData();

    // Set up interval for polling every 5 seconds
    const intervalId = setInterval(fetchData, 5000);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [locationIds, loading]);

  if (loading) {
    return <BlockContainer>Loading...</BlockContainer>;
  }

  if (error) {
    return <BlockContainer>Error: {error}</BlockContainer>;
  }

  // Determine if AlertBox should be active (red) or not (green)
  const isActive = requiredCount > presentCount;

  return (
    <BlockContainer>
      <TitleContainer>
        <WarningAmberIcon />
        <Title>EMERGENCY TEAM</Title>
      </TitleContainer>
      <ContentContainer>
        <WhiteBlock>
          <NumberAndText>
            <Number>{presentCount}</Number>
            <Text>Present</Text>
          </NumberAndText>
        </WhiteBlock>
        <AlertBox
          isActive={isActive}
          defaultColor="#F2FBD7"
          defaultBorderColor="#96C11F"
          activeColor="#FFE1D8"
          activeBorderColor="#FF3D00">
          <NumberAndText>
            <Number>{requiredCount}</Number>
            <Text>Required</Text>
          </NumberAndText>
        </AlertBox>
      </ContentContainer>
    </BlockContainer>
  );
};

export default EmergencyTeamBlock;

const buildUrl = (baseUrl: string, locationIds: number[]): string => {
  let url = baseUrl;

  if (locationIds.length > 0) {
    const params = { location: locationIds.join(',') };
    const queryString = new URLSearchParams(params).toString();
    url += `?${queryString}`;
  }

  return url;
};

// Styled Components
const BlockContainer = styled(Box)`
  width: 350px;
  height: 75px;
  padding: 16px 20px;
  gap: 8px;
  background: #e5e9eb;
  border: 1px solid #d8dfe0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled(Typography)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  letter-spacing: 0.015em;
  text-align: left;
`;

const ContentContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const WhiteBlock = styled(Box)`
  width: 150px;
  height: 30px;
  padding: 8px;
  gap: 14px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8dfe0;
`;

const NumberAndText = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const Number = styled(Typography)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 700;
  line-height: 38.4px;
  text-align: center;
`;

const Text = styled(Typography)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
`;

// Updated AlertBox Component
interface AlertBoxProps {
  children: React.ReactNode;
  isActive: boolean;
  defaultColor: string;
  defaultBorderColor: string;
  activeColor: string;
  activeBorderColor: string;
}

const AlertBox: React.FC<AlertBoxProps> = ({
  children,
  isActive,
  defaultColor,
  defaultBorderColor,
  activeColor,
  activeBorderColor
}) => {
  return (
    <StyledAlertBox
      sx={{
        backgroundColor: isActive ? activeColor : defaultColor,
        borderColor: isActive ? activeBorderColor : defaultBorderColor
      }}>
      {children}
    </StyledAlertBox>
  );
};

const StyledAlertBox = styled(Box)`
  width: 150px;
  height: 30px;
  padding: 8px;
  gap: 14px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  font-family: Lato;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #000000;
  /* Removed cursor:pointer since it's no longer interactive */
`;

const WarningAmberIcon = styled(WarningAmber)`
  color: #000000;
  margin-right: 8px;
`;
