import React, { useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  styled,
  SelectChangeEvent,
  Checkbox,
  ListItemText,
  Chip,
  Box
} from '@mui/material';
import { useAppContext } from '../appContext';
import { Location } from '../models';

interface LocationSelectorProps {
  selectedLocations: Location[];
  onLocationChange: (locations: Location[]) => void;
}

const LocationSelector: React.FunctionComponent<LocationSelectorProps> = ({ selectedLocations, onLocationChange }) => {
  const { locations } = useAppContext();
  const [selected, setSelected] = React.useState<Location[]>(selectedLocations);

  useEffect(() => {
    setSelected(selectedLocations);
  }, [selectedLocations]);

  const handleLocationChange = (event: SelectChangeEvent<string[]>) => {
    const value = event.target.value as string[];
    const updatedLocationObjects = locations.filter(location => value.includes(location.id.toString()));
    setSelected(updatedLocationObjects);
    onLocationChange(updatedLocationObjects);
  };

  return (
    <StyledFormControl variant="outlined">
      <InputLabel id="location-select-label">Select Location</InputLabel>
      <Select
        labelId="location-select-label"
        id="location-select"
        multiple
        value={selected.map(location => location.id.toString())}
        onChange={handleLocationChange}
        renderValue={selected => {
          if (selected.length === 0) {
            return <em>Select Location</em>;
          }
          return (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip key={value} label={locations.find(location => location.id.toString() === value)?.location} />
              ))}
            </Box>
          );
        }}
        label="Select Location"
        inputProps={{ 'aria-label': 'Select location' }}>
        <MenuItem value="">
          <em>Select Location</em>
        </MenuItem>
        {locations.map(location => (
          <MenuItem key={location.id} value={location.id.toString()}>
            <Checkbox checked={selected.some(sel => sel.id === location.id)} />
            <ListItemText primary={location.location} />
          </MenuItem>
        ))}
      </Select>
    </StyledFormControl>
  );
};

export default LocationSelector;

const StyledFormControl = styled(FormControl)`
  min-width: 200px;
`;
