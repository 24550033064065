import { CreateLocationField } from '../../../models';

export const INITIAL_VALUE = 0;

export const BLANK_STRING = '';

export type PopulateLocationForm = Omit<CreateLocationField, 'minEmergencyTeam' | 'emergencyTeamPersons'> & {
  minEmergencyTeam: number;
  emergencyTeamPersons: number;
};

export const isPopulateLocationForm = (data: { [k: string]: FormDataEntryValue }): data is CreateLocationField => {
  return 'name' in data && 'minEmergencyTeam' in data && 'emergencyTeamPersons' in data;
};
