import { theme } from '../../styles/theme';
import {
  DEFAULT_ACTION_COL_WIDTH,
  DEFAULT_COL_WIDTH,
  DEFAULT_ID_COL_WIDTH,
  DoorStatus,
  DoorTableColumn
} from './types';

const {
  palette: {
    grey,
    primary,
    common: { white },
    error,
    info,
    warning
  }
} = theme;

export const statusBoxMainColor = (status: DoorStatus): string => {
  switch (status) {
    case DoorStatus.CLOSED:
      return primary.main;
    case DoorStatus.DISCONNECTED:
      return error.main;
    case DoorStatus.OPENING:
      return info.main;
    case DoorStatus.OPEN_PERMANENT:
      return warning.main;
    case DoorStatus.CLOSED_PERMANENT:
      return warning.main;
    default:
      return '';
  }
};

export const statusBoxBackgroundColor = (status: DoorStatus): string => {
  switch (status) {
    case DoorStatus.CLOSED:
      return primary.lighter || '';
    case DoorStatus.DISCONNECTED:
      return error.light;
    case DoorStatus.OPENING:
      return info.light;
    case DoorStatus.OPEN_PERMANENT:
      return warning.light;
    case DoorStatus.CLOSED_PERMANENT:
      return warning.light;
    default:
      return '';
  }
};

export const getRowColor = (status: DoorStatus, oddRow?: boolean): string => {
  if (oddRow) return status !== DoorStatus.DISCONNECTED ? grey[500] : error.light;
  return status !== DoorStatus.DISCONNECTED ? white : error.light;
};

export const getColWidth = (colTittle: DoorTableColumn, breakpointDown?: boolean): string => {
  switch (colTittle) {
    case DoorTableColumn.ID:
      if (breakpointDown) return '5%';
      return DEFAULT_ID_COL_WIDTH;
    case DoorTableColumn.ACTIONS:
      if (breakpointDown) return '30%';
      return DEFAULT_ACTION_COL_WIDTH;
    case DoorTableColumn.STATUS:
      if (breakpointDown) return '25%';
      return DEFAULT_COL_WIDTH;
    default:
      if (breakpointDown) return '15%';
      return DEFAULT_COL_WIDTH;
  }
};
