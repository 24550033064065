import { ReportLog, UserIcon, LogLevel, AuthReport } from '../../models';
import { SelectBoxType } from './components/SelectBox/SelectBox';

// TODO: All remove in https://sioux.atlassian.net/browse/ECA-357

export const logs: ReportLog[] = [
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '17:59:10',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: '999-XX-7',
    room: '',
    carPlate: '999-XX-7',
    logLevel: LogLevel.NORMAL
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '17:25:10',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.NORMAL
  },
  {
    logID: Math.random().toString(),
    userId: '4',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Aa, Thomas Long Nhat',
    date: '17:08:10',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: '999-XX-7',
    room: '',
    carPlate: '999-XX-7',
    logLevel: LogLevel.MEDIUM
  },
  {
    logID: Math.random().toString(),
    userId: '20422',
    userIcon: UserIcon.VISITOR,
    name: 'Dinh Quoc Viet',
    date: '',
    time: '16:28:10',
    message: 'Visitor from *Barcode or Ticket User has access to reader Signed In',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: 'Suss',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.HIGH
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Reset to Default by User Command send by user',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: 'Smit ST',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.NORMAL
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Open Permanent by User Command send by user',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: 'R-945-DX',
    room: '',
    carPlate: 'R-945-DX',
    logLevel: LogLevel.NORMAL
  },
  {
    logID: Math.random().toString(),
    userId: null,
    userIcon: UserIcon.SYSTEM,
    name: 'Entrada Security',
    date: '',
    time: '',
    message: 'No access to reader or out off group time.',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: 'SMIT TS Facility',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.HIGH
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'No access to reader or out off group time.',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: 'SMIT TS Facility',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.MEDIUM
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: 'Smit ST',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.HIGH
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.NORMAL
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: 'Smit ST',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.NORMAL
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: 'SMIT TS Facility',
    groupName: 'R-945-DX',
    room: '',
    carPlate: '',
    logLevel: LogLevel.MEDIUM
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.HIGH
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: 'R-945-DX',
    room: '',
    carPlate: '',
    logLevel: LogLevel.NORMAL
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.NORMAL
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.MEDIUM
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.HIGH
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: 'R-945-DX',
    room: '',
    carPlate: '',
    logLevel: LogLevel.NORMAL
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: 'R-945-DX',
    room: '',
    carPlate: '',
    logLevel: LogLevel.NORMAL
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: '',
    room: '',
    carPlate: '',
    logLevel: LogLevel.MEDIUM
  },
  {
    logID: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    date: '',
    time: '',
    message: 'Have access to door',
    doorName: 'Wing D 32',
    doorLocation: 'Eindhoven',
    departement: '',
    groupName: 'R-945-DX',
    room: '',
    carPlate: '',
    logLevel: LogLevel.NORMAL
  }
];

export const authLogs: AuthReport[] = [
  {
    authId: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20367',
    userIcon: UserIcon.EMPLOYEE,
    name: 'Nhat',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20371',
    userIcon: UserIcon.EMPLOYEE,
    name: 'a, thong ha 2',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20371',
    userIcon: UserIcon.EMPLOYEE,
    name: 'a, thong ha 2',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: null,
    userIcon: UserIcon.SYSTEM,
    name: 'System Security',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: null,
    userIcon: UserIcon.SYSTEM,
    name: 'System Security',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: null,
    userIcon: UserIcon.SYSTEM,
    name: 'System Security',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20371',
    userIcon: UserIcon.EMPLOYEE,
    name: 'a, thong ha 2',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20371',
    userIcon: UserIcon.EMPLOYEE,
    name: 'a, thong ha 2',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20371',
    userIcon: UserIcon.EMPLOYEE,
    name: 'a, thong ha 2',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20371',
    userIcon: UserIcon.EMPLOYEE,
    name: 'a, thong ha 2',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20422',
    userIcon: UserIcon.VISITOR,
    name: 'Dinh Quoc Viet',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20422',
    userIcon: UserIcon.VISITOR,
    name: 'Dinh Quoc Viet',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20422',
    userIcon: UserIcon.VISITOR,
    name: 'Dinh Quoc Viet',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20422',
    userIcon: UserIcon.VISITOR,
    name: 'Dinh Quoc Viet',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20422',
    userIcon: UserIcon.VISITOR,
    name: 'Dinh Quoc Viet',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  },
  {
    authId: Math.random().toString(),
    userId: '20422',
    userIcon: UserIcon.VISITOR,
    name: 'Dinh Quoc Viet',
    userLocation: 'Eindhoven',
    door: 'Wing D 31',
    doorLocation: 'Eindhoven',
    groupName: 'Algemeen',
    monday: '00:00-23:59',
    tuesday: '00:00-23:59',
    wednesday: '00:00-23:59',
    thursday: '00:00-23:59',
    friday: '00:00-23:59',
    saturday: '00:00-23:59',
    sunday: '00:00-23:59',
    doorSection: 'Hoofd ingang'
  }
];

export const selectBoxData: SelectBoxType[] = [
  {
    id: 1,
    value: 'All'
  },
  {
    id: 2,
    value: 3
  },
  {
    id: 3,
    value: 4
  }
];
