import React from 'react';
import { useTranslation } from 'react-i18next';
import { useUsersStates } from '../../../store';
import GridTable from './GridTable';

export const VisitorsInGroup: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { groupInformation } = useUsersStates();

  // Verkrijg de bezoekers in de groep en bepaal het totaal aantal rijen
  const visitors = groupInformation.visitorsInGroup?.users ?? [];
  const totalRows = visitors.length;

  // Maak de titels voor de GridTable
  const titles = [t('group.visitors_in_group.column.visitor_name'), t('group.visitors_in_group.column.location')];

  // Maak de headertekst inclusief het totaal aantal rijen
  const header = `${t('group.visitors_in_group.content')} (Total Amount: ${totalRows})`;

  return (
    <GridTable
      header={header}
      titles={titles}
      rows={visitors.map(it => ({
        visitorName: it.userName,
        location: it.locationName
      }))}
    />
  );
};
