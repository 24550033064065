import React, { useState, useRef, useEffect, ReactNode } from 'react';
import { Moment } from 'moment';
import {
  Box,
  Fade,
  styled,
  Typography,
  Tabs,
  Tab,
  Button,
  Checkbox,
  FormControlLabel,
  TextFieldProps,
  TextField
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { dimensions } from '../../../../styles/dimensions';
import { TimeFormatType } from '../../../../utils/date';

import { Content, ContentHeader, ContentWrapper, TimeList, Title } from '../../components';
import { PrimaryButton, SecondaryButton } from '../../../../components/Button';

import { BelongUsers, BelongVisitors, DoorAuthorization } from './Table';
import { DayOfWeek, getDefaultNewGroup, TimePick } from './types';

import GroupNameInput from './GroupNameInput';
import TemporaryAccessCheckbox from './TemporaryAccessCheckbox';

import { Popup } from '../../../../components/Popup';
import { SideBarItemType } from '../../../../types/route';

import { useUsersActions } from '../../store';
import { useCallbackPrompt } from '../../../../utils/useCallbackPrompt';
import { showSuccessToast, showErrorToast } from '../../../../components/Toast/actions';
import { PopulateGroupParams, StandardTime } from '../../../../models';
import { StandardTimeItem } from '../components';
import { validateGroupForm } from '../utils';
import { ActionType } from '../types';
import { BooleanValue } from '../../../../types';
import { theme } from '../../../../styles/theme';

const TimePickerTextField: React.FunctionComponent<TextFieldProps> = (props: TextFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <TextField
      size="small"
      disabled={false}
      sx={{ caretColor: theme.palette.primary.main }} // Ensuring the caret color is set to primary color
      inputRef={inputRef}
      onClick={() => inputRef.current?.focus()}
      {...props}
    />
  );
};

const AddNew: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { getExistedUsers, getExistedVisitors, getExistedDoors, addNewGroup, addUserToGroup, getAllGroups } =
    useUsersActions();
  const { showPrompt, cancelNavigation, confirmNavigation } = useCallbackPrompt();
  const addNewGroupParam = useRef<PopulateGroupParams>(getDefaultNewGroup());

  const [applyToWeekends, setApplyToWeekends] = useState(false);
  const [timeDetails, setTimeDetails] = useState<StandardTime[]>([
    { day: 'Default', startTime: '08:00', endTime: '17:00' },
    { day: 'Monday', startTime: '00:00', endTime: '23:59' },
    { day: 'Tuesday', startTime: '00:00', endTime: '23:59' },
    { day: 'Wednesday', startTime: '00:00', endTime: '23:59' },
    { day: 'Thursday', startTime: '00:00', endTime: '23:59' },
    { day: 'Friday', startTime: '00:00', endTime: '23:59' },
    { day: 'Saturday', startTime: '00:00', endTime: '23:59' },
    { day: 'Sunday', startTime: '00:00', endTime: '23:59' }
  ]);

  const handleStandardTimeItemChanged = (moment: Moment, day: string, type: TimePick) => {
    const newTimeDetails = timeDetails.map(item => {
      if (item.day === day) {
        return {
          ...item,
          [type === TimePick.START_TIME ? 'startTime' : 'endTime']: moment.format(TimeFormatType.HOUR_MINUTE)
        };
      }
      return item;
    });
    setTimeDetails(newTimeDetails);
    addNewGroupParam.current.timeDetails = newTimeDetails;
  };

  const handleApplyDefaultTime = () => {
    const defaultTime = timeDetails.find(item => item.day === 'Default');
    if (defaultTime) {
      const newStandardTimes = timeDetails.map(item => {
        if (applyToWeekends || (!applyToWeekends && item.day !== 'Saturday' && item.day !== 'Sunday')) {
          if (item.day !== 'Default') {
            return {
              ...item,
              startTime: defaultTime.startTime,
              endTime: defaultTime.endTime
            };
          }
        }
        return item;
      });

      setTimeDetails(newStandardTimes);
      addNewGroupParam.current.timeDetails = newStandardTimes;
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApplyToWeekends(event.target.checked);
  };

  const handleGroupNameChanged = (value: string) => {
    addNewGroupParam.current.groupName = value;
  };

  const handleTemporaryAccessChanged = (value: string) => {
    addNewGroupParam.current.temporaryAccess = value;
  };

  const handleBelongUserIdsChanged = (userIds: string[]) => {
    if (!addNewGroupParam.current) return;
    addNewGroupParam.current.belongUserIds = userIds;
  };

  const handleBelongVisitorIdsChanged = (visitorIds: string[]) => {
    if (!addNewGroupParam.current) return;
    addNewGroupParam.current.belongVisitorIds = visitorIds;
  };

  const handleUsedDoorIdsChanged = (doorIds: string[]) => {
    if (!addNewGroupParam.current) return;
    addNewGroupParam.current.usedDoorIds = doorIds;
  };

  const handleAddNewGroup = async () => {
    const errorMessage = validateGroupForm(addNewGroupParam.current, ActionType.ADD_NEW);

    if (errorMessage) {
      const { title, subtitle, data } = errorMessage;

      showErrorToast({
        title: t(title),
        subtitle: t(subtitle, data)
      });

      return;
    }

    try {
      const params = addNewGroupParam.current;
      const dayOfWeek = Object.values(DayOfWeek);

      const timeConvert: Record<string, any> = {};
      params.timeDetails.forEach((standardTime, idx) => {
        const { startTime, endTime } = standardTime;
        timeConvert[dayOfWeek[idx]] = `${startTime},${endTime}`;
      });

      const newGroupData = {
        name: params.groupName,
        proId: '1',
        active: '0',
        objectEnable: {
          objects: params.usedDoorIds?.join(',') ?? ''
        },
        time: timeConvert,
        LocationId: '1',
        tmpAccess: params.temporaryAccess ?? '0'
      };

      const newGroupId = await addNewGroup(newGroupData);

      const allUserIds = [...(params.belongUserIds || []), ...(params.belongVisitorIds || [])];

      if (allUserIds.length > 0) {
        await addUserToGroup(`${newGroupId}`, allUserIds);
      }

      await getAllGroups();

      showSuccessToast({
        title: t('group.add_new.message.success.title'),
        subtitle: t('group.add_new.message.success.subtitle')
      });

      navigate(`/users/${SideBarItemType.Group}/${newGroupId}`);
      confirmNavigation();
    } catch (error) {
      showErrorToast({
        title: t('group.add_new.message.fail.title'),
        subtitle: t('group.add_new.message.fail.subtitle')
      });
    }
  };

  useEffect(() => {
    getExistedDoors();
    getExistedUsers();
    getExistedVisitors();

    // Reset state only when leaving the page
    return () => {
      if (addNewGroupParam.current) {
        addNewGroupParam.current.usedDoorIds = [];
        addNewGroupParam.current.belongUserIds = [];
        addNewGroupParam.current.belongVisitorIds = [];
      }
    };
  }, []);

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    // No need to manually save here as the handlers above
    // are keeping addNewGroupParam.current up to date
    setTabIndex(newValue);
  };

  const defaultTime = timeDetails.find(item => item.day === 'Default') || {
    day: 'Default',
    startTime: '08:00',
    endTime: '17:00'
  };

  return (
    <ContentWrapper>
      <Fade in={true}>
        <Content>
          <ContentHeader>
            <Typography variant="subtitle4">{t('group.add_new.content')}</Typography>

            <ButtonWrapper>
              <SecondaryButton
                content={t('group.add_new.button.cancel')}
                height={buttonHeight}
                onClick={() => navigate(-1)}
              />
              <PrimaryButton
                content={t('group.add_new.button.save_new_group')}
                height={buttonHeight}
                onClick={handleAddNewGroup}
              />
            </ButtonWrapper>
          </ContentHeader>
          <Box paddingTop={baseMD} width="35%" minWidth="400px">
            <GroupNameInput onGroupNameChanged={handleGroupNameChanged} />
          </Box>

          <Box paddingTop={baseMD} paddingBottom={baseMD}>
            <Title variant="subtitle3">{t('group.select_standard_time')}</Title>
          </Box>

          <Box paddingBottom={baseMD}>
            <Box display="flex" alignItems="center">
              <StandardTimeItem key="Default" item={defaultTime} onTimeChanged={handleStandardTimeItemChanged} />
              <Button
                variant="contained"
                onClick={handleApplyDefaultTime}
                sx={{ marginLeft: '8px', alignSelf: 'flex-start' }}>
                {t('group.apply_default_time')}
              </Button>
            </Box>
            <FormControlLabel
              control={<Checkbox checked={applyToWeekends} onChange={handleCheckboxChange} />}
              label={t('group.apply_to_weekends')}
            />
          </Box>
          <TemporaryAccessCheckbox
            onAccessChanged={value => handleTemporaryAccessChanged(value ? BooleanValue.TRUE : BooleanValue.FALSE)}
          />

          <TimeList>
            {timeDetails
              .filter(item => item.day !== 'Default')
              .map(item => (
                <StandardTimeItem key={item.day} item={item} onTimeChanged={handleStandardTimeItemChanged} />
              ))}
          </TimeList>

          <Tabs value={tabIndex} onChange={handleTabChange} aria-label="group info tabs">
            <Tab label={t('group.tab.doors')} />
            <Tab label={t('group.tab.users')} />
            <Tab label={t('group.tab.visitors')} />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <DoorAuthorization
              onUsedDoorIdsChanged={handleUsedDoorIdsChanged}
              initialDoorIds={addNewGroupParam.current?.usedDoorIds || []}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <BelongUsers
              onBelongUserIdsChanged={handleBelongUserIdsChanged}
              initialUserIds={addNewGroupParam.current?.belongUserIds || []}
            />
          </TabPanel>
          <TabPanel value={tabIndex} index={2}>
            <BelongVisitors
              onBelongVisitorIdsChanged={handleBelongVisitorIdsChanged}
              initialVisitorIds={addNewGroupParam.current?.belongVisitorIds || []}
            />
          </TabPanel>
        </Content>
      </Fade>

      <Popup
        title={t('group.add_new.popup.title')}
        styleChoice="style2"
        open={showPrompt}
        onClose={cancelNavigation}
        closeactiontext={t('Continue adding new group')}
        action={{
          name: t('user.edit.button.quit'),
          onAction: confirmNavigation
        }}
      />
    </ContentWrapper>
  );
};

export default AddNew;

const { baseMD, buttonHeight } = dimensions;

const ButtonWrapper = styled(Box)`
  display: flex;
  column-gap: ${baseMD};
`;

type TabPanelProps = {
  value: number;
  index: number;
  children: ReactNode;
};

const TabPanel: React.FunctionComponent<TabPanelProps> = ({ value, index, children }) => {
  return <div hidden={value !== index}>{value === index && <Box padding={3}>{children}</Box>}</div>;
};
