import React, { useState, useEffect, MouseEvent } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogContent,
  IconButton as MuiIconButton
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import LinkedCameraOutlinedIcon from '@mui/icons-material/LinkedCameraOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { $get } from '../../utils/http'; // Ensure this path is correct

interface ANPRCameraBlockProps {
  locationIds?: number[]; // Optional, if no location IDs are provided, all locations are fetched
}

const ANPRCameraBlock: React.FC<ANPRCameraBlockProps> = ({ locationIds }) => {
  const [data, setData] = useState<any[]>([]); // Replace `any` with your own type
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; rowIndex: number | null } | null>(
    null
  );
  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
  const [selectedImageSrc, setSelectedImageSrc] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (loading) {
          setLoading(true);
        }

        let url = '/dashboard/anprCameraData';
        if (locationIds && locationIds.length > 0) {
          url += `?locationIds=${locationIds.join(',')}`;
        }

        const response = await $get(url);

        const dataArray = Array.isArray(response) ? response : [response];

        setData(prevData => {
          if (JSON.stringify(prevData) !== JSON.stringify(dataArray)) {
            return dataArray;
          }
          return prevData;
        });
        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to get data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, [locationIds, loading]);

  const handleContextMenu = (event: MouseEvent, rowIndex: number) => {
    event.preventDefault();
    setHighlightedRow(rowIndex);
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      rowIndex: rowIndex
    });
  };

  const handleClose = () => {
    setContextMenu(null);
    setHighlightedRow(null);
  };

  const handleShowLicensePlatePicture = () => {
    if (contextMenu && contextMenu.rowIndex !== null) {
      const { photo } = data[contextMenu.rowIndex];
      setSelectedImageSrc(photo); // Set the source of the image
      setOpenImageDialog(true); // Open the image dialog
    }
    handleClose();
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false); // Close the image dialog
  };

  if (loading) {
    return <BlockContainer>Loading...</BlockContainer>;
  }

  if (error) {
    return <BlockContainer>Error: {error}</BlockContainer>;
  }

  return (
    <BlockContainer>
      <Header>
        <TitleContainer>
          <LinkedCameraOutlinedIconStyled />
          <Title>ANPR CAMERA</Title>
        </TitleContainer>
        <HeaderRight>
          <DateText>{new Date().toLocaleDateString('en-GB').replace(/-/g, '/')}</DateText>
        </HeaderRight>
      </Header>
      <TableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Location</TableHeaderCell>
              <TableHeaderCell>Camera Name</TableHeaderCell>
              <TableHeaderCell>Time</TableHeaderCell>
              <TableHeaderCell>License Plate</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <StyledTableRow
                  key={index}
                  onContextMenu={event => handleContextMenu(event, index)}
                  highlighted={highlightedRow === index}>
                  <TableCell>{row.location_name}</TableCell>
                  <TableCell>{row.camera_name}</TableCell>
                  <TableCell>{row.time}</TableCell>
                  <TableCell>{row.license_plate}</TableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>No data available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        PaperProps={{
          style: {
            width: '154px',
            height: '53px',
            padding: '8px 0',
            borderRadius: '4px 0 0 0',
            opacity: 1
          }
        }}>
        <StyledMenuItem onClick={handleShowLicensePlatePicture}>Show Picture</StyledMenuItem>
      </Menu>
      <Dialog open={openImageDialog} onClose={handleCloseImageDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          <DialogHeader>
            <StyledIconButton onClick={handleCloseImageDialog} edge="end" color="inherit">
              <CloseIcon />
            </StyledIconButton>
          </DialogHeader>
          {selectedImageSrc && <img src={selectedImageSrc} alt="License Plate" style={{ width: '100%' }} />}
        </DialogContent>
      </Dialog>
    </BlockContainer>
  );
};

export default ANPRCameraBlock;

// Styled Components

const BlockContainer = styled(Box)`
  width: 545px;
  height: 292px;
  padding: 0px 16px 16px 24px;
  background: #e5e9eb;
  border: 1px solid #d8dfe0;
  border-radius: 4px 0px 0px 0px;
  display: flex;
  flex-direction: column;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
`;

const HeaderRight = styled(Box)`
  display: flex;
  align-items: center;
`;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled(Typography)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
`;

const DateText = styled(Typography)`
  font-family: Lato;
  font-size: 13px;
  color: #414548;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
`;

const StyledTable = styled(Table)`
  width: 100%;
`;

const TableHeaderCell = styled(MuiTableCell)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
  padding: 8px;
  background-color: white;
`;

const StyledTableRow = styled(TableRow)<{ highlighted?: boolean }>`
  background-color: ${({ highlighted }) => (highlighted ? '#D1EC86' : 'inherit')};
  &:nth-of-type(even) {
    background-color: ${({ highlighted }) => (highlighted ? '#D1EC86' : '#f6f9fa')};
  }
`;

const TableCell = styled(MuiTableCell)`
  flex: 1;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 8px;
`;

const StyledMenuItem = styled(MenuItem)`
  color: black;
  &:hover {
    color: #96c11f;
  }
`;

const DialogHeader = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
`;

const StyledIconButton = styled(MuiIconButton)`
  color: black;
`;

const LinkedCameraOutlinedIconStyled = styled(LinkedCameraOutlinedIcon)`
  color: #000000;
  margin-right: 8px; // Deze regel zorgt voor dezelfde afstand als in EmergencyTeamBlock
`;
