import { Box, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OptionButton, OptionButtonType } from '../../../../components/Button/OptionButton';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';

export const HeaderLocationInformation: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate('edit');
  };

  return (
    <Wrapper>
      <Typography variant="subtitle4" color="grey.100" textTransform="uppercase">
        {t('location.list_header')}
      </Typography>

      <OptionButton type={OptionButtonType.EDIT} content={t('edit')} onClick={handleEdit} isscaledown={false} />
    </Wrapper>
  );
};

const { baseLG, baseMD } = dimensions;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 ${baseLG};
  padding: ${baseMD} 0;
  border-bottom: 1px solid ${theme.palette.grey[400]};
`;
