import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { OptionButton, OptionButtonType } from '../../../../components/Button/OptionButton';
import { ResponsiveInfinityScroll } from '../../../../components/ResponsiveInfinityScroll';
import SearchBar from '../../../../components/SearchBar';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { LocationRouteEndpoints } from '../../../../types/locationRouteEndpoints';
import { SideBarItemType } from '../../../../types/route';
import { useLocationHook } from '../store';
import { BLANK_STRING, INITIAL_VALUE } from '../type';

type Props = {
  id: string;
};

const LocationList: React.FunctionComponent<Props> = ({ id }: Props) => {
  const { t } = useTranslation();

  const disappearButtons =
    location.pathname.includes(LocationRouteEndpoints.ADD_NEW) ||
    location.pathname.includes(LocationRouteEndpoints.EDIT);

  const [{ totalLocation, locationList }, { getLocations, setInitialLocationList, getLocationByName }] =
    useLocationHook();

  const navigate = useNavigate();

  const [keyword, setKeyword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  const [onSearchMode, setOnSearchMode] = useState<boolean>(false);

  const handleSearch = async (value: string) => {
    setKeyword(value);
    setLoading(true);
    try {
      if (value.trim() === BLANK_STRING) {
        await getLocations();
      } else {
        await getLocationByName(value);
        setOnSearchMode(true);
      }
    } catch (error) {
      await setInitialLocationList();
    } finally {
      setLoading(false);
    }
  };

  const handleScrollDown = async () => {
    if (totalLocation > locationList.length && locationList[locationList.length - 1]) {
      const fromId = locationList[locationList.length - 1].id;
      if (onSearchMode) {
        await getLocations(keyword, fromId);
      } else {
        await getLocations();
      }
    }
  };

  const handleChooseLocation = async (value: number) => {
    navigate(`/AdminSettings/location/${value}`);
  };

  async function getAllLocations() {
    setLoading(true);
    await getLocations();
    setOnSearchMode(false);
    setLoading(false);
  }

  React.useEffect(() => {
    getAllLocations();
  }, []);

  return (
    <ListWrapper>
      <Header>
        <Typography variant="subtitle4" textTransform="uppercase" color="grey.100">
          {t('location.list')} ({totalLocation ? totalLocation : INITIAL_VALUE})
        </Typography>
      </Header>
      <br></br>
      {!disappearButtons && (
        <OptionButton
          type={OptionButtonType.ADD_NEW}
          content={t('add_new')}
          isscaledown={true}
          onClick={() => navigate(`/AdminSettings/location/${LocationRouteEndpoints.ADD_NEW}`)}
        />
      )}

      <SearchBar onClear={getAllLocations} onSearch={handleSearch} placeHolder={t('location.search_placeholder')} />

      {!loading && isEmpty(locationList) ? (
        <Typography variant="subtitle1" color="grey.200">
          {t('search_no_result')}
        </Typography>
      ) : (
        <ResponsiveInfinityScroll
          height="40vh"
          dataLength={locationList ? locationList.length : 0}
          next={handleScrollDown}
          hasMore={loading || (locationList && totalLocation > locationList.length)}
          loader={
            <Box display="flex" justifyContent="center">
              <CircularProgress color="primary" size={40} />
            </Box>
          }>
          {locationList.map(item => (
            <Item
              variant="body1"
              key={item.id}
              className={id === item.id.toString() ? 'active' : BLANK_STRING}
              onClick={() => handleChooseLocation(item.id)}>
              {item.location}
            </Item>
          ))}
        </ResponsiveInfinityScroll>
      )}
    </ListWrapper>
  );
};

export default LocationList;

const { grey, primary, common } = theme.palette;
const { baseMD, baseXS, baseSM, baseLG } = dimensions;

const Header = styled(Box)`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${grey[400]};
`;

const Item = styled(Typography)`
  display: flex;
  align-items: center;
  padding: 0 0 0 3%;
  width: 96%;
  line-height: 40px;
  border-radius: 2px;
  cursor: pointer;
  &.active {
    background-color: ${primary.main};
    color: ${common.white};
    font-weight: 700;
  }
`;

export const ListWrapper = styled(Box)`
  width: 300px;
  padding: ${baseSM} ${baseLG};
  border-radius: ${baseXS};
  height: calc(100% - ${baseMD});
  box-shadow: ${theme.shadows[2]};
  background-color: ${theme.palette.grey[500]};

  ${props => props.theme.breakpoints.down('lg')} {
    width: 200px;
  }
`;
