import React, { useState } from 'react';
import { Box, Grow, styled, Typography, Zoom, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { OptionButton, OptionButtonType } from '../../../../components/Button/OptionButton';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { useUsersHook } from '../../store';
import { Popup } from '../../../../components/Popup';
import { useCallbackPrompt } from '../../../../utils/useCallbackPrompt';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast/actions';
import { $delete } from '../../../../utils/http';
import { LocalHospital, Star } from '@mui/icons-material';
import { BooleanValue } from '../../../../types/checkbox';

export const HeaderUserInformation: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [{ userInfo }] = useUsersHook();
  const [showPrompt, setShowDeletePopup] = useState(false); // State voor de verwijder popup

  const handleClickAddNewVisitorButton = () => {
    navigate('/users/visitor/add-new', { state: { id: userInfo?.id, name: userInfo?.name } });
  };

  const handleClickEditButton = () => {
    navigate('edit');
  };

  const handleClickDeleteButton = () => {
    setShowDeletePopup(true); // Toon de delete popup
  };

  const cancelNavigation = () => {
    setShowDeletePopup(false); // Sluit de popup zonder actie
  };

  const cancelDelete = () => {
    setShowDeletePopup(false); // Sluit de popup zonder actie
  };

  const confirmDelete = async () => {
    try {
      const userId = userInfo?.id;
      console.log('Attempting to delete user with ID:', userId); // Log de poging om de gebruiker te verwijderen
      const response = await $delete(`user/${userId}`);

      console.log('Response status:', response.status); // Log de status van de response
      if (response.status === 200) {
        showSuccessToast({
          title: t('user.deletenot.success.title'),
          subtitle: t('user.deletenot.success.subtitle')
        });
        setShowDeletePopup(false);
        navigate('/users/user'); // Navigeer naar de gebruikerslijst of een relevante pagina
        window.location.reload();
      } else {
        showSuccessToast({
          title: t('user.deletenot.success.title'),
          subtitle: t('user.deletenot.success.subtitle')
        });
        setShowDeletePopup(false);
        navigate('/users/user'); // Navigeer naar de gebruikerslijst of een relevante pagina
        setTimeout(() => {
          window.location.reload();
        }, 500); // Wacht 0.5 seconden voordat de pagina herladen wordt
      }
    } catch (error) {
      console.error('Error deleting user:', error); // Log de fout die gevangen is in de catch
      showErrorToast({
        title: t('user.deletenot.error.title'),
        subtitle: t('user.deletenot.error.subtitle')
      });
    }
  };
  if (!userInfo) return <></>;

  return (
    <Wrapper>
      <Popup
        title={t('Delete User')}
        styleChoice="style2"
        open={showPrompt}
        onClose={cancelDelete}
        closeactiontext={t('Cancel')}
        action={{
          name: t('Delete'),
          onAction: confirmDelete
        }}
      />
      <InfoWrapper>
        <Box position="relative">
          <Grow in={true}>
            <UserImageWrapper src={userInfo.avatar} />
          </Grow>
          {userInfo.emergency === BooleanValue.TRUE && (
            <Tooltip title={t('user.emergency_team')} arrow>
              <LocalHospital
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: -30,
                  color: 'error.main',
                  fontSize: '28px',
                  cursor: 'help'
                }}
              />
            </Tooltip>
          )}
          {userInfo.special === 'on' && (
            <Tooltip title={t('user.special_rights')} arrow>
              <Star
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  right: -60,
                  color: 'gold',
                  fontSize: '28px',
                  cursor: 'help'
                }}
              />
            </Tooltip>
          )}
        </Box>

        <Zoom in={true}>
          <Box display="flex" flexDirection="column" rowGap={`${baseSM}`}>
            <InfoWrapper>
              <Typography variant="h3" color="grey.100">
                {userInfo.name}
              </Typography>

              <Typography variant="h3" color="grey.200" paddingLeft={`${baseSM}`} borderLeft={`3px solid ${grey[200]}`}>
                {userInfo.id}
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                onClick={handleClickAddNewVisitorButton}
                style={{
                  fontSize: '13px',
                  fontStyle: 'italic',
                  marginTop: '10px',
                  textDecoration: 'underline',
                  color: 'grey',
                  cursor: 'pointer'
                }}>
                {t('user.add_visitor')}
              </Typography>
            </InfoWrapper>

            <DepartmentInfoWrapper>
              <LabelTypography variant="body2" color="grey.200">
                {t('user.department')}:
              </LabelTypography>
              <Typography variant="subtitle4" color="grey.100" sx={{ marginLeft: '4px' }}>
                {userInfo.department}
              </Typography>
            </DepartmentInfoWrapper>
          </Box>
        </Zoom>
      </InfoWrapper>

      <ButtonWrapper>
        <div style={{ marginRight: '40px' }}>
          <OptionButton
            type={OptionButtonType.DELETE}
            content={t('user.delete')}
            onClick={handleClickDeleteButton}
            isscaledown={false}
          />
        </div>
        <OptionButton
          type={OptionButtonType.EDIT}
          content={t('edit')}
          onClick={handleClickEditButton}
          isscaledown={false}
        />
      </ButtonWrapper>
    </Wrapper>
  );
};

const { base, baseXS, baseLG, baseSM, buttonHeight } = dimensions;
const { grey } = theme.palette;

const UserImageWrapper = styled(Box)(({ src }: { src: string | undefined }) => ({
  width: '120px',
  height: '120px',
  backgroundImage: `url(${src})`,
  backgroundSize: '100% auto',
  backgroundRepeat: 'no-repeat',
  border: `1px solid ${grey[400]}`,
  borderRadius: `${baseXS}`,
  boxShadow: `${theme.shadows[2]}`
}));

const Wrapper = styled(Box)`
  padding: ${baseLG};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const InfoWrapper = styled(Box)`
  display: flex;
  min-width: 200px;
  flex-direction: row;
  column-gap: ${base};
  align-items: flex-start;
`;

const DepartmentInfoWrapper = styled(Box)`
  display: flex;
  min-width: 200px;
  flex-direction: row;
  gap: 4px;
  align-items: center;
  margin-top: 4px;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  column-gap: ${base};
  row-gap: ${base};
  height: ${buttonHeight};
`;

const LabelTypography = styled(Typography)`
  min-width: unset;
`;
