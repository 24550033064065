// src/modules/DataManagement/Users.tsx
import React, { useState, useRef } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FileSelector from '../../components/FileSelector';
import { showErrorToast, showSuccessToast } from '../../components/Toast/actions';
import { FileExtension } from '../../types/file';
import { downloadCSVFile, exportCSV, importCSV } from '../../utils/fileHelper';
import { importUserConverter, exportUserConverter } from '../Users/converter';
import { useUsersHook } from '../Users/store'; // Zorg dat dit de geüpdatete hook met getTemplateForImport importeert
import { OptionButton, OptionButtonType } from '../../components/Button/OptionButton';
import { startCaseKeysObj } from '../../utils/object';

const UserImportExport: React.FC = () => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-empty-pattern
  const [{}, actions] = useUsersHook();
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Handler voor het selecteren en importeren van een bestand
  const handleFileSelected = async (file: File) => {
    setLoading(true);
    try {
      const fileData = await importCSV(file);
      await actions.createUserInfoFromFile(fileData);
      showSuccessToast({
        title: t('user.import_user_toast.success.title'),
        subtitle: t('user.import_user_toast.success.subtitle')
      });
    } catch (error) {
      showErrorToast({
        title: t('user.import_user_toast.failed.title'),
        subtitle: t('user.import_user_toast.failed.subtitle')
      });
    } finally {
      setLoading(false);
    }
  };

  // Handler voor het exporteren van gebruikersdata naar een CSV-bestand
  const exportUserList = async () => {
    setLoading(true);
    try {
      const exportUserInfoList = await actions.getExportUserInfoList();
      const startCaseDataList = exportUserInfoList.map(info => startCaseKeysObj(exportUserConverter(info)));
      const csvData = exportCSV(startCaseDataList);
      downloadCSVFile(csvData, 'users_export.csv');
      showSuccessToast({
        title: t('user.export_user_toast.success.title'),
        subtitle: t('user.export_user_toast.success.subtitle')
      });
    } catch {
      showErrorToast({
        title: t('user.export_user_toast.failed.title'),
        subtitle: t('user.export_user_toast.failed.subtitle')
      });
    } finally {
      setLoading(false);
    }
  };

  // Handler voor het downloaden van de importtemplate
  const downloadTemplate = async () => {
    setLoading(true);
    try {
      downloadCSVFile('user_import_template.csv');
      showSuccessToast({
        title: t('user.download_template.success.title'),
        subtitle: t('user.download_template.success.subtitle')
      });
    } catch {
      showErrorToast({
        title: t('user.download_template.failed.title'),
        subtitle: t('user.download_template.failed.subtitle')
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Zorgt ervoor dat de volledige hoogte van de pagina wordt benut
        width: '100vw', // Zorgt ervoor dat de volledige breedte van de pagina wordt benut
        padding: 4,
        textAlign: 'center',
        backgroundColor: '#f4f4f9' // Optioneel: achtergrondkleur voor contrast
      }}>
      <Typography variant="h3" gutterBottom>
        {t('user.import_export.title')}
      </Typography>
      <Typography variant="body1" mb={4}>
        Gebruik deze tools om gebruikers te importeren en exporteren. Download een template om te zien welk formaat u
        moet gebruiken voor import.
      </Typography>

      <Box display="flex" gap={3} mb={4}>
        <OptionButton
          type={OptionButtonType.EXPORT}
          content={t('export')}
          onClick={exportUserList}
          isscaledown={true}
        />
        <OptionButton
          type={OptionButtonType.IMPORT}
          content={t('import')}
          onClick={() => inputFileRef.current?.click()}
          isscaledown={true}
        />
        <OptionButton
          type={OptionButtonType.DOWNLOAD}
          content={t('download_template')}
          onClick={downloadTemplate}
          isscaledown={true}
        />
      </Box>

      <FileSelector inputFileRef={inputFileRef} onFileSelected={handleFileSelected} fileExts={[FileExtension.CSV]} />

      {loading && (
        <Box display="flex" justifyContent="center" mt={2}>
          <CircularProgress color="primary" />
        </Box>
      )}
    </Box>
  );
};

export default UserImportExport;
