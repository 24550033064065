import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Fade, styled, Typography, Tabs, Tab } from '@mui/material';
import { ReactComponent as Activated } from '../../../../assets/image/Users/Activated.svg';
import { ReactComponent as Edit } from '../../../../assets/image/edit.svg';
import { dimensions } from '../../../../styles/dimensions';
import {
  Content,
  ContentHeader,
  ContentWrapper,
  StandardTime,
  TemporaryAccess,
  TimeList,
  Title,
  TableWrapper
} from '../../components';
import { useUsersHook } from '../../store';
import { DoorAuthorization, UsersInGroup, VisitorsInGroup } from './Table';
import StandardTimeItem from './StandardTimeItem';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from '../../../../components/Button';
import { GroupRouteEndpoints } from '../types';
import { Popup } from '../../../../components/Popup';
import { showErrorToast, showSuccessToast } from '../../../../components/Toast/actions';
import { $delete } from '../../../../utils/http';
import { OptionButton, OptionButtonType } from '../../../../components/Button/OptionButton';
import Group from '..';
import { group } from 'console';

const GroupInfo: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [{ groupInformation }, { getGroupById }] = useUsersHook();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [showPrompt, setShowDeletePopup] = useState(false); // State for delete popup

  const getGroupInformation = async () => {
    if (id) {
      try {
        setLoading(true);
        await getGroupById(id);
        setLoading(false);
      } catch (error) {
        navigate('/not-found');
      }
    }
  };

  React.useEffect(() => {
    getGroupInformation();
  }, [id]);

  const { groupName, timeDetails, groupTmpAccessEnabled: temporaryAccess } = groupInformation;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleClickDeleteButton = () => {
    setShowDeletePopup(true); // Show the delete popup
  };

  const cancelDelete = () => {
    setShowDeletePopup(false); // Close the popup without action
  };

  const confirmDelete = async () => {
    try {
      const groupIdDelete = groupInformation.groupId;
      console.log('Attempting to delete group with ID:', groupInformation.groupId); // Log the attempt to delete the group
      const response = await $delete(`group/${groupIdDelete}`);
      console.log('Response status:', response.status); // Log the response status
      if (response.status === 200) {
        showSuccessToast({
          title: t('group.deletenot.success.title'),
          subtitle: t('group.deletenot.success.subtitle')
        });
        setShowDeletePopup(false);
        navigate('/users/group'); // Navigate to the groups list or a relevant page
        window.location.reload();
      } else {
        showSuccessToast({
          title: t('group.deletenot.success.title'),
          subtitle: t('group.deletenot.success.subtitle')
        });
        setShowDeletePopup(false);
        navigate('/users/group'); // Navigate to the groups list or a relevant page
        setTimeout(() => {
          window.location.reload();
        }, 500); // Wait 0.5 seconds before reloading the page
      }
    } catch (error) {
      console.error('Error deleting group:', error); // Log the error caught in the catch block
      showErrorToast({
        title: t('group.deletenot.error.title'),
        subtitle: t('group.deletenot.error.subtitle')
      });
    }
  };

  return (
    <ContentWrapper>
      <Popup
        title={t('Delete Group')}
        styleChoice="style2"
        open={showPrompt}
        onClose={cancelDelete}
        closeactiontext={t('Cancel')}
        action={{
          name: t('Delete'),
          onAction: confirmDelete
        }}
      />
      {loading ? (
        <LoadingWrapper>
          <CircularProgress color="primary" size={40} />
        </LoadingWrapper>
      ) : (
        <Fade in={!loading}>
          <Content>
            <ContentHeader>
              <Typography variant="h3">{groupName}</Typography>
              <ButtonWrapper>
                <div style={{ marginRight: '40px' }}>
                  <OptionButton
                    type={OptionButtonType.DELETE}
                    content={t('user.delete')}
                    onClick={handleClickDeleteButton}
                    isscaledown={false}
                  />
                </div>
                <OptionButton
                  type={OptionButtonType.EDIT}
                  content={t('edit')}
                  onClick={() => navigate(GroupRouteEndpoints.EDIT)}
                  isscaledown={false}
                />
              </ButtonWrapper>
            </ContentHeader>
            <StandardTime>
              <Title variant="subtitle3">{t('group.standard_time')}</Title>
              <TimeList>
                {timeDetails.map(item => (
                  <StandardTimeItem key={item.day} item={item} />
                ))}
              </TimeList>
              {temporaryAccess === '1' && (
                <TemporaryAccess>
                  <Typography>{t('group.ask_for_temporary_group_access')}</Typography>
                  <Activated />
                </TemporaryAccess>
              )}
            </StandardTime>
            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="group info tabs">
              <Tab label={t('group.tab.doors')} />
              <Tab label={t('group.tab.users')} />
              <Tab label={t('group.tab.visitors')} />
            </Tabs>
            {tabIndex === 0 && (
              <Box padding={3}>
                <DoorAuthorization />
              </Box>
            )}
            {tabIndex === 1 && (
              <Box padding={3}>
                <UsersInGroup />
              </Box>
            )}
            {tabIndex === 2 && (
              <Box padding={3}>
                <VisitorsInGroup />
              </Box>
            )}
          </Content>
        </Fade>
      )}
    </ContentWrapper>
  );
};

export default GroupInfo;

const { base, baseSM, baseXS, baseMD, baseLG, buttonHeight } = dimensions;

const LoadingWrapper = styled(Box)`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
  column-gap: ${base};
  row-gap: ${base};
  height: ${buttonHeight};
`;
