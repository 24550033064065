import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { RefObject, useEffect, useState } from 'react';
import { theme } from '../styles/theme';
import { $get } from '../utils/http';

type Props = {
  componentToPrint: RefObject<HTMLDivElement>;
  locationId?: string;
};

interface EvacuationData {
  userFullName: string;
  userLastScannedTime: string;
  userLastScannedDoor: string;
  userDepartment: string;
  userPhone: string;
}

export const TableEvacuation: React.FunctionComponent<Props> = ({ componentToPrint, locationId }) => {
  const [evacuationData, setEvacuationData] = useState<EvacuationData[]>([]);

  useEffect(() => {
    const fetchEvacuationData = async () => {
      try {
        const response: EvacuationData[] = await $get('dashboard/evacuation', {
          params: {
            locationId: locationId
          }
        });
        const sortedData = response.sort((a: EvacuationData, b: EvacuationData) => {
          if (a.userDepartment < b.userDepartment) return -1;
          if (a.userDepartment > b.userDepartment) return 1;
          if (a.userFullName < b.userFullName) return -1;
          if (a.userFullName > b.userFullName) return 1;
          return 0;
        });
        setEvacuationData(sortedData);
      } catch (error) {
        console.error('Error fetching evacuation data:', error);
      }
    };

    if (locationId) {
      fetchEvacuationData();
    }
  }, [locationId]);

  const columns = {
    User: 'userFullName',
    DateTime: 'userLastScannedTime',
    DoorName: 'userLastScannedDoor',
    Department: 'userDepartment',
    Phone: 'userPhone'
  };

  return (
    <TableContainer ref={componentToPrint}>
      <Table>
        <TableHead>
          <RowWrapper>
            {Object.keys(columns).map(title => (
              <TableCell key={title}>{title}</TableCell>
            ))}
          </RowWrapper>
        </TableHead>

        <TableBody>
          {evacuationData.map((data, index) => (
            <RowWrapper key={index}>
              <TableCell>{data.userFullName}</TableCell>
              <TableCell>{data.userLastScannedTime}</TableCell>
              <TableCell>{data.userLastScannedDoor}</TableCell>
              <TableCell>{data.userDepartment}</TableCell>
              <TableCell>{data.userPhone}</TableCell>
            </RowWrapper>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const { grey } = theme.palette;

const RowWrapper = styled(TableRow)`
  width: 100%;
  &:nth-of-type(odd) {
    background-color: ${grey[500]};
  }
`;
