import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { styled } from '@mui/system';
import { showErrorToast, showSuccessToast } from '../../components/Toast/actions';
import { $post, $get } from '../../utils/http';

const SoftwareUpdates: React.FC = () => {
  const [noteDialogOpen, setNoteDialogOpen] = useState(false);
  const [releaseNotes, setReleaseNotes] = useState('');
  const [version, setVersion] = useState('');
  const [releaseNotesList, setReleaseNotesList] = useState<{ releasenote: string; version: string }[]>([]);

  useEffect(() => {
    const fetchReleaseNotes = async () => {
      try {
        // Haal de data op van de nieuwe endpoint
        const data = await $get('/releaseNote/all');
        console.log('Fetched data:', data); // Hiermee kun je de vorm van de data controleren
        if (Array.isArray(data)) {
          setReleaseNotesList(data); // Sla de opgehaalde release notes op
        } else {
          setReleaseNotesList([]); // Zorg ervoor dat de lijst een array is
        }
      } catch (error) {
        showErrorToast({
          title: 'Error',
          subtitle: 'Failed to fetch release notes'
        });
        setReleaseNotesList([]); // In het geval van een fout, initialiseer een lege array
      }
    };

    fetchReleaseNotes();
  }, []);

  const handleOpenDialog = () => {
    setNoteDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setNoteDialogOpen(false);
  };

  const handleSaveNote = async () => {
    try {
      // Stuur release note en versie naar de API
      await $post('/dashboard/systemUpdate', {
        releaseNote: releaseNotes,
        releaseVersion: version
      });

      // Update de lijst met release notes in de UI
      const newNote = { releasenote: releaseNotes, version };
      setReleaseNotesList(prevNotes => [...prevNotes, newNote]);

      // Maak de invoervelden leeg en sluit de dialog
      setReleaseNotes('');
      setVersion('');
      handleCloseDialog();

      showSuccessToast({
        title: 'Success',
        subtitle: 'Release note and version added successfully'
      });
    } catch (error) {
      showErrorToast({
        title: 'Error',
        subtitle: 'Failed to save release note and version'
      });
    }
  };

  return (
    <PageContainer>
      <Header>
        <Title>Release Notes</Title>
        <Button variant="contained" color="primary" onClick={handleOpenDialog}>
          Add Release Note
        </Button>
      </Header>
      <TableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Version</TableHeaderCell>
              <TableHeaderCell>Release Note</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(releaseNotesList || []).map((entry, index) => (
              <StyledTableRow key={index}>
                <TableCell>{entry.version}</TableCell>
                <TableCell>{entry.releasenote}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </StyledTable>
      </TableContainer>

      <Dialog open={noteDialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Add a Release Note</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Version Number"
            type="text"
            fullWidth
            value={version}
            onChange={e => setVersion(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Release Note"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={releaseNotes}
            onChange={e => setReleaseNotes(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveNote} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </PageContainer>
  );
};

export default SoftwareUpdates;

const PageContainer = styled(Box)`
  width: 100%;
  padding: 16px;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled(Typography)`
  font-family: Lato;
  font-size: 24px;
  font-weight: 800;
`;

const StyledTable = styled(Table)`
  width: 100%;
`;

const TableHeaderCell = styled(MuiTableCell)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 800;
  text-align: left;
  padding: 8px;
  background-color: white;
`;

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(even) {
    background-color: #f9f9f9;
  }
`;

const TableCell = styled(MuiTableCell)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 8px;
`;
