import React, { useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface LanguageSelectorProps {
  className?: string;
}

const LanguageSelector: React.FunctionComponent<LanguageSelectorProps> = ({ className }) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(() => {
    const storedLanguage = localStorage.getItem('i18nextLng') || 'en';
    return storedLanguage;
  });

  useEffect(() => {
    const storedLanguage = localStorage.getItem('i18nextLng');
    if (!storedLanguage) {
      localStorage.setItem('i18nextLng', 'en');
      i18n.changeLanguage('en');
      setLanguage('en');
    } else {
      i18n.changeLanguage(storedLanguage);
      setLanguage(storedLanguage);
    }
  }, [i18n]);

  const handleChangeLanguage = (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('i18nextLng', newLanguage);
  };

  return (
    <LanguageSelectorContainer className={className} variant="outlined">
      <InputLabel id="language-select-label">{t('Language')}</InputLabel>
      <Select
        labelId="language-select-label"
        id="language-select"
        value={language}
        onChange={handleChangeLanguage}
        label={t('Language')}>
        <MenuItem value="en">English</MenuItem>
        <MenuItem value="nl">Nederlands</MenuItem>
        <MenuItem value="de">Deutsch</MenuItem>
      </Select>
    </LanguageSelectorContainer>
  );
};

export default LanguageSelector;

const LanguageSelectorContainer = styled(FormControl)`
  min-width: 120px;
`;
