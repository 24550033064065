import { Route, unstable_HistoryRouter as Router, Routes } from 'react-router-dom';
import NotFound from './components/NotFound';
import ProtectedRoute from './components/ProtectedRoute';
import Authentication from './modules/Auth';
import { Master } from './modules/Master';
import history from './utils/history';
import { AppContextProvider } from './appContext'; // Import the AppProvider

const App = (): JSX.Element => {
  return (
    <AppContextProvider>
      {' '}
      {/* Wrap the application in the AppProvider */}
      <Router history={history}>
        <Routes>
          <Route path="/auth/*" element={<Authentication />} />
          <Route
            path="/*"
            element={
              <ProtectedRoute>
                <Master />
              </ProtectedRoute>
            }
          />
          <Route path="not-found" element={<NotFound />} />
        </Routes>
      </Router>
    </AppContextProvider>
  );
};

export default App;
