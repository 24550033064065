import { Box, CircularProgress, styled } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { theme } from '../../../../styles/theme';
import { ContentWrapper } from '../components/ContentWrapper';
import NoticeMessage from '../components/NoticeMessage';
import { useLocationHook } from '../store';
import { HeaderLocationInformation } from './HeaderLocationInformation';
import { LocationInformation } from './LocationInformation';

export const LocationInfo: React.FunctionComponent = () => {
  const [{ locationList, locationInfo }, { getLocationInfo }] = useLocationHook();
  const { id } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function getLocationInformation(id: string) {
    setIsLoading(true);
    try {
      await getLocationInfo(id);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    id && getLocationInformation(id);
  }, [id]);

  return (
    <ContentWrapper>
      {isEmpty(locationList) ? (
        <Box width="100%" height="100%" />
      ) : isLoading ? (
        <LoadingWrapper>
          <CircularProgress color="primary" size={40} />
        </LoadingWrapper>
      ) : locationInfo.location ? (
        <>
          <HeaderLocationInformation />

          <LocationInformation />
        </>
      ) : (
        <NoticeMessage message={t('location.no_location')} />
      )}
    </ContentWrapper>
  );
};

const LoadingWrapper = styled(Box)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.grey[500]};
  opacity: 70%;
  width: 100%;
  box-shadow: ${theme.shadows[3]};
`;
