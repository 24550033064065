import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectBoxWithLabel } from '../../components/SelectBox';
import { FilterOptionsWrapper } from '../../components';
import { ReportLogFormField } from '../../types';
import { $get } from '../../../../utils/http';

const { SELECT_USER_IDS: SELECT_USER } = ReportLogFormField;

interface UserOption {
  id: string;
  value: string;
  label: string;
}

interface LogData {
  id: string;
  uid: string;
  name: string;
  doorName: string;
  doorLocation: string | null;
  doorlocationId: string | null;
  department: string;
  timeDate: string;
}

interface LogResponse {
  data: LogData[];
  code: string;
  message: string | null;
  total: number;
}

interface UserSelectProps {
  name: string;
}

const UserSelect: React.FunctionComponent<UserSelectProps> = ({ name }) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserOption[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response: LogResponse = await $get('log/user');
        const uniqueUsers = Array.from(
          new Map(
            response.data.map((log: LogData) => [
              log.uid,
              {
                id: log.uid,
                value: log.uid,
                label: `${log.name} (${log.department})`
              }
            ])
          ).values()
        );
        setUsers(uniqueUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <FilterOptionsWrapper width="30%" minWidth="350px">
      <SelectBoxWithLabel name={name} label={t('report.log_history.filter_option.user')} options={users} />
    </FilterOptionsWrapper>
  );
};

export default UserSelect;
