import enTranslations from '../../languages/locales/en.json';
import nlTranslations from '../../languages/locales/nl.json';
import deTranslations from '../../languages/locales/de.json';
import fs from 'fs';
import path from 'path';

export const loadTranslations = async (lang: string): Promise<Record<string, string>> => {
  const module = await import(`../../languages/locales/${lang}.json`);
  return module.default;
};

export const saveTranslations = (lang: string, translations: Record<string, string>): void => {
  localStorage.setItem(`translations_${lang}`, JSON.stringify(translations));
};
