import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleBackToDashboard = () => {
    navigate('/');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      textAlign="center"
      sx={{
        background: 'linear-gradient(180deg, #9FBF43 0%, #698223 100%)',
        color: 'white'
      }}>
      <Typography variant="h1" color="inherit">
        4😔4
      </Typography>
      <Typography variant="h5" color="inherit" marginBottom={2} fontWeight={700} fontSize={40}>
        OOps! {t('notfound.page_not_found')}
      </Typography>
      <Typography variant="body1" color="inherit" marginBottom={4}>
        {t('notfound.page_doesnt_exist')}
        <br />
        {t('notfound.back_to_dashboard_suggestion')}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleBackToDashboard}>
        {t('notfound.back_to_dashboard')}
      </Button>
    </Box>
  );
};

export default NotFound;
