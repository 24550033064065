import React from 'react';
import { ReportWrapper } from '../components';
import { useReportActions } from '../store';
import FilterOptions from './FilterOptions';
import GridTable from './GridTable';

interface FilterValues {
  userId?: string;
  locationId?: string;
  startDate?: string;
  endDate?: string;
}

const LogHistoryReport: React.FunctionComponent = () => {
  const { getReportLogs } = useReportActions();
  const [filters, setFilters] = React.useState<FilterValues | null>(null);

  React.useEffect(() => {
    getReportLogs();
  }, []);

  const handleFilterChange = (newFilters: FilterValues) => {
    setFilters(newFilters);
  };

  return (
    <ReportWrapper>
      <FilterOptions onFilterChange={handleFilterChange} />
      <GridTable filters={filters} />
    </ReportWrapper>
  );
};

export default LogHistoryReport;
