import React from 'react';

import { Autocomplete, Checkbox, Chip, MenuItem, styled, TextField, Typography } from '@mui/material';

import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../../appContext';
import { dimensions } from '../../../styles/dimensions';
import { theme } from '../../../styles/theme';
import { StorageKeys } from '../../../types/storage';

import { Location } from '../../../models';

const { primary, grey, common } = theme.palette;
const { baseMD, inputHeight } = dimensions;

type Props = {
  onOptionsChanged: (locations: Location[]) => Promise<void>;
};

const SelectLocations: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();

  const { onOptionsChanged } = props;

  const { locations } = useAppContext();

  const storageLocations = sessionStorage.getItem(StorageKeys.LOCATIONS);

  const defaultLocations = storageLocations ? JSON.parse(storageLocations) : [];

  const handleTagsRender = (tagValues: Location[]) =>
    tagValues.map(option => <LocationChip key={option.id} label={option.location} />);

  const handleOptionRender = (props: React.HTMLAttributes<HTMLLIElement>, option: Location, selected: boolean) => (
    <StyledMenuItem key={option.id} value={option.id} {...props}>
      <LocationCheckBox checked={selected} />

      <Typography variant="body1" color={selected ? primary.main : common.black}>
        {option.location}
      </Typography>
    </StyledMenuItem>
  );

  React.useEffect(() => {
    if (!isEmpty(defaultLocations)) sessionStorage.removeItem(StorageKeys.LOCATIONS);
  }, []);

  return (
    <Autocomplete
      size="small"
      multiple
      freeSolo={false}
      limitTags={1}
      options={locations}
      onKeyDown={e => e.preventDefault()}
      disableCloseOnSelect
      disableClearable={true}
      getLimitTagsText={more => <LocationChip label={t('dashboard.select_locations.has_more', { number: more })} />}
      getOptionLabel={option => option.location}
      onChange={(_e, locations) => onOptionsChanged(locations)}
      renderTags={handleTagsRender}
      renderOption={(props, option, { selected }) => handleOptionRender(props, option, selected)}
      renderInput={params => (
        <Input
          {...params}
          disabled={true}
          label={t('dashboard.select_locations.content')}
          inputProps={{
            ...params.inputProps,
            onKeyDown: e => {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        />
      )}
      sx={{ caretColor: common.white }}
    />
  );
};

export default SelectLocations;

const LocationChip = styled(Chip)`
  max-width: 120px;
  background-color: ${primary.lighter};
  border: 1px solid ${primary.light};
`;

const Input = styled(TextField)`
  width: 300px;
  display: flex;
  flex-direction: row;

  .MuiOutlinedInput-input {
    cursor: pointer;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  width: 94%;
  margin-left: 3%;
  height: ${inputHeight};
  border-bottom: 1px solid ${grey[400]};
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.Mui-selected {
    background-color: ${common.white};
  }
`;

const LocationCheckBox = styled(Checkbox)`
  width: ${baseMD};
  height: ${baseMD};
  padding-right: ${baseMD};
`;
