export enum DoorStatus {
  CLOSED = 'CLOSED',
  DISCONNECTED = 'DISCONNECTED',
  OPENING = 'OPENING',
  OPEN_PERMANENT = 'OPEN PERMANENT',
  CLOSED_PERMANENT = 'CLOSED PERMANENT'
}

export enum DoorActions {
  OPEN = 'Open',
  OPEN_PERMANENT = 'Open permanent',
  CLOSED_PERMANENT = 'Closed permanent',
  RESET_DEFAULT = 'Reset to default'
}

export enum DoorTableColumn {
  ID = 'doorId',
  DOOR_NAME = 'doorName',
  DOOR_LOCATION = 'doorLocation',
  DOOR_SECTION = 'doorSection',
  DOOR_TYPE = 'doorType',
  STATUS = 'doorStatus',
  ACTIONS = 'actions'
}

export const DEFAULT_ID_COL_WIDTH = '15%';

export const DEFAULT_COL_WIDTH = '17%';

export const DEFAULT_ACTION_COL_WIDTH = '27%';
