import React from 'react';
import { useTranslation } from 'react-i18next';
import { DoorAuthorization as DoorAuthorizationType } from '../../../../../models';
import { useUsersHook } from '../../../store';
import { GridTable } from '../../components';
import { TableAction } from '../types';
import _ from 'lodash';
import { Box, styled } from '@mui/material';

type Props = {
  onUsedDoorIdsChanged: (doorIds: string[]) => void;
  initialDoorIds: string[];
};

const StyledBox = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Ruimte tussen de tabellen */
`;

const TableContainer = styled(Box)`
  flex: 1;
  min-width: 435px; /* Zorgt ervoor dat de tabellen niet te smal worden */
`;

export const DoorAuthorization: React.FunctionComponent<Props> = ({ onUsedDoorIdsChanged, initialDoorIds }) => {
  const { t } = useTranslation();

  const [{ groupInformation, existedDoors }, { getExistedDoors }] = useUsersHook();
  const { objectsEnabled: doorAuthorization } = groupInformation || {};

  const [usedDoors, setUsedDoors] = React.useState<DoorAuthorizationType[]>([]);
  const [notUsedDoors, setNotUsedDoors] = React.useState<DoorAuthorizationType[]>([]);

  const titles = [
    t('group.door_authorization.column.door'),
    t('group.door_authorization.column.location'),
    t('group.door_authorization.column.module'),
    t('group.action')
  ];

  const placeHolder = t('group.door_authorization.placeholder');

  const sortDoorsByDoorId = (doors: DoorAuthorizationType[]) =>
    doors.sort((prev, next) => parseInt(prev.doorId, 10) - parseInt(next.doorId, 10));

  const handleAddDoorIntoGroup = (door: DoorAuthorizationType) => {
    const newNotUsedDoorsInGroup = sortDoorsByDoorId(notUsedDoors.filter(item => item.doorId !== door.doorId));
    const newUsedDoor = [door, ...usedDoors];

    onUsedDoorIdsChanged(newUsedDoor.map(item => item.doorId));

    setUsedDoors(newUsedDoor);
    setNotUsedDoors(newNotUsedDoorsInGroup);
  };

  const handleRemoveDoorIntoGroup = (door: DoorAuthorizationType) => {
    const newUsedDoorsInGroup = sortDoorsByDoorId(usedDoors.filter(item => item.doorId !== door.doorId));

    onUsedDoorIdsChanged(newUsedDoorsInGroup.map(item => item.doorId));

    setNotUsedDoors(sortDoorsByDoorId([door, ...notUsedDoors]));
    setUsedDoors(newUsedDoorsInGroup);
  };

  React.useEffect(() => {
    getExistedDoors();
  }, []);

  React.useEffect(() => {
    if (existedDoors) {
      const initialUsedDoors = existedDoors.filter(door => initialDoorIds.includes(door.doorId));
      const initialNotUsedDoors = existedDoors.filter(door => !initialDoorIds.includes(door.doorId));

      setUsedDoors(initialUsedDoors);
      setNotUsedDoors(initialNotUsedDoors);
    }
  }, [existedDoors, initialDoorIds]);

  return (
    <StyledBox>
      <TableContainer>
        <GridTable
          header={t('group.door_authorization.used_in_the_group')}
          titles={titles}
          rows={usedDoors.map(({ doorName, doorLocation, moduleName }) => ({
            doorName,
            doorLocation,
            moduleName
          }))}
          actionType={TableAction.REMOVE}
          action={row => {
            const door = usedDoors.find(
              door =>
                door.doorName === row.doorName &&
                door.doorLocation === row.doorLocation &&
                door.moduleName === row.moduleName
            );
            if (door) {
              handleRemoveDoorIntoGroup(door);
            }
          }}
          placeholder={placeHolder}
        />
      </TableContainer>
      <TableContainer>
        <GridTable
          header={t('group.door_authorization.not_used_in_group')}
          titles={titles}
          rows={notUsedDoors.map(({ doorName, doorLocation, moduleName }) => ({
            doorName,
            doorLocation,
            moduleName
          }))}
          actionType={TableAction.ADD}
          action={row => {
            const door = notUsedDoors.find(
              door =>
                door.doorName === row.doorName &&
                door.doorLocation === row.doorLocation &&
                door.moduleName === row.moduleName
            );
            if (door) {
              handleAddDoorIntoGroup(door);
            }
          }}
          placeholder={placeHolder}
        />
      </TableContainer>
    </StyledBox>
  );
};
