import React, { useState, MouseEvent, useEffect } from 'react';
import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Tooltip,
  Button,
  Autocomplete,
  Checkbox,
  Chip,
  MenuItem,
  IconButton,
  Menu,
  Divider,
  CircularProgress,
  TypographyProps
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { theme } from '../../styles/theme';
import { dimensions } from '../../styles/dimensions';
import { useDoorHook } from './store';
import { useTranslation } from 'react-i18next';
import { Location } from '../../models';
import { Door } from '../../models/door';
import { POLLING_TIME } from '../Dashboard/types';
import { showErrorToast, showSuccessToast } from '../../components/Toast/actions';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import { DoorActions, DoorStatus, DoorTableColumn } from './types';
import { $get, $post } from '../../utils/http';
import { useAppContext } from '../../appContext';
import SearchBar from '../../components/SearchBar';
import { getRowColor, statusBoxMainColor, statusBoxBackgroundColor } from './utils';

const { primary, grey, common } = theme.palette;
const { baseSM, base3XL, baseMD, baseLG, rowHeight, inputHeight } = dimensions;

const getColWidth = (coltitle: DoorTableColumn, isResponsive = false) => {
  switch (coltitle) {
    case DoorTableColumn.DOOR_NAME:
      return isResponsive ? '600px' : '50px';
    default:
      return isResponsive ? '100px' : '150px';
  }
};

const DoorManagement: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { locations } = useAppContext();
  const [{ doorList }, { getAllDoors, changeDoorStatus }] = useDoorHook();
  const [loading, setLoading] = useState<boolean>(false);
  const [openNoteDialog, setOpenNoteDialog] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [user, setUser] = useState<string>('');
  const [selectedDoorId, setSelectedDoorId] = useState<string | null>(null);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [pendingAction, setPendingAction] = useState<DoorActions | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedLocations, setSelectedLocations] = useState<Location[]>(() => {
    const savedLocations = localStorage.getItem('selectedLocations');
    if (savedLocations) {
      return JSON.parse(savedLocations);
    }
    const defaultLocation = localStorage.getItem('defaultLocation');
    if (defaultLocation) {
      return [JSON.parse(defaultLocation)];
    }
    return [];
  });
  const [filteredDoors, setFilteredDoors] = useState<Door[]>([]);
  const [sortConfig, setSortConfig] = useState<{ key: keyof Door; direction: 'asc' | 'desc' } | null>(() => {
    const savedSortConfig = localStorage.getItem('sortConfig');
    return savedSortConfig ? JSON.parse(savedSortConfig) : null;
  });
  const [openColumnConfigDialog, setOpenColumnConfigDialog] = useState<boolean>(false);
  const [visibleColumns, setVisibleColumns] = useState<string[]>(() => {
    const savedColumns = localStorage.getItem('visibleColumns');
    return savedColumns
      ? JSON.parse(savedColumns)
      : [
          DoorTableColumn.DOOR_NAME,
          DoorTableColumn.DOOR_LOCATION,
          DoorTableColumn.DOOR_SECTION,
          DoorTableColumn.DOOR_TYPE,
          DoorTableColumn.STATUS
        ];
  });

  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; doorId: string | null } | null>(
    null
  );

  const [tooltipLoading, setTooltipLoading] = useState<boolean>(false);
  const [tooltipContent, setTooltipContent] = useState<string>('');
  const [openInfoDialog, setOpenInfoDialog] = useState<boolean>(false);

  const navigate = useNavigate();

  const tableColumns = [
    { dataKey: DoorTableColumn.DOOR_NAME, fieldName: `${t('door.door_name')}`, padding: '3px' },
    { dataKey: DoorTableColumn.DOOR_LOCATION, fieldName: `${t('door.door_location')}`, padding: '3px' },
    { dataKey: DoorTableColumn.DOOR_SECTION, fieldName: `${t('door.door_section')}`, padding: '3px' },
    { dataKey: DoorTableColumn.DOOR_TYPE, fieldName: `${t('door.door_type')}`, padding: '3px' },
    { dataKey: DoorTableColumn.STATUS, fieldName: `${t('door.status')}`, padding: '3px' }
  ];

  const dataGetter = (data: Record<string, string | number | Date>, id: string): any => data[id];

  const isActionDisabled = (action: DoorActions, status: DoorStatus): boolean => {
    switch (status) {
      case DoorStatus.CLOSED:
        return false;
      case DoorStatus.DISCONNECTED:
        return true;
      case DoorStatus.OPENING:
        return action === DoorActions.OPEN;
      case DoorStatus.OPEN_PERMANENT:
        return action !== DoorActions.RESET_DEFAULT;
      case DoorStatus.CLOSED_PERMANENT:
        return action !== DoorActions.CLOSED_PERMANENT;
      default:
        return false;
    }
  };

  const getActionColor = (action: DoorActions, status: DoorStatus): string =>
    isActionDisabled(action, status) ? grey[300] : primary.dark;

  const getActionCursor = (action: DoorActions, status: DoorStatus): string =>
    isActionDisabled(action, status) ? 'context-menu' : 'pointer';

  const getDoorStatusByAction = (action: DoorActions): DoorStatus => {
    switch (action) {
      case DoorActions.OPEN:
        return DoorStatus.OPENING;
      case DoorActions.OPEN_PERMANENT:
        return DoorStatus.OPEN_PERMANENT;
      case DoorActions.CLOSED_PERMANENT:
        return DoorStatus.CLOSED_PERMANENT;
      case DoorActions.RESET_DEFAULT:
        return DoorStatus.CLOSED;
      default:
        return DoorStatus.CLOSED;
    }
  };

  const renderCell = (info: Door, prop: string) => {
    if (prop !== DoorTableColumn.STATUS) return dataGetter(info, prop);
    return <StatusBox status={dataGetter(info, prop)}>{dataGetter(info, prop)}</StatusBox>;
  };

  const onActionClick = async (id: string, action: DoorActions, status: DoorStatus): Promise<void> => {
    if (isActionDisabled(action, status)) return;

    if (action === DoorActions.CLOSED_PERMANENT || action === DoorActions.OPEN_PERMANENT) {
      handleOpenNoteDialog(id, action);
      return;
    }

    setLoading(true);
    try {
      if (action === DoorActions.RESET_DEFAULT) {
        await $post(`/objectNote/${id}`, { objectId: id, objectNote: '' });
      }
      await changeDoorStatus(id, getDoorStatusByAction(action));
      showSuccessToast({
        title: t('door.toast.success.title'),
        subtitle: t('door.toast.success.subtitle')
      });
    } catch (_err) {
      showErrorToast({
        title: t('door.toast.failed.title'),
        subtitle: t('door.toast.failed.subtitle')
      });
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  const getDoors = async () => {
    setLoading(true);
    await getAllDoors();
    setLoading(false);
  };

  const handleOpenNoteDialog = async (doorId: string, action: DoorActions) => {
    setSelectedDoorId(doorId);
    setDialogTitle(action === DoorActions.CLOSED_PERMANENT ? 'Note Closed Permanent' : 'Note Open Permanent');
    setPendingAction(action);
    try {
      const response = await $get(`objectNote/${doorId}`);

      // Debugging - log de volledige response
      console.log('API Response:', response);

      setNote(response?.objectNote || '');
      setUser(response?.settingUser || '');

      // Controleer of 'user' beschikbaar is en log het naar de console
      if (response?.settingUser) {
        console.log('User:', response.settingUser);
        setUser(response.settingUser);
      } else {
        console.warn('No user found in response');
      }
    } catch (error) {
      console.error('Failed to fetch note:', error); // In geval van een fout
      setNote('');
      setUser(''); // Reset de user naar een lege waarde bij een fout
    }
    setOpenNoteDialog(true);
  };

  const handleOpenChangeNoteDialog = async (doorId: string) => {
    setSelectedDoorId(doorId);
    setDialogTitle('Change Note');
    setPendingAction(null); // Zorg ervoor dat er geen actie is ingesteld, zodat de status niet wordt gewijzigd
    try {
      const response = await $get(`objectNote/${doorId}`);
      setNote(response.objectNote || '');
      setUser(response?.settingUser || '');
    } catch (error) {
      console.error('Failed to fetch note:', error);
      setNote('');
    }
    setOpenNoteDialog(true);
  };

  const handleOpenInfoDialog = async (doorId: string, doorStatus: DoorStatus) => {
    setSelectedDoorId(doorId);
    setDialogTitle(doorStatus);
    try {
      const response = await $get(`objectNote/${doorId}`);
      setNote(response.objectNote || 'No note available');
      setUser(response?.settingUser || 'No user available');
    } catch (error) {
      console.error('Failed to fetch note:', error);
      setNote('No note available');
    }
    setOpenInfoDialog(true);
  };

  const handleCloseNoteDialog = () => {
    setOpenNoteDialog(false);
    setNote('');
    setSelectedDoorId(null);
    setDialogTitle('');
    setPendingAction(null);
    handleClose();
  };

  const handleCloseInfoDialog = () => {
    setOpenInfoDialog(false);
    setNote('');
    setSelectedDoorId(null);
    setDialogTitle('');
  };

  const handleSaveNote = async () => {
    if (selectedDoorId) {
      try {
        await $post(`/objectNote/${selectedDoorId}`, { objectId: selectedDoorId, objectNote: note });
        if (pendingAction) {
          await changeDoorStatus(selectedDoorId, getDoorStatusByAction(pendingAction));
        }
        showSuccessToast({
          title: t('door.toast.note.success.title'),
          subtitle: t('door.toast.note.success.subtitle')
        });
      } catch (error) {
        console.error('Failed to save note:', error);
        showErrorToast({
          title: t('door.toast.note.failed.title'),
          subtitle: t('door.toast.note.failed.subtitle')
        });
      }
    }
    handleCloseNoteDialog();
  };

  const handleLocationChange = (locations: Location[]) => {
    setSelectedLocations(locations);
    localStorage.setItem('selectedLocations', JSON.stringify(locations));
    applyFilterAndSort(doorList, locations, sortConfig, searchQuery);
  };

  // Nieuwe functie om alleen de zoekopdracht te wissen
  const handleClearSearchQuery = () => {
    setSearchQuery('');
    applyFilterAndSort(doorList, selectedLocations, sortConfig, '');
  };

  const handleToggleColumn = (column: string) => {
    if (column === DoorTableColumn.DOOR_NAME || column === DoorTableColumn.STATUS) return;
    setVisibleColumns(prev => {
      const newColumns = prev.includes(column) ? prev.filter(col => col !== column) : [...prev, column];
      localStorage.setItem('visibleColumns', JSON.stringify(newColumns));
      return newColumns;
    });
  };

  const handleContextMenu = (event: MouseEvent, doorId: string) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      doorId: doorId
    });
    const rows = document.querySelectorAll('.MuiTableRow-root');
    rows.forEach(row => row.classList.remove('highlight'));
    const row = event.currentTarget as HTMLElement;
    row.classList.add('highlight');
  };

  const handleClose = () => {
    setContextMenu(null);
    const rows = document.querySelectorAll('.MuiTableRow-root');
    rows.forEach(row => row.classList.remove('highlight'));
  };

  const handleClearFilters = () => {
    // Verwijder alleen de zoekopdracht, zodat andere filters behouden blijven
    setSearchQuery(''); // Zet de zoekopdracht terug naar een lege string
    applyFilterAndSort(doorList, selectedLocations, sortConfig, ''); // Herhaal de filter- en sorteerlogica zonder zoekterm
  };

  useEffect(() => {
    getDoors();
    const pollingGetAllDoors = setInterval(() => {
      getAllDoors();
    }, POLLING_TIME);
    return () => clearInterval(pollingGetAllDoors);
  }, []);

  useEffect(() => {
    applyFilterAndSort(doorList, selectedLocations, sortConfig, searchQuery);
  }, [doorList, selectedLocations, sortConfig, searchQuery]);

  const applyFilterAndSort = (
    doors: Door[],
    locations: Location[],
    sortConfig: { key: keyof Door; direction: 'asc' | 'desc' } | null,
    searchQuery: string
  ) => {
    let updatedDoors = [...doors];
    if (locations.length > 0) {
      updatedDoors = updatedDoors.filter(door => locations.some(location => location.location === door.doorLocation));
    }

    if (searchQuery) {
      const lowercasedFilter = searchQuery.toLowerCase();
      updatedDoors = updatedDoors.filter(item => item.doorName.toLowerCase().includes(lowercasedFilter));
    }

    if (sortConfig) {
      updatedDoors.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    setFilteredDoors(updatedDoors);
  };

  const requestSort = (key: keyof Door) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    const newSortConfig = { key, direction };
    setSortConfig(newSortConfig);
    localStorage.setItem('sortConfig', JSON.stringify(newSortConfig));
    applyFilterAndSort(filteredDoors, selectedLocations, newSortConfig, searchQuery);
  };

  const getSortIndicator = (column: keyof Door) => {
    if (!sortConfig) return null;
    return sortConfig.key === column ? (
      sortConfig.direction === 'asc' ? (
        <ExpandLessOutlinedIcon />
      ) : (
        <ExpandMoreOutlinedIcon />
      )
    ) : null;
  };

  const handleTagsRender = (tagValues: Location[], getTagProps: any) =>
    tagValues.map((option, index) => (
      <LocationChip
        key={option.id}
        label={option.location}
        {...getTagProps({ index })}
        sx={index === 0 ? { width: '240px' } : { width: '120px' }}
      />
    ));

  const handleOptionRender = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: Location,
    { selected }: { selected: boolean }
  ) => (
    <StyledMenuItem key={option.id} value={option.id} {...props}>
      <Checkbox checked={selected} />
      <Typography variant="body1" color={selected ? primary.main : common.black}>
        {option.location}
      </Typography>
    </StyledMenuItem>
  );

  const fetchNoteForTooltip = async (doorId: string) => {
    setTooltipLoading(true);
    try {
      const response = await $get(`objectNote/${doorId}`);
      setTooltipContent(response.objectNote || 'NO INFORMATION AVAILABLE');
    } catch (error) {
      console.error('Failed to fetch note:', error);
      setTooltipContent('NO INFORMATION AVAILABLE');
    } finally {
      setTooltipLoading(false);
    }
  };

  return (
    <Wrapper>
      <Header>
        <StyledAutocomplete
          size="small"
          multiple
          freeSolo={false}
          limitTags={1}
          options={locations}
          onKeyDown={e => e.preventDefault()}
          disableCloseOnSelect
          getLimitTagsText={more => (
            <LocationChip label={t('dashboard.select_locations.has_more', { number: more })} sx={{ width: '120px' }} />
          )}
          getOptionLabel={(option: Location) => option.location}
          value={selectedLocations} // Zorg ervoor dat de geselecteerde locaties worden weergegeven
          onChange={(_e, locations) => handleLocationChange(locations as Location[])}
          renderTags={(tagValues: Location[], getTagProps) => handleTagsRender(tagValues, getTagProps)}
          renderOption={(props, option: Location, { selected }) => handleOptionRender(props, option, { selected })}
          renderInput={params => (
            <Input
              {...params}
              disabled
              label={t('dashboard.select_locations.content')}
              inputProps={{
                ...params.inputProps,
                onKeyDown: e => {
                  e.preventDefault();
                  e.stopPropagation();
                }
              }}
            />
          )}
          sx={{ caretColor: common.white, marginLeft: '16px' }}
          ChipProps={{
            sx: { display: 'inline-flex', flexWrap: 'nowrap' }
          }}
        />
        <SearchBarContainer>
          <SearchBar
            onSearch={(value: string) => setSearchQuery(value)}
            placeHolder={t('door.search_placeholder')}
            onClear={handleClearSearchQuery} // Gebruik de nieuwe functie om alleen de zoekopdracht te wissen
            keyword={searchQuery}
            onKeywordChanged={setSearchQuery}
          />
        </SearchBarContainer>
        {(selectedLocations.length > 0 || searchQuery || sortConfig) && (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClearFilters}
            startIcon={<ClearIcon />}
            sx={{ marginLeft: '16px', marginRight: 'auto' }}>
            {t('door.clear_filters')}
          </Button>
        )}
        <SettingsButton
          variant="outlined"
          color="primary"
          startIcon={<SettingsOutlinedIcon />}
          onClick={() => setOpenColumnConfigDialog(true)}>
          {t('door.column_settings.content')}
        </SettingsButton>
      </Header>

      {loading ? (
        <LoadingIndicator size={48} width="100%" height={`calc(100% - ${rowHeight})`} position="relative" />
      ) : (
        <Body>
          <Container>
            <StyledTable stickyHeader>
              <StyledTableHead>
                <TableRow>
                  {tableColumns.map(
                    column =>
                      visibleColumns.includes(column.dataKey) && (
                        <TitleCell
                          key={column.dataKey}
                          paddingitem={column.padding}
                          coltitle={column.dataKey}
                          onClick={() => requestSort(column.dataKey as keyof Door)}>
                          <TitleWrapper>
                            {column.fieldName}
                            {getSortIndicator(column.dataKey as keyof Door)}
                          </TitleWrapper>
                        </TitleCell>
                      )
                  )}
                  <TitleCell paddingitem="3px" coltitle={DoorTableColumn.ACTIONS}>
                    <InfoOutlinedIcon />
                  </TitleCell>
                </TableRow>
              </StyledTableHead>

              <TableBody>
                {filteredDoors.map(info => (
                  <InformationRow
                    key={info.doorId}
                    status={info.doorStatus}
                    onContextMenu={event => handleContextMenu(event, info.doorId)}>
                    {tableColumns.map(
                      (column, index) =>
                        visibleColumns.includes(column.dataKey) && (
                          <InformationCell
                            key={column.dataKey}
                            paddingitem={column.padding}
                            coltitle={column.dataKey}
                            style={{ height: '30px' }}
                            className="table-cell">
                            {renderCell(info, column.dataKey)}
                          </InformationCell>
                        )
                    )}
                    <InformationCell paddingitem="3px" coltitle={DoorTableColumn.ACTIONS} style={{ height: '30px' }}>
                      {(info.doorStatus === DoorStatus.OPEN_PERMANENT ||
                        info.doorStatus === DoorStatus.CLOSED_PERMANENT) && (
                        <Tooltip
                          title={tooltipLoading ? <CircularProgress size={20} /> : tooltipContent}
                          onOpen={() => fetchNoteForTooltip(info.doorId)}>
                          <InfoOutlinedIcon
                            sx={{
                              color: primary.dark,
                              cursor: 'pointer'
                            }}
                            onClick={() => handleOpenInfoDialog(info.doorId, info.doorStatus)}
                          />
                        </Tooltip>
                      )}
                    </InformationCell>
                  </InformationRow>
                ))}
                {Array.from({ length: Math.max(10 - filteredDoors.length, 0) }).map((_, index) => (
                  <TableRow key={index}>
                    {tableColumns.map(
                      column =>
                        visibleColumns.includes(column.dataKey) && (
                          <TableCell key={column.dataKey} style={{ height: '30px' }} />
                        )
                    )}
                    <TableCell style={{ height: '30px' }} />
                  </TableRow>
                ))}
              </TableBody>
            </StyledTable>
          </Container>
        </Body>
      )}

      <Dialog open={openNoteDialog} onClose={handleCloseNoteDialog} maxWidth="md" fullWidth>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={dialogTitle}
            type="text"
            fullWidth
            multiline
            rows={7}
            value={note}
            onChange={e => setNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNoteDialog} color="primary">
            {t('door.note_dialog.cancel')}
          </Button>
          <Button onClick={handleSaveNote} color="primary">
            {t('door.note_dialog.save')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openInfoDialog} onClose={handleCloseInfoDialog} maxWidth="md" fullWidth>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{note}</Typography>
        </DialogContent>
        <Box sx={{ textAlign: 'right', mt: 1, mr: 4 }}>
          {' '}
          <Typography variant="body2" color="textSecondary">
            {t('door.note_dialog.created_by')} {user ? user : 'Geen gebruiker gevonden'}
          </Typography>
        </Box>
        <DialogActions>
          <Button onClick={handleCloseInfoDialog} color="primary">
            {t('door.info_dialog.close')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openColumnConfigDialog} onClose={() => setOpenColumnConfigDialog(false)} maxWidth="xs" fullWidth>
        <DialogTitleWrapper>
          <DialogTitleText variant="h4">{t('dashboard.column_settings.content')}</DialogTitleText>
          <IconButton onClick={() => setOpenColumnConfigDialog(false)}>
            <CloseIcon />
          </IconButton>
        </DialogTitleWrapper>

        <DialogContent>
          {tableColumns.map((column, index) => (
            <CheckBoxLine key={column.dataKey}>
              <Typography variant="body1">{column.fieldName}</Typography>
              <Checkbox
                checked={visibleColumns.includes(column.dataKey)}
                onChange={() => handleToggleColumn(column.dataKey)}
                disabled={column.dataKey === DoorTableColumn.DOOR_NAME || column.dataKey === DoorTableColumn.STATUS}
              />
            </CheckBoxLine>
          ))}
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenColumnConfigDialog(false)} color="primary">
            {t('dashboard.column_settings.cancel')}
          </Button>
          <Button onClick={() => setOpenColumnConfigDialog(false)} color="primary">
            {t('dashboard.column_settings.apply')}
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        PaperProps={{
          style: {
            width: '154px',
            maxHeight: 'fit-content',
            padding: '8px 0',
            borderRadius: '4px 0 0 0',
            opacity: 1
          }
        }}>
        <StyledMenuItem onClick={() => onActionClick(contextMenu!.doorId!, DoorActions.OPEN, DoorStatus.CLOSED)}>
          Open
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem
          onClick={() => onActionClick(contextMenu!.doorId!, DoorActions.OPEN_PERMANENT, DoorStatus.CLOSED)}>
          Open Permanent
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem
          onClick={() => onActionClick(contextMenu!.doorId!, DoorActions.CLOSED_PERMANENT, DoorStatus.CLOSED)}>
          Close Permanent
        </StyledMenuItem>
        <Divider />
        <StyledMenuItem
          onClick={() => onActionClick(contextMenu!.doorId!, DoorActions.RESET_DEFAULT, DoorStatus.CLOSED)}>
          Reset Default
        </StyledMenuItem>
        <Divider />
        {contextMenu &&
          (filteredDoors.find(door => door.doorId === contextMenu.doorId)?.doorStatus === DoorStatus.OPEN_PERMANENT ||
            filteredDoors.find(door => door.doorId === contextMenu.doorId)?.doorStatus ===
              DoorStatus.CLOSED_PERMANENT) && (
            <>
              <StyledMenuItem onClick={() => handleOpenChangeNoteDialog(contextMenu.doorId!)}>
                Change Note
              </StyledMenuItem>
              <Divider />
            </>
          )}
      </Menu>
    </Wrapper>
  );
};

export default DoorManagement;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: ${grey[500]};
  height: 100%;
  overflow: auto;
`;

const Header = styled(Box)`
  background-color: white;
  height: ${base3XL};
  display: flex;
  align-items: center;
`;

const SearchBarContainer = styled(Box)`
  width: 250px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 16px;
`;

const Body = styled(Box)`
  background-color: white;
  height: 100%;
  overflow: auto;
`;

const Container = styled(TableContainer)`
  border: 1px solid ${grey[400]};
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  overflow: auto;
`;

const TitleCell = styled(TableCell)<{ paddingitem?: string; coltitle: DoorTableColumn }>(
  ({ paddingitem, coltitle }) => ({
    color: `${grey[100]}`,
    fontWeight: 700,
    fontSize: '16px',
    padding: paddingitem,
    width: getColWidth(coltitle),
    [theme.breakpoints.down('lg')]: {
      width: getColWidth(coltitle, true)
    },
    paddingLeft: coltitle === DoorTableColumn.DOOR_NAME ? '16px' : paddingitem
  })
);

const TitleWrapper = styled(Box)`
  display: flex;
  align-items: center;
`;

const StyledTable = styled(Table)`
  height: 100%;
  ${props => props.theme.breakpoints.down('xl')} {
    min-height: 300px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: space-between;
  }
`;

const InformationRow = styled(TableRow)<{ status: DoorStatus }>(({ status }) => ({
  backgroundColor: getRowColor(status),
  ['&:nth-of-type(odd)']: {
    backgroundColor: getRowColor(status, true)
  },
  transition: 'background-color 0.5s ease',
  '&.highlight': {
    backgroundColor: '#D1EC86'
  },
  height: '30px'
}));

const InformationCell = styled(TableCell)<{ paddingitem?: string; coltitle: DoorTableColumn }>(
  ({ paddingitem, coltitle }) => ({
    fontWeight: 500,
    fontSize: '14px',
    padding: paddingitem,
    width: getColWidth(coltitle),
    [theme.breakpoints.down('lg')]: {
      width: getColWidth(coltitle, true)
    },
    paddingLeft: coltitle === DoorTableColumn.DOOR_NAME ? '16px' : paddingitem,
    height: '30px'
  })
);

const StatusBox = styled(Box)<{ status: DoorStatus }>(({ status }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '106px',
  height: '27px',
  padding: '0 8px',
  fontSize: 10,
  fontWeight: 600,
  color: statusBoxMainColor(status),
  backgroundColor: statusBoxBackgroundColor(status),
  border: `1px solid ${statusBoxMainColor(status)}`,
  borderRadius: 2
}));

const HyperLinkText = styled(Typography)<TypographyProps>(({ theme }) => ({
  textDecoration: 'underline',
  marginRight: '8px',
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
}));

const StyledTableHead = styled(TableHead)`
  height: ${rowHeight};
`;

const LocationChip = styled(Chip)`
  background-color: ${primary.lighter};
  border: 1px solid ${primary.light};
`;

const Input = styled(TextField)`
  width: 350px;
  display: flex;
  flex-direction: row;
  height: ${inputHeight};
  .MuiOutlinedInput-input {
    cursor: pointer;
    padding: 8px 16px;
  }
`;

const StyledTextField = styled(TextField)`
  height: ${inputHeight};
  .MuiInputBase-root {
    height: 100%;
  }
`;

const StyledAutocomplete = styled(Autocomplete<Location, true, false, false>)`
  margin-left: 8px;
  .MuiAutocomplete-inputRoot {
    height: 40px;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  .MuiAutocomplete-tag {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: black;
  &:hover {
    color: #96c11f;
  }
`;

const SettingsButton = styled(Button)`
  margin-left: auto;
  margin-right: 16px;
`;

const DialogTitleWrapper = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 24px;
`;

const DialogTitleText = styled(Typography)`
  font-size: 1.25rem;
  font-weight: 500;
`;

const CheckBoxLine = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 24px;
  padding: ${dimensions.baseSM} 0;
  border-bottom: 1px solid ${theme.palette.grey[400]};
`;
