import { Typography, Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '../../../../components/Button';
import { CheckboxInput } from '../../../../components/Input';
import { dimensions } from '../../../../styles/dimensions';
import { FilterOptionsWrapper } from '../../components';
import { ReportLogFormField } from '../../types';

const { DEPARTMENT_VIEW, ROOM_VIEW, GROUP_NAME_VIEW } = ReportLogFormField;

const CHECKBOX_LABEL_WIDTH = '70%';

const FieldSelect: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <FilterOptionsWrapper minWidth="154px">
      <Typography variant="subtitle3">{t('report.content')}</Typography>

      <Box width="92%" paddingTop={dimensions.baseXS}>
        <PrimaryButton content={t('report.create')} width="100%" height={dimensions.buttonHeight} type="submit" />
      </Box>
    </FilterOptionsWrapper>
  );
};

export default FieldSelect;
