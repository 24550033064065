import { Box, Checkbox, CheckboxProps, styled, Typography, TypographyVariant } from '@mui/material';
import React, { useRef } from 'react';
import { theme } from '../../styles/theme';
import { BooleanValue } from '../../types/checkbox';

type Props = {
  width?: string;
  label?: string;
  labelWidth?: string;
  minWidth?: string;
  checkboxWidth?: string;
  name?: string;
  defaultValue?: string;
  variant?: TypographyVariant;
} & CheckboxProps;

export const CheckboxInput: React.FunctionComponent<Props> = (props: Props) => {
  const { variant, label, labelWidth, minWidth, checkboxWidth, name, defaultValue, width, ...others } = props;

  const currentCheckBoxValue = useRef<boolean>(defaultValue === BooleanValue.TRUE);

  return (
    <Wrapper width={width} minWidth={minWidth}>
      {label && (
        <StyledTypography variant={variant} width={labelWidth} color={theme.palette.grey[200]}>
          {label}
        </StyledTypography>
      )}
      <CheckBoxWrapper width={checkboxWidth}>
        <Checkbox name={name} {...others} defaultChecked={currentCheckBoxValue.current} />
      </CheckBoxWrapper>
    </Wrapper>
  );
};

// Styled Typography with 7px font size
const StyledTypography = styled(Typography)`
  font-size: 12px; // Verklein de font-size naar 7px
  margin-right: 8px; // Voeg wat ruimte toe tussen de tekst en de checkbox
`;

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 26px;
  width: 100%; // Zorg ervoor dat de wrapper de volledige breedte benut
`;

const CheckBoxWrapper = styled(Box)`
  display: flex;
  align-items: center; // Zorg ervoor dat de checkbox verticaal uitgelijnd is
  width: auto; // Laat de breedte automatisch aanpassen aan de checkbox
`;
