import React, { ReactNode, useState, useRef, useEffect } from 'react';
import { Moment } from 'moment';
import { Box, Fade, styled, Typography, Tabs, Tab, Button, Checkbox, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { dimensions } from '../../../../styles/dimensions';
import { TimeFormatType } from '../../../../utils/date';

import { Content, ContentHeader, ContentWrapper, TimeList, Title } from '../../components';
import { PrimaryButton, SecondaryButton } from '../../../../components/Button';

import { BelongUsers, BelongVisitors, DoorAuthorization } from './Table';
import { DayOfWeek, TimePick } from './types';

import GroupNameInput from './GroupNameInput';
import TemporaryAccessCheckbox from './TemporaryAccessCheckbox';

import { Popup } from '../../../../components/Popup';
import { SideBarItemType } from '../../../../types/route';

import { useUsersHook } from '../../store';
import { useCallbackPrompt } from '../../../../utils/useCallbackPrompt';
import { showSuccessToast, showErrorToast } from '../../../../components/Toast/actions';
import { PopulateGroupParams, StandardTime } from '../../../../models';
import { CircularLoading, StandardTimeItem } from '../components';
import { validateGroupForm } from '../utils';
import { ActionType } from '../types';
import { BooleanValue } from '../../../../types';

const Edit: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { showPrompt, cancelNavigation, confirmNavigation } = useCallbackPrompt();

  const [{ groupInformation }, { getGroupById, editGroup, updateParticipantInGroup, updateDoorsInGroup }] =
    useUsersHook();

  const [loading, setLoading] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [applyToWeekends, setApplyToWeekends] = useState(false);
  const [timeDetails, setTimeDetails] = useState<StandardTime[]>([]);

  const editGroupParam = useRef<PopulateGroupParams>();

  const getGroupInformation = async () => {
    setLoading(true);

    if (id) {
      await getGroupById(id);
    }

    setLoading(false);
  };

  const handleStandardTimeItemChanged = (moment: Moment, day: string, type: TimePick) => {
    if (!editGroupParam.current) return;

    const newTimeDetails = timeDetails.map(item => {
      if (item.day === day) {
        return {
          ...item,
          [type === TimePick.START_TIME ? 'startTime' : 'endTime']: moment.format(TimeFormatType.HOUR_MINUTE)
        };
      }
      return item;
    });

    setTimeDetails(newTimeDetails);
    editGroupParam.current.timeDetails = newTimeDetails;
  };

  const handleApplyDefaultTime = () => {
    if (!editGroupParam.current) return;

    const defaultTime = timeDetails.find(item => item.day === 'Default');
    if (defaultTime) {
      const newStandardTimes = timeDetails.map(item => {
        if (applyToWeekends || (!applyToWeekends && item.day !== 'Saturday' && item.day !== 'Sunday')) {
          if (item.day !== 'Default') {
            return {
              ...item,
              startTime: defaultTime.startTime,
              endTime: defaultTime.endTime
            };
          }
        }
        return item;
      });

      setTimeDetails(newStandardTimes);
      editGroupParam.current.timeDetails = newStandardTimes;
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApplyToWeekends(event.target.checked);
  };

  const handleGroupNameChanged = (value: string) => {
    if (!editGroupParam.current) return;
    editGroupParam.current.groupName = value;
  };

  const handleTemporaryAccessChanged = (value: string) => {
    if (!editGroupParam.current) return;
    editGroupParam.current.temporaryAccess = value;
  };

  const handleBelongUserIdsChanged = (userIds: string[]) => {
    if (!editGroupParam.current) return;
    editGroupParam.current.belongUserIds = userIds;
  };

  const handleBelongVisitorIdsChanged = (visitorIds: string[]) => {
    if (!editGroupParam.current) return;
    editGroupParam.current.belongVisitorIds = visitorIds;
  };

  const handleUsedDoorIdsChanged = (doorIds: string[]) => {
    if (!editGroupParam.current) return;
    editGroupParam.current.usedDoorIds = doorIds;
  };

  const handleEditGroup = async () => {
    setLoading(true);

    const errorMessage = editGroupParam.current && validateGroupForm(editGroupParam.current, ActionType.EDIT);

    if (errorMessage) {
      const { title, subtitle, data } = errorMessage;
      setLoading(false);
      showErrorToast({
        title: t(title),
        subtitle: t(subtitle, data)
      });
      return;
    }

    try {
      const params = editGroupParam.current;

      if (!id || !params) {
        return;
      }

      const dayOfWeek = Object.values(DayOfWeek);
      const timeConvert: { [key: string]: any } = {};
      params.timeDetails.forEach((standardTime, idx) => {
        const { startTime, endTime } = standardTime;
        timeConvert[dayOfWeek[idx]] = `${startTime},${endTime}`;
      });

      const newGroupData = {
        name: params.groupName,
        proId: '1',
        active: '0',
        objectEnable: {
          objects: params.usedDoorIds?.join(',') ?? ''
        },
        time: timeConvert,
        LocationId: '1',
        temporaryAccess: params.temporaryAccess,
        enableLimitDate: params.temporaryAccess === '1' ? '1' : '0'
      };
      await editGroup(id, newGroupData);

      const { belongUserIds, belongVisitorIds, usedDoorIds } = params;

      await Promise.all([
        updateParticipantInGroup([...belongUserIds, ...belongVisitorIds]),
        updateDoorsInGroup(usedDoorIds)
      ]);

      setLoading(false);

      showSuccessToast({
        title: t('group.edit.message.success.title'),
        subtitle: t('group.edit.message.success.subtitle')
      });
    } catch (error) {
      setLoading(false);

      showErrorToast({
        title: t('group.edit.message.fail.title'),
        subtitle: t('group.edit.message.fail.subtitle')
      });

      return;
    }

    navigate(`/users/${SideBarItemType.Group}/${id}`);

    confirmNavigation();
  };

  const handleEditGroupParamChanged = () => {
    if (!groupInformation) return;
    const {
      objectsEnabled: doorAuthorization,
      groupName,
      timeDetails,
      groupTmpAccessEnabled,
      usersInGroup,
      visitorsInGroup
    } = groupInformation;

    const userIds = usersInGroup?.users.map(item => item.userId) ?? [];
    const visitorIds = visitorsInGroup?.users.map(item => item.userId) ?? [];
    const doorIds = doorAuthorization?.map(item => item.doorId) ?? [];

    editGroupParam.current = {
      groupName: groupName,
      usedDoorIds: doorIds,
      belongUserIds: userIds,
      timeDetails: timeDetails,
      belongVisitorIds: visitorIds,
      temporaryAccess: groupTmpAccessEnabled
    };

    setTimeDetails(timeDetails);
  };

  useEffect(() => {
    getGroupInformation();
  }, []);

  useEffect(() => {
    if (groupInformation) {
      handleEditGroupParamChanged();
    }
  }, [groupInformation]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const defaultTime = timeDetails.find(item => item.day === 'Default') || {
    day: 'Default',
    startTime: '08:00',
    endTime: '17:00'
  };

  return (
    <ContentWrapper>
      {loading ? (
        <CircularLoading />
      ) : (
        <Fade in={true}>
          <Content>
            <ContentHeader>
              <Typography variant="subtitle4">{t('group.edit.title')}</Typography>

              <ButtonWrapper>
                <SecondaryButton
                  content={t('group.edit.button.cancel')}
                  height={buttonHeight}
                  onClick={() => navigate(-1)}
                />
                <PrimaryButton content={t('group.edit.button.save')} height={buttonHeight} onClick={handleEditGroup} />
              </ButtonWrapper>
            </ContentHeader>
            <Box paddingTop={baseMD} width="35%" minWidth="400px">
              <GroupNameInput onGroupNameChanged={handleGroupNameChanged} />
            </Box>

            <Box paddingTop={baseMD} paddingBottom={baseMD}>
              <Title variant="subtitle3">{t('group.select_standard_time')}</Title>
            </Box>

            <Box paddingBottom={baseMD}>
              <Box display="flex" alignItems="center">
                <StandardTimeItem key="Default" item={defaultTime} onTimeChanged={handleStandardTimeItemChanged} />
                <Button
                  variant="contained"
                  onClick={handleApplyDefaultTime}
                  sx={{ marginLeft: '8px', alignSelf: 'flex-start' }}>
                  {t('group.apply_default_time')}
                </Button>
              </Box>
              <FormControlLabel
                control={<Checkbox checked={applyToWeekends} onChange={handleCheckboxChange} />}
                label={t('group.apply_to_weekends')}
              />
            </Box>
            <TemporaryAccessCheckbox
              onAccessChanged={value => handleTemporaryAccessChanged(value ? BooleanValue.TRUE : BooleanValue.FALSE)}
            />

            <TimeList>
              {timeDetails
                .filter(item => item.day !== 'Default')
                .map(item => (
                  <StandardTimeItem key={item.day} item={item} onTimeChanged={handleStandardTimeItemChanged} />
                ))}
            </TimeList>

            <Tabs value={tabIndex} onChange={handleTabChange} aria-label="simple tabs example">
              <Tab label={t('group.edit.tab.doors')} />
              <Tab label={t('group.edit.tab.users')} />
              <Tab label={t('group.edit.tab.visitors')} />
            </Tabs>
            {tabIndex === 0 && (
              <Box padding={3}>
                <DoorAuthorization onUsedDoorIdsChanged={handleUsedDoorIdsChanged} />
              </Box>
            )}
            {tabIndex === 1 && (
              <Box padding={3}>
                <BelongUsers onBelongUserIdsChanged={handleBelongUserIdsChanged} />
              </Box>
            )}
            {tabIndex === 2 && (
              <Box padding={3}>
                <BelongVisitors onBelongVisitorIdsChanged={handleBelongVisitorIdsChanged} />
              </Box>
            )}
          </Content>
        </Fade>
      )}

      <Popup
        title={t('group.edit.popup.title')}
        styleChoice="style2"
        open={showPrompt}
        onClose={cancelNavigation}
        closeactiontext={t('group.edit.button.cancel')}
        action={{
          name: t('group.edit.button.quit'),
          onAction: confirmNavigation
        }}
      />
    </ContentWrapper>
  );
};

export default Edit;

const { baseMD, buttonHeight } = dimensions;

const ButtonWrapper = styled(Box)`
  display: flex;
  column-gap: ${baseMD};
`;
