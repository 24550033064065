import { Box, styled } from '@mui/material';
import React from 'react';
import { Route, Routes, useMatch } from 'react-router-dom';
import { theme } from '../../../styles/theme';
import { VisitorDetails } from './VisitorDetails';
import { SideBarItemType } from '../../../types/route';
import { BLANK_STRING } from '../types';
import { VisitorRouteEndpoints } from './type';
import VisitorList from './VisitorList';
import NoticeMessage from '../components/NoticeMessage';
import { useTranslation } from 'react-i18next';
import { useUsersHook } from '../store';
import { AddNewVisitor } from './AddNewVisitor';
import { isEmpty } from 'lodash';
import { EditVisitor } from './EditVisitor';

const Visitor: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [{ visitorList }] = useUsersHook();
  const match = useMatch(`/users/${SideBarItemType.Visitor}/:${VisitorRouteEndpoints.ID}/*`);
  const visitorId = match?.params[VisitorRouteEndpoints.ID];

  return (
    <Wrapper>
      <VisitorList id={visitorId || BLANK_STRING} />

      <Routes>
        <Route path={`/:${VisitorRouteEndpoints.ID}`} element={<VisitorDetails />} />
        <Route path={`/${VisitorRouteEndpoints.ADD_NEW}`} element={<AddNewVisitor />} />
        <Route path={`/:${VisitorRouteEndpoints.ID}/${VisitorRouteEndpoints.EDIT}`} element={<EditVisitor />} />
        <Route
          path={`/`}
          element={
            isEmpty(visitorList) ? (
              <NoticeMessage showBlankPage={true} message={t('visitor.select_visitor_name_for_details')} />
            ) : (
              <VisitorDetails />
            )
          }
        />
      </Routes>
    </Wrapper>
  );
};

export default Visitor;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${theme.palette.grey[500]};
`;
