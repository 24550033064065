import { Box, styled, TableContainer, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CustomTable } from '../../../../components/CustomTable';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { mockLogHistory } from '../mockData'; // Import de mock data

// Boolean om te schakelen tussen DEMO data en API data
const useDemoData = true; // Zet dit op false om echte API data te gebruiken

// Mock API Call (indien gebruik van echte API data)
const fetchLogsFromAPI = async (userId: number) => {
  // Vervang dit met je daadwerkelijke API endpoint en fetch logica
  const response = await fetch(`/api/logs/${userId}`);
  if (!response.ok) {
    throw new Error('Failed to fetch logs');
  }
  const data = await response.json();
  return data;
};

// Mock useUsersHook
const useUsersHook = () => {
  const [state, setState] = React.useState({
    userLogs: useDemoData ? mockLogHistory : [], // Gebruik DEMO data als useDemoData true is
    userInfo: { id: 1, name: 'John Doe' }
  });

  const getLogsOfUser = async (userId: number) => {
    if (useDemoData) {
      // Als we DEMO data gebruiken, hoeven we niets te doen
      return;
    }

    try {
      const logs = await fetchLogsFromAPI(userId);
      setState(prevState => ({
        ...prevState,
        userLogs: logs
      }));
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  return [state, { getLogsOfUser }] as const;
};

// Functie om de logdata te sorteren op tijd (nieuw naar oud)
const sortLogsByDateTime = (logs: any[]) => {
  return logs.sort((a, b) => {
    // Verander de tijd in een Date object voor correcte vergelijking
    const dateA = new Date(`1970-01-01T${a.dateTimeLog}Z`);
    const dateB = new Date(`1970-01-01T${b.dateTimeLog}Z`);
    return dateB.getTime() - dateA.getTime(); // Sorteren op aflopende tijd
  });
};

export const LogHistory: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [{ userLogs, userInfo }, { getLogsOfUser }] = useUsersHook();

  enum TableColumnKeys {
    DateTimeLog = 'dateTimeLog', // Update de keys om overeen te komen met de mock data
    DoorName = 'doorName',
    Location = 'doorLocation',
    Message = 'message'
  }

  const headerColumns = [
    {
      dataKey: TableColumnKeys.DateTimeLog,
      fieldName: t('user.log.time')
    },
    {
      dataKey: TableColumnKeys.DoorName,
      fieldName: t('user.log.door_name')
    },
    {
      dataKey: TableColumnKeys.Location,
      fieldName: t('user.log.door_location')
    },
    {
      dataKey: TableColumnKeys.Message,
      fieldName: t('user.log.message')
    }
  ];

  // Sorteer de logs voordat ze worden doorgegeven aan de tabel
  const sortedLogs = sortLogsByDateTime(userLogs);

  React.useEffect(() => {
    if (userInfo) getLogsOfUser(userInfo?.id);
  }, [userInfo, getLogsOfUser]);

  return (
    <Wrapper>
      <Title variant="body1">{t('user.log.title')}</Title>

      <LogContainer>
        <CustomTable columns={headerColumns} dataList={sortedLogs} cellMaxWidth="130px" />
      </LogContainer>
    </Wrapper>
  );
};

const { grey } = theme.palette;
const { base } = dimensions;

const Wrapper = styled(Box)`
  flex: 1.5;
  min-height: 500px;
  overflow-y: auto;
  padding-bottom: ${base};
`;

const LogContainer = styled(TableContainer)`
  height: calc(100% - 48px);
  overflow-y: auto;
`;

const Title = styled(Typography)`
  color: ${grey[100]};
  margin: ${base} 0;
`;
