import React, { useState, useMemo, useEffect } from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogContent as MuiDialogContent,
  IconButton,
  Button,
  Table as MuiTable,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/system';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import SearchBar from '../../components/SearchBar'; // Zorg ervoor dat dit pad correct is
import { $get } from '../../utils/http'; // Zorg ervoor dat dit pad correct is
import 'video.js/dist/video-js.css'; // Importeer video.js CSS
import videojs from 'video.js'; // Importeer video.js

interface CameraData {
  camera_name: string;
  location_name: string;
  section: string;
  liveFeedUrl: string;
}

interface EntradaCamBlockProps {
  locationIds?: number[];
}

const EntradaCamBlock: React.FC<EntradaCamBlockProps> = ({ locationIds }) => {
  const useTestData = 1; // Stel in op 0 voor demo data (API-responses), 1 voor test data

  const testData: CameraData[] = [
    {
      camera_name: 'Front Door',
      location_name: 'Main Entrance',
      section: 'Entrance',
      liveFeedUrl:
        'https://media.gettyimages.com/id/1367-8/video/young-boy-shopping-with-mother-in-convenience-store-chooses-ice-pop-from-freezer-and-shows-his.mp4?s=mp4-640x640-gi&k=20&c=9Y6NtMuYJjNqvQpTUTXHbvgz5U8neVLFXHxtT8T8DY0='
    },
    {
      camera_name: 'Back Yard',
      location_name: 'Garden',
      section: 'Outdoor',
      liveFeedUrl:
        'https://media.gettyimages.com/id/744-68/video/wide-shot-surveillance-cam-man-stealing-car-in-parking-garage.mp4?s=mp4-640x640-gi&k=20&c=LdtPemiLTM1rsJQJrHdc8Hxp1wa2E6Bz0Thmft_3GBw='
    },
    {
      camera_name: 'Living Room',
      location_name: 'Inside House',
      section: 'Living Area',
      liveFeedUrl:
        'https://media.gettyimages.com/id/85260080/video/guard-looking-at-security-monitors-then-using-phone-and-talking-on-walkie-talkie-compton.mp4?s=mp4-640x640-gi&k=20&c=cSPQ8o0dYfCOzwlFKMh-uIpVCp9YpNHvf7wtm9wRmug='
    },
    {
      camera_name: 'Garage Entrance',
      location_name: 'Garage',
      section: 'Vehicle Access',
      liveFeedUrl:
        'https://media.gettyimages.com/id/1084227724/video/front-porch-security-camera-home-front-porch-burglary.mp4?s=mp4-640x640-gi&k=20&c=J06HKPmIR24yBCMZc0vPSsrt2xCwcbHpoF6ZcmwSnZM='
    },
    {
      camera_name: 'Kitchen Area',
      location_name: 'Inside House',
      section: 'Cooking Area',
      liveFeedUrl:
        'https://media.gettyimages.com/id/1161046610/video/security-camera-footage-of-man-stealing-package-from-front-stoop-lehi-utah-united-states.mp4?s=mp4-640x640-gi&k=20&c=V2_RqOHyYLgyUPQc30niV1VzMskL6NclK-HlzSJiD8c='
    },
    {
      camera_name: 'Kitchen Area1',
      location_name: 'Inside House',
      section: 'Cooking Area',
      liveFeedUrl:
        'https://media.gettyimages.com/id/1161046610/video/security-camera-footage-of-man-stealing-package-from-front-stoop-lehi-utah-united-states.mp4?s=mp4-640x640-gi&k=20&c=V2_RqOHyYLgyUPQc30niV1VzMskL6NclK-HlzSJiD8c='
    },
    {
      camera_name: 'Kitchen Area2',
      location_name: 'Inside House',
      section: 'Cooking Area',
      liveFeedUrl:
        'https://media.gettyimages.com/id/1161046610/video/security-camera-footage-of-man-stealing-package-from-front-stoop-lehi-utah-united-states.mp4?s=mp4-640x640-gi&k=20&c=V2_RqOHyYLgyUPQc30niV1VzMskL6NclK-HlzSJiD8c='
    },
    {
      camera_name: 'Kitchen Area3',
      location_name: 'Inside House',
      section: 'Cooking Area',
      liveFeedUrl:
        'https://media.gettyimages.com/id/1161046610/video/security-camera-footage-of-man-stealing-package-from-front-stoop-lehi-utah-united-states.mp4?s=mp4-640x640-gi&k=20&c=V2_RqOHyYLgyUPQc30niV1VzMskL6NclK-HlzSJiD8c='
    },
    {
      camera_name: 'Kitchen Area4',
      location_name: 'Inside House',
      section: 'Cooking Area',
      liveFeedUrl:
        'https://media.gettyimages.com/id/1161046610/video/security-camera-footage-of-man-stealing-package-from-front-stoop-lehi-utah-united-states.mp4?s=mp4-640x640-gi&k=20&c=V2_RqOHyYLgyUPQc30niV1VzMskL6NclK-HlzSJiD8c='
    },
    {
      camera_name: 'Kitchen Area5',
      location_name: 'Inside House',
      section: 'Cooking Area',
      liveFeedUrl:
        'https://media.gettyimages.com/id/1161046610/video/security-camera-footage-of-man-stealing-package-from-front-stoop-lehi-utah-united-states.mp4?s=mp4-640x640-gi&k=20&c=V2_RqOHyYLgyUPQc30niV1VzMskL6NclK-HlzSJiD8c='
    },
    {
      camera_name: 'Kitchen Area6',
      location_name: 'Inside House',
      section: 'Cooking Area',
      liveFeedUrl:
        'https://media.gettyimages.com/id/1161046610/video/security-camera-footage-of-man-stealing-package-from-front-stoop-lehi-utah-united-states.mp4?s=mp4-640x640-gi&k=20&c=V2_RqOHyYLgyUPQc30niV1VzMskL6NclK-HlzSJiD8c='
    },
    {
      camera_name: 'Kitchen Area7',
      location_name: 'Inside House',
      section: 'Cooking Area',
      liveFeedUrl:
        'https://media.gettyimages.com/id/1161046610/video/security-camera-footage-of-man-stealing-package-from-front-stoop-lehi-utah-united-states.mp4?s=mp4-640x640-gi&k=20&c=V2_RqOHyYLgyUPQc30niV1VzMskL6NclK-HlzSJiD8c='
    }
  ];

  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [showMoreDialog, setShowMoreDialog] = useState<boolean>(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState<string | null>(null);
  const [selectedCamera, setSelectedCamera] = useState<CameraData | null>(null);
  const [dialogSearchTerm, setDialogSearchTerm] = useState<string>(''); // Zoekterm voor dialog
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<keyof CameraData>('camera_name');
  const [cameraData, setCameraData] = useState<CameraData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [favorites, setFavorites] = useState<string[]>([]);
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  // Opslaan en laden van favorieten met expiratie
  const storeFavoritesWithExpiry = (favoritesList: string[]) => {
    const data = { favorites: favoritesList, timestamp: new Date().getTime() };
    localStorage.setItem('favorites', JSON.stringify(data));
  };

  const loadFavoritesWithExpiry = (): string[] => {
    const stored = localStorage.getItem('favorites');
    if (stored) {
      try {
        const parsed = JSON.parse(stored);
        const { favorites, timestamp } = parsed;
        const now = new Date().getTime();
        const fourMonthsInMs = 1000 * 60 * 60 * 24 * 30 * 4; // Ongeveer 4 maanden
        if (now - timestamp < fourMonthsInMs) {
          return favorites;
        } else {
          localStorage.removeItem('favorites');
          return [];
        }
      } catch (e) {
        console.error('Failed to parse favorites:', e);
        localStorage.removeItem('favorites');
        return [];
      }
    }
    return [];
  };

  useEffect(() => {
    const storedFavorites = loadFavoritesWithExpiry();
    setFavorites(storedFavorites);
  }, []);

  useEffect(() => {
    storeFavoritesWithExpiry(favorites);
  }, [favorites]);

  const toggleFavorite = (cameraName: string) => {
    if (favorites.includes(cameraName)) {
      setFavorites(prevFavorites => prevFavorites.filter(name => name !== cameraName));
    } else {
      if (favorites.length >= 9) {
        setAlertMessage('Je kunt maximaal 9 favorieten selecteren.');
        setAlertOpen(true);
        return;
      }
      setFavorites(prevFavorites => [...prevFavorites, cameraName]);
    }
  };

  const handleOpenDialog = (url: string, camera: CameraData) => {
    setSelectedVideoUrl(url);
    setSelectedCamera(camera);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedVideoUrl(null);
    setSelectedCamera(null);
    if (selectedCamera) {
      const player = document.getElementById('video-player') as HTMLVideoElement;
      if (player) {
        player.pause();
        player.src = '';
      }
    }
  };

  const handleOpenShowMore = () => {
    setShowMoreDialog(true);
  };

  const handleCloseShowMore = () => {
    setShowMoreDialog(false);
  };

  const handleRequestSort = (property: keyof CameraData) => {
    const isAsc = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        if (useTestData === 1) {
          setCameraData(testData);
        } else {
          let url = '/dashboard/liveCam';
          if (locationIds && locationIds.length > 0) {
            url += `?locationIds=${locationIds.join(',')}`;
          }
          const response = await $get(url);
          const dataArray = Array.isArray(response) ? response : [response];
          setCameraData(dataArray);
        }
        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Gegevens ophalen mislukt');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [locationIds, useTestData]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  const formattedDate = currentDateTime.toLocaleDateString('nl-NL').replace(/-/g, '/');
  const formattedTime = currentDateTime.toLocaleTimeString('nl-NL', {
    hour: '2-digit',
    minute: '2-digit'
  });

  const filteredData = useMemo(() => {
    return cameraData.filter(
      item =>
        item.camera_name.toLowerCase().includes(dialogSearchTerm.toLowerCase()) ||
        item.location_name.toLowerCase().includes(dialogSearchTerm.toLowerCase()) ||
        item.section.toLowerCase().includes(dialogSearchTerm.toLowerCase())
    );
  }, [dialogSearchTerm, cameraData]);

  const displayedData = useMemo(() => {
    const favoriteCameras = filteredData.filter(camera => favorites.includes(camera.camera_name));
    const nonFavoriteCameras = filteredData.filter(camera => !favorites.includes(camera.camera_name));

    const limitedFavorites = favoriteCameras.slice(0, 9);
    const remainingSlots = 9 - limitedFavorites.length;
    const limitedNonFavorites = nonFavoriteCameras.slice(0, remainingSlots);

    return [...limitedFavorites, ...limitedNonFavorites];
  }, [filteredData, favorites]);

  useEffect(() => {
    if (selectedVideoUrl) {
      const player = document.getElementById('video-player') as HTMLVideoElement;
      if (player) {
        player.src = selectedVideoUrl;
        player.load();
        player.play();
      }
    }
  }, [selectedVideoUrl]);

  const handleCloseAlert = () => {
    setAlertOpen(false);
    setAlertMessage('');
  };

  const handleShowLive = (camera: CameraData) => {
    handleOpenDialog(camera.liveFeedUrl, camera);
  };

  return (
    <BlockContainer>
      <Header>
        <TitleContainer>
          <VideocamOutlinedIcon />
          <Title>LIVE CAM</Title>
        </TitleContainer>
        <HeaderRight>
          <DateText>{`${formattedTime} ${formattedDate}`}</DateText>
        </HeaderRight>
      </Header>
      <ContentContainer>
        {loading ? (
          <NoDataText>Bezig met laden...</NoDataText>
        ) : displayedData.length === 0 ? (
          <NoDataText>Geen gegevens beschikbaar</NoDataText>
        ) : (
          displayedData.map((item, index) => (
            <CameraBlock key={index} onClick={() => handleOpenDialog(item.liveFeedUrl, item)}>
              <VideoContainer>
                <video
                  id={`video-player-${index}`}
                  width="100%"
                  height="100%"
                  autoPlay
                  muted
                  loop
                  src={item.liveFeedUrl}
                  title={item.camera_name}
                />
                <CameraName>{item.camera_name}</CameraName>
              </VideoContainer>
            </CameraBlock>
          ))
        )}
      </ContentContainer>
      {cameraData.length > 9 && (
        <ShowMoreContainer>
          <ShowMoreButton onClick={handleOpenShowMore}>Meer weergeven</ShowMoreButton>
        </ShowMoreContainer>
      )}
      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <MuiDialogContent>
          <DialogHeader>
            <IconButton onClick={handleCloseDialog} edge="end" color="inherit">
              <CloseIcon />
            </IconButton>
          </DialogHeader>
          {selectedVideoUrl && (
            <video
              id="video-player"
              width="100%"
              height="400"
              controls
              autoPlay
              muted
              src={selectedVideoUrl}
              title="Live Feed"
            />
          )}
        </MuiDialogContent>
      </Dialog>
      <Dialog open={showMoreDialog} onClose={handleCloseShowMore} maxWidth="md" fullWidth>
        <StyledDialogContent>
          <DialogHeader>
            <IconButton onClick={handleCloseShowMore} edge="end" color="inherit">
              <CloseIcon />
            </IconButton>
          </DialogHeader>
          <SearchContainer>
            <SearchBar
              placeHolder="Zoeken"
              keyword={dialogSearchTerm}
              onSearch={setDialogSearchTerm}
              onClear={() => setDialogSearchTerm('')}
              onKeywordChanged={setDialogSearchTerm}
            />
          </SearchContainer>
          {favorites.length > 0 && (
            <Box mb={4}>
              <Typography variant="h6" gutterBottom>
                Favorieten
              </Typography>
              <TableContainer>
                <MuiTable>
                  <TableHead>
                    <TableRow>
                      <TableHeaderCell>Camera naam</TableHeaderCell>
                      <TableHeaderCell>Locatie</TableHeaderCell>
                      <TableHeaderCell>Sectie</TableHeaderCell>
                      <TableHeaderCell>Acties</TableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {favorites.slice(0, 9).map(favName => {
                      const camera = cameraData.find(cam => cam.camera_name === favName);
                      if (!camera) return null;
                      return (
                        <TableRow key={favName}>
                          <MuiTableCell>{camera.camera_name}</MuiTableCell>
                          <MuiTableCell>{camera.location_name}</MuiTableCell>
                          <MuiTableCell>{camera.section}</MuiTableCell>
                          <MuiTableCell>
                            <Button
                              sx={{ color: '#96C11F', borderColor: '#96C11F' }}
                              onClick={() => handleShowLive(camera)}>
                              Live Weergeven
                            </Button>
                            <Tooltip title="Uit favorieten verwijderen">
                              <IconButton
                                onClick={() => toggleFavorite(camera.camera_name)}
                                size="small"
                                sx={{ ml: 1 }}>
                                <StarIcon sx={{ color: '#96C11F' }} />
                              </IconButton>
                            </Tooltip>
                          </MuiTableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </MuiTable>
              </TableContainer>
            </Box>
          )}
          <Box>
            <Typography variant="h6" gutterBottom>
              Alle Cameras
            </Typography>
            <TableContainer>
              <MuiTable>
                <TableHead>
                  <TableRow>
                    <TableHeaderCell sortDirection={orderBy === 'camera_name' ? orderDirection : false}>
                      <TableSortLabel
                        active={orderBy === 'camera_name'}
                        direction={orderBy === 'camera_name' ? orderDirection : 'asc'}
                        onClick={() => handleRequestSort('camera_name')}>
                        Camera naam
                      </TableSortLabel>
                    </TableHeaderCell>
                    <TableHeaderCell sortDirection={orderBy === 'location_name' ? orderDirection : false}>
                      <TableSortLabel
                        active={orderBy === 'location_name'}
                        direction={orderBy === 'location_name' ? orderDirection : 'asc'}
                        onClick={() => handleRequestSort('location_name')}>
                        Locatie
                      </TableSortLabel>
                    </TableHeaderCell>
                    <TableHeaderCell sortDirection={orderBy === 'section' ? orderDirection : false}>
                      <TableSortLabel
                        active={orderBy === 'section'}
                        direction={orderBy === 'section' ? orderDirection : 'asc'}
                        onClick={() => handleRequestSort('section')}>
                        Sectie
                      </TableSortLabel>
                    </TableHeaderCell>
                    <TableHeaderCell>Acties</TableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item, index) => (
                    <TableRow key={index}>
                      <MuiTableCell>{item.camera_name}</MuiTableCell>
                      <MuiTableCell>{item.location_name}</MuiTableCell>
                      <MuiTableCell>{item.section}</MuiTableCell>
                      <MuiTableCell>
                        <Button sx={{ color: '#96C11F', borderColor: '#96C11F' }} onClick={() => handleShowLive(item)}>
                          Live Weergeven
                        </Button>
                        <Tooltip
                          title={
                            favorites.includes(item.camera_name)
                              ? 'Uit favorieten verwijderen'
                              : 'Toevoegen aan favorieten'
                          }>
                          <IconButton onClick={() => toggleFavorite(item.camera_name)} size="small" sx={{ ml: 1 }}>
                            {favorites.includes(item.camera_name) ? (
                              <StarIcon sx={{ color: '#96C11F' }} />
                            ) : (
                              <StarBorderIcon />
                            )}
                          </IconButton>
                        </Tooltip>
                      </MuiTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </MuiTable>
            </TableContainer>
          </Box>
        </StyledDialogContent>
      </Dialog>
      <Snackbar open={alertOpen} autoHideDuration={3000} onClose={handleCloseAlert}>
        <Alert onClose={handleCloseAlert} severity="warning" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </BlockContainer>
  );
};

export default EntradaCamBlock;

// Gecorrigeerde Styled Components
const BlockContainer = styled(Box)`
  width: 100%;
  padding: 16px;
  background: #e5e9eb;
  border: 1px solid #d8dfe0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

const Header = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const HeaderRight = styled(Box)`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const Title = styled(Typography)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  margin-left: 8px;
`;

const ContentContainer = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Vaste 3 kolommen om grootte te behouden */
  gap: 16px;
  margin-bottom: 16px;
`;

const CameraBlock = styled(Box)`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  background-color: #000;
  border: 1px solid #d8dfe0;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const VideoContainer = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CameraName = styled(Typography)`
  position: absolute;
  bottom: 8px;
  width: 100%;
  font-family: Lato;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.7);
  text-align: center;
`;

const ShowMoreContainer = styled(Box)`
  text-align: right;
`;

const ShowMoreButton = styled(Button)`
  font-family: Lato;
  font-size: 14px;
  font-style: italic;
  font-weight: 500;
  color: #96c11f;
  text-transform: none;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  &:hover {
    text-decoration: underline;
  }
`;

const DialogHeader = styled(Box)`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 8px;
`;

const SearchContainer = styled(Box)`
  margin-bottom: 16px;
`;

const DateText = styled(Typography)`
  font-family: Lato;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-align: right;
  width: 100%;
`;

const NoDataText = styled(Typography)`
  text-align: center;
  width: 100%;
  font-family: Lato;
  font-size: 14px;
  color: #888;
`;

const TableHeaderCell = styled(MuiTableCell)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
  background-color: #f5f5f5;
`;

const StyledDialogContent = styled(MuiDialogContent)`
  max-height: 600px;
  overflow-y: auto;
`;
