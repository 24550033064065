import React, { useState, useEffect, MouseEvent } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  Divider
} from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import HelpOutline from '@mui/icons-material/HelpOutline';
import { $get } from '../../utils/http'; // Zorg ervoor dat dit pad correct is
import { showErrorToast, showSuccessToast } from '../../components/Toast/actions';

interface ExpectedVisitorsBlockProps {
  locationIds?: number[]; // Optioneel, als geen locatie-IDs worden meegegeven, worden alle locaties opgehaald
}

const ExpectedVisitorsBlock: React.FC<ExpectedVisitorsBlockProps> = ({ locationIds }) => {
  const today = new Date().toLocaleDateString('en-GB').replace(/-/g, '/');
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]); // Vervang any door je eigen type
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [contextMenu, setContextMenu] = useState<{ mouseX: number; mouseY: number; rowIndex: number | null } | null>(
    null
  );
  const [highlightedRow, setHighlightedRow] = useState<number | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Alleen setLoading(true) bij initiële load
        if (loading) {
          setLoading(true);
        }

        // Bouw de URL met optionele locatie-IDs
        let url = '/dashboard/unknownBadges';
        if (locationIds && locationIds.length > 0) {
          url += `?location=${locationIds.join(',')}`;
        }

        const response = await $get(url);

        // Alleen updaten als de data daadwerkelijk veranderd is
        setData(prevData => {
          if (JSON.stringify(prevData) !== JSON.stringify(response)) {
            return response;
          }
          return prevData;
        });
        setError(null);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to get data');
      } finally {
        setLoading(false);
      }
    };

    // Initial fetch
    fetchData();

    // Set up interval for polling every 5 seconds
    const intervalId = setInterval(fetchData, 5000);

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [locationIds, loading]);

  const handleContextMenu = (event: MouseEvent, rowIndex: number) => {
    event.preventDefault();
    setHighlightedRow(rowIndex);
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      rowIndex: rowIndex
    });
  };

  const handleClose = () => {
    setContextMenu(null);
    setHighlightedRow(null);
  };

  const handleAddNewUser = () => {
    if (contextMenu && contextMenu.rowIndex !== null) {
      const { tagnumber } = data[contextMenu.rowIndex];
      navigate('/users/user/add-new', { state: { tagNumber: tagnumber } }); // Doorgeven van tagNumber via state
    }
    handleClose();
  };

  const handleCopyNumber = () => {
    if (contextMenu && contextMenu.rowIndex !== null) {
      const { tagnumber } = data[contextMenu.rowIndex];
      navigator.clipboard.writeText(tagnumber); // Gebruik tagNumber uit de geselecteerde rij
      showSuccessToast({ title: 'Copied!', subtitle: 'Tag Number copied to clipboard' }); // Optionele toast
    }
    handleClose();
  };

  if (loading) {
    return <BlockContainer>Loading...</BlockContainer>;
  }

  if (error) {
    return <BlockContainer>Error: {error}</BlockContainer>;
  }

  return (
    <BlockContainer>
      <Header>
        <TitleContainer>
          <HelpOutlineIcon />
          <Title>UNKNOWN BADGES</Title>
        </TitleContainer>
        <HeaderRight>
          <DateText>{today}</DateText>
        </HeaderRight>
      </Header>
      <TableContainer>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableHeaderCell>Time</TableHeaderCell>
              <TableHeaderCell>Location</TableHeaderCell>
              <TableHeaderCell>Section</TableHeaderCell>
              <TableHeaderCell>Door</TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <StyledTableRow
                  key={index}
                  onContextMenu={event => handleContextMenu(event, index)}
                  highlighted={highlightedRow === index}>
                  <TableCell>{row.time}</TableCell>
                  <TableCell>{row.location_name}</TableCell>
                  <TableCell>{row.section}</TableCell>
                  <TableCell>{row.door}</TableCell>
                </StyledTableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>No data available</TableCell>
              </TableRow>
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined}
        PaperProps={{
          style: {
            width: '154px',
            height: '96px',
            padding: '8px 0',
            borderRadius: '4px 0 0 0',
            opacity: 1
          }
        }}>
        <StyledMenuItem onClick={handleAddNewUser}>Add new user</StyledMenuItem>
        <Divider />
        <StyledMenuItem onClick={handleCopyNumber}>Copy Tag Number</StyledMenuItem>
      </Menu>
    </BlockContainer>
  );
};

export default ExpectedVisitorsBlock;

const BlockContainer = styled(Box)`
  width: 545px; /* Behoud de breedte van de originele ExpectedVisitorsBlock */
  height: 292px; /* Behoud de hoogte van de originele ExpectedVisitorsBlock */
  padding: 0px 16px 16px 24px;
  background: #e5e9eb;
  border: 1px solid #d8dfe0;
  border-radius: 4px 0px 0px 0px;
  display: flex;
  flex-direction: column;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 16px 0;
`;

const HeaderRight = styled(Box)`
  display: flex;
  align-items: center;
`;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
`;

const Title = styled(Typography)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
`;

const HelpOutlineIcon = styled(HelpOutline)`
  color: #414548;
  margin-right: 8px;
`;

const DateText = styled(Typography)`
  font-family: Lato;
  font-size: 13px;
  color: #414548;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
`;

const StyledTable = styled(Table)`
  width: 100%;
`;

const TableHeaderCell = styled(MuiTableCell)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  text-align: left;
  padding: 8px;
  background-color: white;
`;

const StyledTableRow = styled(TableRow)<{ highlighted?: boolean }>`
  background-color: ${({ highlighted }) => (highlighted ? '#D1EC86' : 'inherit')};
  &:nth-of-type(even) {
    background-color: ${({ highlighted }) => (highlighted ? '#D1EC86' : '#f6f9fa')};
  }
`;

const TableCell = styled(MuiTableCell)`
  flex: 1;
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  padding: 8px;
`;

const StyledMenuItem = styled(MenuItem)`
  color: black;
  &:hover {
    color: #96c11f;
  }
`;
