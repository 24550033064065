export const enum StatusEmergencyTeam {
  ON = 'ON',
  OFF = 'OFF'
}

export enum EmergencyKeys {
  ID = 'id',
  NAME = 'name',
  LOCATION = 'location',
  STATUS = 'status',
  LAST = 'last scanned door'
}

export enum TabIndex {
  LOCATION = 0,
  SOFTWARE_UPDATES = 1,
  DATABASE = 2,
  PROCESSOR = 3,
  ALARM = 4,
  MAINTENANCE = 5,
  CLIENT = 6,
  ACCOUNTS = 7,
  EMERGENCY_TEAM = 8,
  RECREATION = 9,
  DIGITAL_RECEPTION = 10,
  TEMPORARY_BADGES = 11
}

export const DEFAULT_ID = -1;
export const ALL = 'All';
