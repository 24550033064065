import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import _ from 'lodash';
import React from 'react';
import { LoadingIndicator } from './components/LoadingIndicator';
import { AdminProfile, Location } from './models';
import { StorageKeys } from './types/storage';
import { $get } from './utils/http';

type AppContextValue = {
  locations: Location[];
  adminProfile?: AdminProfile;
  defaultLocation?: string;
  loadAdminProfile: () => void;
  showLoading: () => void;
  hideLoading: () => void;
  saveUserLocation: (location: string) => void;
};
const AppContext = React.createContext<AppContextValue>({} as AppContextValue);

type AppContextProviderProps = {
  children: React.ReactNode;
};

export const AppContextProvider = (props: AppContextProviderProps) => {
  const { children } = props;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [adminProfile, setAdminProfile] = React.useState<AdminProfile>();
  const [locations, setLocations] = React.useState<Location[]>([]);
  const [defaultLocation, setDefaultLocation] = React.useState<string>('');

  const token = localStorage.getItem(StorageKeys.TOKEN);

  const loadAdminProfile = async () => {
    if (_.isUndefined(adminProfile)) {
      const response = await $get('admin/adminProfile');
      const profile: AdminProfile = response.data;
      setAdminProfile(profile);
    }
  };

  const getLocations = async () => {
    const response = await $get('location/all');
    const { data } = response;
    if (data) {
      const locationList: Location[] = data.locations;
      setLocations(locationList);
    }
  };

  const loadDefaultLocation = () => {
    const location = localStorage.getItem('defaultLocation') || '';
    setDefaultLocation(location);
  };

  const saveUserLocation = (location: string) => {
    localStorage.setItem('defaultLocation', location);
    setDefaultLocation(location);
  };

  React.useEffect(() => {
    if (token) {
      getLocations();
    }
  }, [token]);

  React.useEffect(() => {
    loadDefaultLocation();
  }, []);

  return (
    <AppContext.Provider
      value={{
        locations,
        adminProfile,
        defaultLocation,
        loadAdminProfile,
        showLoading: () => setLoading(true),
        hideLoading: () => setLoading(false),
        saveUserLocation
      }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        {loading && <LoadingIndicator size={48} width="100%" height="100%" />}
        {children}
      </LocalizationProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  return React.useContext(AppContext);
};
