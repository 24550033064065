import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, styled, Paper, InputBase, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { loadTranslations, saveTranslations } from './translationHelpers';
import { Search as SearchIcon, Clear as ClearIcon } from '@mui/icons-material';
import { showSuccessToast } from '../../components/Toast/actions';

interface Translations {
  key: string;
  en: string;
  nl: string;
  de: string;
}

const TranslationManager: React.FC = () => {
  const { t } = useTranslation();
  const [translations, setTranslations] = useState<Translations[]>([]);
  const [newTranslation, setNewTranslation] = useState<Translations>({ key: '', en: '', nl: '', de: '' });
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredTranslations, setFilteredTranslations] = useState<Translations[]>([]);

  useEffect(() => {
    const fetchTranslations = async () => {
      const en = await loadTranslations('en');
      const nl = await loadTranslations('nl');
      const de = await loadTranslations('de');
      const combined = combineTranslations(en, nl, de);
      setTranslations(combined);
      setFilteredTranslations(combined);
    };
    fetchTranslations();
  }, []);

  const combineTranslations = (
    en: Record<string, string>,
    nl: Record<string, string>,
    de: Record<string, string>
  ): Translations[] => {
    const keys = Object.keys(en);
    return keys.map(key => ({
      key,
      en: en[key],
      nl: nl[key] || '',
      de: de[key] || ''
    }));
  };

  const handleChange = (key: string, lang: string, value: string) => {
    setTranslations(prev => prev.map(t => (t.key === key ? { ...t, [lang]: value } : t)));
  };

  const handleSave = () => {
    const en = translations.reduce((acc, t) => ({ ...acc, [t.key]: t.en }), {});
    const nl = translations.reduce((acc, t) => ({ ...acc, [t.key]: t.nl }), {});
    const de = translations.reduce((acc, t) => ({ ...acc, [t.key]: t.de }), {});
    saveTranslations('en', en);
    saveTranslations('nl', nl);
    saveTranslations('de', de);
    showSuccessToast({
      title: t('translation_manager.success_title'),
      subtitle: t('translation_manager.success_message')
    });
  };

  const handleAddTranslation = () => {
    setTranslations([...translations, newTranslation]);
    setFilteredTranslations([...translations, newTranslation]);
    setNewTranslation({ key: '', en: '', nl: '', de: '' });
  };

  const handleSearch = (term: string) => {
    setSearchTerm(term);
    if (term === '') {
      setFilteredTranslations(translations);
    } else {
      const filtered = translations.filter(
        t =>
          t.key.toLowerCase().includes(term.toLowerCase()) ||
          t.en.toLowerCase().includes(term.toLowerCase()) ||
          t.nl.toLowerCase().includes(term.toLowerCase()) ||
          t.de.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredTranslations(filtered);
    }
  };

  return (
    <Box padding={2} position="relative">
      <Typography variant="h4" gutterBottom>
        {t('translation_manager.title')}
      </Typography>

      <SearchContainer>
        <SearchInput
          placeholder={t('translation_manager.search_placeholder')}
          value={searchTerm}
          onChange={e => handleSearch(e.target.value)}
        />
        {searchTerm && (
          <IconButton onClick={() => handleSearch('')}>
            <ClearIcon />
          </IconButton>
        )}
        <IconButton onClick={() => handleSearch(searchTerm)}>
          <SearchIcon />
        </IconButton>
      </SearchContainer>

      <Box marginTop={2}>
        {filteredTranslations.map(({ key, en, nl, de }) => (
          <Box key={key} display="flex" flexDirection="row" mb={2}>
            <Typography variant="body1" width="20%">
              {key}
            </Typography>
            <TextField
              label="EN"
              value={en}
              onChange={e => handleChange(key, 'en', e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <TextField
              label="NL"
              value={nl}
              onChange={e => handleChange(key, 'nl', e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
            />
            <TextField
              label="DE"
              value={de}
              onChange={e => handleChange(key, 'de', e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </Box>
        ))}
      </Box>

      <Box mt={4}>
        <Typography variant="h5" gutterBottom>
          {t('translation_manager.add_new_translation')}
        </Typography>
        <TextField
          label="Key"
          value={newTranslation.key}
          onChange={e => setNewTranslation(prev => ({ ...prev, key: e.target.value }))}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="EN"
          value={newTranslation.en}
          onChange={e => setNewTranslation(prev => ({ ...prev, en: e.target.value }))}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="NL"
          value={newTranslation.nl}
          onChange={e => setNewTranslation(prev => ({ ...prev, nl: e.target.value }))}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <TextField
          label="DE"
          value={newTranslation.de}
          onChange={e => setNewTranslation(prev => ({ ...prev, de: e.target.value }))}
          variant="outlined"
          margin="normal"
          fullWidth
        />
        <Button onClick={handleAddTranslation} variant="contained" color="primary" sx={{ mt: 2 }}>
          {t('translation_manager.add_button')}
        </Button>
      </Box>

      <SaveButton onClick={handleSave} variant="contained" color="primary">
        {t('translation_manager.save_button')}
      </SaveButton>
    </Box>
  );
};

export default TranslationManager;

const SearchContainer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '2px 4px',
  marginBottom: theme.spacing(2)
}));

const SearchInput = styled(InputBase)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  flex: 1
}));

const SaveButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2)
}));
