export const mockLogHistory = [
  {
    dateTimeLog: '12:01:20',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '08:15:45',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '09:45:30',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '10:25:10',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '14:05:50',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '15:30:20',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '16:10:15',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '11:20:00',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '13:50:25',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '07:45:35',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '12:55:10',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '14:20:45',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '16:40:30',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '09:10:20',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '10:55:35',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '11:30:50',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message: 'Have access to reader'
  },
  {
    dateTimeLog: '13:25:25',
    doorName: 'Wing C 31 (DEMO DATA)',
    doorLocation: 'Eindhoven',
    message:
      'Have access to reader Have access to reader Have access to reader Have access to reader Have access to reader'
  }
];
