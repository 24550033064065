import React from 'react';

import { Box, Grow, styled } from '@mui/material';

import FieldSelect from './FieldSelect';
import PlaceSelect from './PlaceSelect';
import TimeSelect from './TimeSelect';
import UserSelect from './UserSelect';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';

interface FilterValues {
  userId?: string;
  locationId?: string;
  startDate?: string;
  endDate?: string;
}

interface FilterOptionsProps {
  onFilterChange: (filters: FilterValues) => void;
}

const FilterOptions: React.FunctionComponent<FilterOptionsProps> = ({ onFilterChange }) => {
  const handleCreateReport = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget as HTMLFormElement);
    console.log('Form data:', {
      userId: formData.get('SELECT_USER_IDS'),
      locationId: formData.get('SELECT_LOCATION'),
      startDate: formData.get('START_DATE'),
      endDate: formData.get('END_DATE')
    });

    const filters: FilterValues = {
      userId: formData.get('SELECT_USER_IDS')?.toString() || undefined,
      locationId: formData.get('SELECT_LOCATION')?.toString() || undefined,
      startDate: formData.get('START_DATE')?.toString() || undefined,
      endDate: formData.get('END_DATE')?.toString() || undefined
    };

    onFilterChange(filters);
  };

  return (
    <Grow in={true}>
      <Wrapper>
        <Box
          component="form"
          width="98%"
          height="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          rowGap={baseMD}
          columnGap={base}
          overflow="auto"
          onSubmit={handleCreateReport}>
          <UserSelect name="SELECT_USER_IDS" />
          <PlaceSelect name="SELECT_LOCATION" />
          <TimeSelect />
          <FieldSelect />
        </Box>
      </Wrapper>
    </Grow>
  );
};

export default FilterOptions;

const { baseMD, base } = dimensions;

const Wrapper = styled(Box)`
  width: 100%;
  height: 170px;
  padding-top: ${base};
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 7px ${theme.palette.grey[300]};
  overflow: auto;
`;
