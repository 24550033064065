import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/system';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import { showErrorToast } from '../../components/Toast/actions';
import { $get } from '../../utils/http';

const SystemUpdatesBlock: React.FC = () => {
  const [latestVersion, setLatestVersion] = useState<string | null>(null);
  const [latestReleaseNote, setLatestReleaseNote] = useState<string | null>('Loading...');

  useEffect(() => {
    const fetchLatestUpdate = async () => {
      try {
        const data = await $get('/dashboard/systemUpdate');
        if (data && data.version && data.releasenote) {
          setLatestVersion(data.version);
          setLatestReleaseNote(data.releasenote);
        } else {
          setLatestVersion('Unknown');
          setLatestReleaseNote('No data available.');
        }
      } catch (error) {
        showErrorToast({
          title: 'Error',
          subtitle: 'Failed to fetch the latest release notes.'
        });
        setLatestVersion('Error');
        setLatestReleaseNote('Failed to load release notes.');
      }
    };

    fetchLatestUpdate();
  }, []);

  return (
    <BlockContainer>
      <TitleContainer>
        <WrenchIconContainer>
          <BuildOutlinedIcon fontSize="inherit" />
        </WrenchIconContainer>
        <Title>SYSTEM UPDATE</Title>
      </TitleContainer>
      <Separator />
      <Placeholder>{latestReleaseNote}</Placeholder>
      <VersionNumber>{latestVersion ? `V.${latestVersion}` : 'v.Unknown'}</VersionNumber>
    </BlockContainer>
  );
};

export default SystemUpdatesBlock;

const BlockContainer = styled(Box)`
  width: 345px;
  height: 115px;
  padding: 16px 20px;
  gap: 8px;
  background: #e5e9eb;
  border: 1px solid #d8dfe0;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative; /* Voor absolute positioning */
`;

const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const WrenchIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  font-size: 16px;
`;

const Title = styled(Typography)`
  font-family: Lato;
  font-size: 14px;
  font-weight: 800;
  line-height: 16.8px;
  letter-spacing: 0.015em;
  text-align: left;
`;

const Separator = styled(Box)`
  width: 100%;
  border-top: 1px solid #d8dfe0;
  margin-bottom: 8px;
`;

const Placeholder = styled(Typography)`
  font-family: Lato;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-top: 8px;
`;

const VersionNumber = styled(Typography)`
  position: absolute;
  bottom: 16px;
  right: 20px;
  font-family: Lato;
  font-size: 12px;
  font-weight: 400;
  font-style: italic;
  color: #7a8a8d;
`;
