import React, { useEffect, useState } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '../../appContext';
import { dimensions } from '../../styles/dimensions';
import LanguageSelector from '../../components/LanguageSelector';
import LocationSelector from '../../components/LocationSelector';
import { Location } from '../../models';

const { baseMD } = dimensions;

const MyProfile: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { adminProfile, loadAdminProfile } = useAppContext();
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);

  useEffect(() => {
    loadAdminProfile();
    const storedLocations = localStorage.getItem('defaultLocations');
    if (storedLocations) {
      setSelectedLocations(JSON.parse(storedLocations));
    }
  }, [loadAdminProfile]);

  const handleLocationChange = (locations: Location[]) => {
    setSelectedLocations(locations);
    localStorage.setItem('defaultLocations', JSON.stringify(locations));
  };

  return (
    <Box sx={{ padding: baseMD }}>
      <ProfileRow>
        <Label variant="body1">{t('myprofile.name')}:</Label>
        <Value variant="body1">{adminProfile?.fullName}</Value>
      </ProfileRow>
      <ProfileRow>
        <Label variant="body1">{t('myprofile.role')}:</Label>
        <Value variant="body1">{adminProfile?.userRole}</Value>
      </ProfileRow>
      <ProfileRow>
        <Label variant="body1">{t('myprofile.language')}:</Label>
        <StyledLanguageSelector />
      </ProfileRow>
      <ProfileRow>
        <Label variant="body1">{t('myprofile.location')}:</Label>
        <StyledLocationSelector selectedLocations={selectedLocations} onLocationChange={handleLocationChange} />
      </ProfileRow>
    </Box>
  );
};

export default MyProfile;

const ProfileRow = styled(Box)`
  display: flex;
  align-items: center;
  margin-bottom: 20px; /* Adjust this value as needed for spacing between rows */
  & > *:not(:first-child) {
    margin-left: 16px; /* Adjust this value to ensure consistent spacing between elements */
  }
`;

const Label = styled(Typography)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  width: 150px; /* Adjust this value as needed for label width */
`;

const Value = styled(Typography)`
  font-family: Lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
`;

const StyledLanguageSelector = styled(LanguageSelector)`
  min-width: 200px; /* Set the minimum width for the LanguageSelector */
`;

const StyledLocationSelector = styled(LocationSelector)`
  min-width: 200px; /* Set the minimum width for the LocationSelector */
`;
