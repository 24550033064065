import { styled, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TextInput } from '../../../../components/Input';
import { dimensions } from '../../../../styles/dimensions';
import { useUsersHook } from '../../store';
import { FormActionType } from '../type';
import { ContractPersonForm } from './ContractPersonForm';
import { EmailInputForm } from './EmailInputForm';
import { LocationForm } from './LocationForm';
import { PhoneInputForm } from './PhoneInputForm';

type Props = {
  onEmailChange: (email: string) => void;
  formActionType: FormActionType;
  setLoading: (value: boolean) => void;
};

export const VisitorInformationForm: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const { onEmailChange, formActionType, setLoading } = props;

  const getPlaceholderText = (value: string): string => value.slice(0, -1);

  const [{ visitorInfo }] = useUsersHook();

  React.useEffect(() => {
    if (formActionType === FormActionType.EDIT && visitorInfo) {
      onEmailChange(visitorInfo.email);
    }
  }, [formActionType, visitorInfo, onEmailChange]);

  const getFormInitValue = (): {
    name: string;
    company: string;
    phone: string;
    email: string;
    plate: string;
    vehicle: string;
  } => {
    switch (formActionType) {
      case FormActionType.EDIT:
        return {
          name: visitorInfo?.name ?? '',
          company: visitorInfo?.company ?? '',
          phone: visitorInfo?.phone ?? '',
          email: visitorInfo?.email ?? '',
          plate: visitorInfo?.plate ?? '',
          vehicle: visitorInfo?.vehicle ?? ''
        };
      case FormActionType.ADD_NEW:
      default:
        return {
          name: '',
          company: '',
          phone: '',
          email: '',
          plate: '',
          vehicle: ''
        };
    }
  };

  return (
    <Wrapper rowGap={baseSM}>
      <Typography variant="subtitle3" color="grey.100" marginBottom={baseMD}>
        {t('visitor.visitor_information')}
      </Typography>

      <ContractPersonForm formActionType={formActionType} />

      <InfoWrapper>
        <TextInput
          width={TEXT_INPUT_WIDTH}
          label={t('visitor.visitor_name')}
          placeholder={getPlaceholderText(t('visitor.visitor_name'))}
          name="name"
          required
          autoComplete="none"
          defaultValue={getFormInitValue().name}
        />
      </InfoWrapper>

      <LocationForm formActionType={formActionType} setLoading={setLoading} required />

      <InfoWrapper>
        <TextInput
          width={TEXT_INPUT_WIDTH}
          label={t('visitor.company')}
          placeholder={getPlaceholderText(t('visitor.company'))}
          name="company"
          defaultValue={getFormInitValue().company}
        />
      </InfoWrapper>

      <InfoWrapper>
        <PhoneInputForm
          width={TEXT_INPUT_WIDTH}
          label={t('visitor.phone')}
          placeholder={getPlaceholderText(t('visitor.phone'))}
          name="phone"
          defaultValue={getFormInitValue().phone}
        />
      </InfoWrapper>

      <InfoWrapper>
        <EmailInputForm
          width={TEXT_INPUT_WIDTH}
          label={t('visitor.email')}
          placeholder={getPlaceholderText(t('visitor.email'))}
          name="email"
          type="email"
          required // Hier wordt de 'required' prop toegevoegd
          defaultValue={getFormInitValue().email}
          onChange={e => onEmailChange(e.target.value)}
        />
      </InfoWrapper>

      <InfoWrapper>
        <TextInput
          width={TEXT_INPUT_WIDTH}
          label={t('visitor.plate_number')}
          placeholder={getPlaceholderText(t('visitor.plate_number'))}
          name="plate"
          defaultValue={getFormInitValue().plate}
        />
      </InfoWrapper>

      <InfoWrapper>
        <TextInput
          width={TEXT_INPUT_WIDTH}
          label={t('visitor.vehicle')}
          placeholder={getPlaceholderText(t('visitor.vehicle'))}
          name="vehicle"
          defaultValue={getFormInitValue().vehicle}
        />
      </InfoWrapper>
    </Wrapper>
  );
};

const { baseSM, baseMD, base2XL } = dimensions;

const TEXT_INPUT_WIDTH = '71%';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const InfoWrapper = styled(Box)`
  min-height: ${base2XL};
  width: 100%;
`;
