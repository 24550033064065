import {
  Box,
  styled,
  Typography,
  Menu,
  MenuItem as MenuItemComponent,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  IconButton,
  Avatar,
  Tooltip
} from '@mui/material';
import { theme } from '../styles/theme';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import { $post } from '../utils/http';
import { useAppContext } from '../appContext';
import { dimensions } from '../styles/dimensions';
import { showErrorToast, showSuccessToast } from '../components/Toast/actions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useLocation, useNavigate } from 'react-router-dom';

const Header = (): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { adminProfile, loadAdminProfile } = useAppContext();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState<null | HTMLElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const getPageTitle = () => {
    const path = location.pathname.split('/')[1];
    switch (path) {
      case 'door':
        return t('door.page_title');
      case 'users':
        // eslint-disable-next-line no-case-declarations
        const subPath = location.pathname.split('/')[2];
        if (subPath === 'user') {
          return t('user.page_title');
        } else if (subPath === 'visitor') {
          return t('visitor.page_title');
        } else if (subPath === 'group') {
          return t('group.page_title');
        }
        break;
      case 'emergencyTeam':
        return t('emergency_team.title_without_persons');
      case 'report':
        return t('report.content');
      case 'myProfile':
        return t('myprofile.content');
      case 'AdminSettings':
        return t('admin_settings.content');
      default:
        if (location.pathname === '/') {
          return t('dashboard.page_title');
        }
        return '';
    }
  };

  // Initialize language state
  const [language, setLanguage] = useState<string>(() => {
    const storedLanguage = localStorage.getItem('i18nextLng');
    return storedLanguage || 'en';
  });

  useEffect(() => {
    const storedLanguage = localStorage.getItem('i18nextLng');
    if (!storedLanguage) {
      localStorage.setItem('i18nextLng', 'en');
      i18n.changeLanguage('en');
      setLanguage('en');
    } else {
      i18n.changeLanguage(storedLanguage);
      setLanguage(storedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    loadAdminProfile();
  }, []);

  const handleLanguageChange = (newLanguage: string) => {
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    localStorage.setItem('i18nextLng', newLanguage);
    setLanguageMenuAnchorEl(null); // Close language menu
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setLanguageMenuAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageMenuAnchorEl(null);
  };

  // Function to handle the opening of the Freshdesk widget
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = async () => {
    let isValid = true;
    if (!name) {
      setNameError(t('support.name_error'));
      isValid = false;
    } else {
      setNameError('');
    }

    if (!email || !validateEmail(email)) {
      setEmailError(t('support.email_error'));
      isValid = false;
    } else {
      setEmailError('');
    }

    if (!message) {
      setMessageError(t('support.message_error'));
      isValid = false;
    } else {
      setMessageError('');
    }

    if (!isValid) {
      showErrorToast({ title: t('support.error_title'), subtitle: t('support.error_message') });
      return;
    }

    try {
      const response = await $post('/support/addnew', { name, email, message });
      if (response) {
        setOpen(false);
        setName('');
        setEmail('');
        setMessage('');
        showSuccessToast({ title: t('support.success_title'), subtitle: t('support.success_message') });
      }
    } catch (error) {
      console.error('Error sending support message:', error);
      showErrorToast({ title: t('support.error_title'), subtitle: t('support.error_message') });
    }
  };

  const openAboutEntrada = () => {
    window.open('https://entradasecurity.com/', '_blank');
  };

  const getInitials = (name: string) => {
    return name ? name.charAt(0).toUpperCase() : '';
  };

  return (
    <Wrapper>
      <Typography variant="subtitle1" color="grey.100" sx={{ position: 'absolute', left: '16px' }}>
        {getPageTitle()}
      </Typography>
      <UserInfo>
        <Typography marginLeft="6px" variant="body1" fontWeight={500} fontSize={16}>
          {adminProfile && adminProfile.client}
        </Typography>
        <Tooltip
          title={
            <React.Fragment>
              {adminProfile?.fullName}
              <br />
              {adminProfile?.userRole}
            </React.Fragment>
          }
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'white',
                color: 'black',
                fontSize: '1rem',
                border: '1px solid #ddd',
                borderRadius: '4px',
                padding: '8px',
                textAlign: 'center'
              }
            }
          }}>
          <IconButton onClick={() => navigate('/myProfile')}>
            <Avatar style={{ backgroundColor: '#96C11F', cursor: 'pointer' }}>
              {getInitials(adminProfile?.fullName || '')}
            </Avatar>
          </IconButton>
        </Tooltip>
        <InfoOutlinedIcon onClick={openAboutEntrada} style={{ cursor: 'pointer', color: 'black' }} />
        <ContactSupportOutlinedIcon onClick={handleClickOpen} style={{ cursor: 'pointer', color: 'black' }} />
      </UserInfo>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <NonClickableMenuItem>{adminProfile?.client}</NonClickableMenuItem>
        <Divider />
        <MenuItemComponent onClick={handleLanguageMenuOpen}>{t('change_language')}</MenuItemComponent>
        <Menu
          anchorEl={languageMenuAnchorEl}
          open={Boolean(languageMenuAnchorEl)}
          onClose={handleLanguageMenuClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <MenuItemComponent onClick={() => handleLanguageChange('en')}>English</MenuItemComponent>
          <MenuItemComponent onClick={() => handleLanguageChange('nl')}>Nederlands</MenuItemComponent>
          <MenuItemComponent onClick={() => handleLanguageChange('de')}>Deutsch</MenuItemComponent>
        </Menu>
        <MenuItemComponent onClick={handleClickOpen}>{t('get_support')}</MenuItemComponent>
        <Divider />
        <MenuItemComponent onClick={openAboutEntrada}>{t('about_entrada')}</MenuItemComponent>
      </Menu>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t('support.title')}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={t('support.name')}
            type="text"
            fullWidth
            variant="standard"
            value={name}
            onChange={e => setName(e.target.value)}
            error={!!nameError}
            helperText={nameError}
          />
          <TextField
            margin="dense"
            label={t('support.email')}
            type="email"
            fullWidth
            variant="standard"
            value={email}
            onChange={e => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
          />
          <TextField
            margin="dense"
            label={t('support.message')}
            type="text"
            fullWidth
            variant="standard"
            value={message}
            onChange={e => setMessage(e.target.value)}
            error={!!messageError}
            helperText={messageError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('support.cancel')}</Button>
          <Button onClick={handleSubmit} color="primary">
            {t('support.send')}
          </Button>
        </DialogActions>
      </Dialog>
    </Wrapper>
  );
};

export default Header;

const { rowHeight, baseLG } = dimensions;

const Wrapper = styled(Box)`
  height: ${rowHeight};
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(180deg, ${theme.palette.grey[500]} 10%, ${theme.palette.grey[400]} 80%);
  position: relative;
`;

const UserInfo = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: ${baseLG};
`;

const UserDetails = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  text-align: left;
  line-height: 0.8;

  & > *:not(:last-child) {
    margin-bottom: 0px;
  }
`;

const NonClickableMenuItem = styled(MenuItemComponent)`
  pointer-events: none;
  opacity: 1;
`;

const LanguageSelector = styled(Box)`
  margin-left: ${baseLG};
  display: flex;
  align-items: center;
`;
