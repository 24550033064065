import {
  Box,
  Chip,
  CircularProgress,
  Grow,
  Input,
  InputLabel,
  Stack,
  styled,
  Typography,
  Tooltip
} from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AddNewIcon } from '../../../../assets/image/Users/AddNewIcon.svg';
import SearchBar from '../../../../components/SearchBar';
import { Group } from '../../../../models/group';
import { dimensions } from '../../../../styles/dimensions';
import { theme } from '../../../../styles/theme';
import { UnderLine } from '../../../../types/searchBar';
import { useUsersHook } from '../../store';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { DateInput } from '../../../../components/Input/DateInput';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { showErrorToast } from '../../../../components/Toast/actions';

type Props = {
  chosenGroupIds: string[];
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
type GroupInfo = {
  groupID: string;
  groupName: string;
  temporaryAccess?: string | null;
  temporaryAccessStart?: string | null;
};

export const GroupBar: React.FunctionComponent<Props> = ({ chosenGroupIds }: Props) => {
  const { t } = useTranslation();

  const [{ groupList, userInfo }, { getAllGroups }] = useUsersHook();
  const [loading, setLoading] = useState<boolean>(true);

  const initialGroupList = useRef<Group[]>(groupList);

  const [chosenGroup, setChosenGroup] = useState<Group[]>([]);
  const [listGroup, setListGroup] = useState<Group[]>(groupList);

  const [temporaryAccessDates, setTemporaryAccessDates] = useState<{ [key: string]: string }>({});

  const [assignedSearchValue, setAssignedSearchValue] = useState<string>('');
  const [filteredChosenGroup, setFilteredChosenGroup] = useState<Group[]>([]);

  const handleAddGroupIntoChosenList = (id: string) => {
    const addedGroup = initialGroupList.current.find(group => group.groupID === id);

    const removeSelectItemGroups = listGroup.filter(item => {
      return item.groupID !== id;
    });

    initialGroupList.current = initialGroupList.current.filter(item => {
      return item.groupID !== id;
    });

    setListGroup(removeSelectItemGroups);

    addedGroup && setChosenGroup(chosenGroup.concat(addedGroup));
  };

  const handleRemoveGroupOutOfChosenList = (id: string) => {
    const removedGroup = chosenGroup.find(group => group.groupID === id);

    const removeSelectItemChosenGroup = chosenGroup.filter(item => {
      return item.groupID !== id;
    });

    setChosenGroup(removeSelectItemChosenGroup);

    removedGroup &&
      (setListGroup(listGroup.concat(removedGroup)),
      (initialGroupList.current = initialGroupList.current.concat(removedGroup)));
  };

  const handleSearch = (value: string) => {
    const result = initialGroupList.current.filter(item =>
      item.groupName.toLocaleLowerCase().includes(value.toLocaleLowerCase())
    );
    setListGroup(result);
  };

  const handleClearSearchBox = () => {
    setListGroup(initialGroupList.current);
  };

  const getGroups = async () => {
    setLoading(true);
    await getAllGroups();
    setLoading(false);
  };

  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    const initialChosenGroup: Group[] = groupList.filter(item => {
      const groupInfo = userInfo?.groupNames?.find(g => g.groupID === item.groupID);
      if (groupInfo?.temporaryAccess) {
        setTemporaryAccessDates(prev => ({
          ...prev,
          [item.groupID]: groupInfo.temporaryAccess
        }));
      }
      return chosenGroupIds.includes(item.groupID);
    });

    setChosenGroup(initialChosenGroup);

    const currentGroups = groupList.filter(item => !initialChosenGroup.includes(item));

    initialGroupList.current = currentGroups;

    setListGroup(currentGroups);
  }, [groupList, userInfo]);

  const handleAssignedSearch = (value: string) => {
    setAssignedSearchValue(value);
    const result = chosenGroup.filter(item => item.groupName.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
    setFilteredChosenGroup(result);
  };

  const handleAssignedClearSearch = () => {
    setAssignedSearchValue('');
    setFilteredChosenGroup(chosenGroup);
  };

  useEffect(() => {
    setFilteredChosenGroup(chosenGroup);
  }, [chosenGroup]);

  useEffect(() => {
    const input = document.querySelector('input[name="groupIds"]') as HTMLInputElement;
    if (input) {
      input.value = chosenGroup.map(item => item.groupID).join(',');
    }
  }, [chosenGroup]);

  const handleDateChange = (groupId: string, isStart: boolean, date: string) => {
    const startDate = isStart ? date : temporaryAccessDates[`start_${groupId}`];
    const endDate = !isStart ? date : temporaryAccessDates[groupId];

    if (startDate && endDate) {
      if (new Date(endDate) < new Date(startDate)) {
        showErrorToast(t('group.end_date_before_start_date_error'));
        return;
      }
    }

    setTemporaryAccessDates(prev => ({
      ...prev,
      [isStart ? `start_${groupId}` : groupId]: date
    }));
  };

  return (
    <Wrapper>
      <ListGroupInput
        name="groupIds"
        value={chosenGroup.map(item => item.groupID).join(',')}
        onKeyDown={e => e.preventDefault()}
      />

      <GroupWrapper>
        <Column>
          <Typography variant="subtitle3" color="grey.100">
            {t('user.groups.assigned')}
          </Typography>
          <SearchBar
            placeHolder={t('user.create.search_group')}
            justuderline={UnderLine.YES}
            onSearch={handleAssignedSearch}
            onClear={handleAssignedClearSearch}
          />
          <ItemWrapper>
            {filteredChosenGroup.map((group, index) => (
              <GroupItem key={index}>
                <Typography variant="body1" color="grey.100">
                  {group.groupName}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {group.groupEnableLimitDate === 1 && (
                    <Tooltip title={t('group.temporary_access_enabled')}>
                      <AccessTimeIcon
                        sx={{
                          fontSize: 16,
                          color: 'primary.main'
                        }}
                      />
                    </Tooltip>
                  )}
                  <ArrowCircleRightIcon
                    sx={{
                      cursor: 'pointer',
                      color: '#727980'
                    }}
                    onClick={() => handleRemoveGroupOutOfChosenList(group.groupID)}
                  />
                </Box>
              </GroupItem>
            ))}
          </ItemWrapper>
        </Column>

        <Column>
          <Typography variant="subtitle3" color="grey.100">
            {t('user.groups.available')}
          </Typography>
          <SearchBar
            placeHolder={t('user.create.search_group')}
            justuderline={UnderLine.YES}
            onSearch={handleSearch}
            onClear={handleClearSearchBox}
          />
          <ItemWrapper>
            {loading ? (
              <LoadingWrapper>
                <CircularProgress color="primary" size={30} />
              </LoadingWrapper>
            ) : (
              listGroup.map((item, index) => (
                <GroupItem key={index}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <ArrowCircleLeftIcon
                      sx={{
                        cursor: 'pointer',
                        color: '#727980'
                      }}
                      onClick={() => handleAddGroupIntoChosenList(item.groupID)}
                    />
                    <Typography variant="body1" color="grey.100">
                      {item.groupName}
                    </Typography>
                  </Box>
                  {item.groupEnableLimitDate === 1 && (
                    <Tooltip title={t('group.temporary_access_enabled')}>
                      <AccessTimeIcon
                        sx={{
                          fontSize: 16,
                          color: 'primary.main'
                        }}
                      />
                    </Tooltip>
                  )}
                </GroupItem>
              ))
            )}
          </ItemWrapper>
        </Column>
      </GroupWrapper>

      {chosenGroup.map(
        (group, index) =>
          group.groupEnableLimitDate === 1 && (
            <Box key={index} sx={{ width: '100%', mt: 2 }}>
              <Typography
                variant="body1"
                color="grey.100"
                sx={{
                  fontWeight: 600,
                  mb: 1,
                  textAlign: 'center'
                }}>
                {t('group.temporary_access_in', { groupName: group.groupName })}
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Box sx={{ minWidth: '220px', flex: 1 }}>
                  <DateInput
                    name={`temporaryAccessStart_${group.groupID}`}
                    label={t('group.start_date')}
                    defaultValue={temporaryAccessDates[`start_${group.groupID}`] || ''}
                    onChange={date =>
                      setTemporaryAccessDates(prev => ({
                        ...prev,
                        [`start_${group.groupID}`]: date
                      }))
                    }
                    inputWidth="100%"
                    labelWidth="40%"
                  />
                </Box>
                <Box sx={{ minWidth: '220px', flex: 1 }}>
                  <DateInput
                    name={`temporaryAccess_${group.groupID}`}
                    label={t('group.end_date')}
                    defaultValue={temporaryAccessDates[group.groupID] || ''}
                    onChange={date =>
                      setTemporaryAccessDates(prev => ({
                        ...prev,
                        [group.groupID]: date
                      }))
                    }
                    inputWidth="100%"
                    labelWidth="40%"
                  />
                </Box>
              </Box>
            </Box>
          )
      )}
    </Wrapper>
  );
};

const { baseSM, baseLG, baseMD } = dimensions;
const { primary, grey, error } = theme.palette;

const ListGroupInput = styled(Input)`
  width: 1px;
`;

const Wrapper = styled(Box)`
  flex: 1.5;
  min-height: 500px;
  border-left: 1px solid ${grey[400]};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-left: ${baseMD};
  ${props => props.theme.breakpoints.down(1400)} {
    border: none;
  }
`;

const GroupWrapper = styled(Box)`
  display: flex;
  width: 100%;
  margin-top: ${baseSM};
  border: 1px solid ${grey[400]};
  border-radius: 4px;
`;

const Column = styled(Box)`
  flex: 1;
  padding: ${baseMD};

  &:first-of-type {
    border-right: 1px solid ${grey[400]};
  }
`;

const GroupItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${grey[400]};
  height: 40px;
`;

const StyledStack = styled(Stack)`
  display: flex;
  flex-flow: row wrap;
  row-gap: ${baseSM};
  width: 492px;
  margin: ${baseLG} 8% 0;
`;

const GroupChip = styled(Chip)`
  background-color: ${primary.lighter};
  border: 1px solid ${primary.light};
`;

const ItemWrapper = styled(Box)`
  height: 400px;
  overflow-y: auto;
`;

const LoadingWrapper = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.palette.grey[500]};
  opacity: 70%;
  height: 200px;
`;

const Label = styled(InputLabel)`
  color: ${grey[200]};
  width: 39%;
  .MuiInputLabel-asterisk {
    color: ${error.main} !important;
  }
`;
