import React, { useEffect, useState } from 'react';
import {
  Box,
  styled,
  TableContainer,
  Typography,
  Switch,
  Button,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Column, CustomTable } from '../../components/CustomTable';
import { Location } from '../../models';
import { theme } from '../../styles/theme';
import { useEmergencyHook } from './store';
import { DEFAULT_ID } from './type';
import { CircularLoading } from '../Users/Group/components';
import LocationFilter from './components/LocationFilter';
import ClearIcon from '@mui/icons-material/Clear';
import { $get } from '../../utils/http';
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import { StatusEmergencyTeam } from './type';
import { dimensions } from '../../styles/dimensions';
import { useAppContext } from '../../appContext';

const { grey } = theme.palette;
const { rowHeight, base3XL } = dimensions;

// API response interface
interface EmergencyTeamResponse {
  data: {
    users: EmergencyTeamUser[];
  };
  code: string;
  message: null | string;
  fromId: number;
  take: number;
  total: string;
}

// User interface
interface EmergencyTeamUser {
  userId: string;
  userFullName: string;
  location: string;
  lastScan: string;
  lastDoor: string;
  lastDoorName: string;
  presence: string;
}

const ROWS_PER_PAGE = 15;

const PresentTeam: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [{ locationList, emergencyList }, { getLocations, getEmergencyTeams }] = useEmergencyHook();
  const { locations } = useAppContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [selectedLocations, setSelectedLocations] = useState<Location[]>([]);
  const [emergencyMembers, setEmergencyMembers] = useState<EmergencyTeamUser[]>([]);
  const [showAllMembers, setShowAllMembers] = useState<boolean>(true);
  const [showAbsent, setShowAbsent] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState<string>('all');

  const fetchEmergencyTeam = async () => {
    try {
      const response: EmergencyTeamResponse = await $get('users/emergencyTeam');
      setEmergencyMembers(response.data.users);
    } catch (error) {
      console.error('Error fetching emergency team:', error);
    }
  };

  const initData = async () => {
    setLoading(true);
    await Promise.all([getLocations(), fetchEmergencyTeam()]);
    setLoading(false);
  };

  const columns: Column[] = [
    {
      dataKey: 'name',
      fieldName: t('emergency_team.name')
    },
    {
      dataKey: 'location',
      fieldName: t('emergency_team.registered_at_location')
    },
    {
      dataKey: 'last scanned door',
      fieldName: t('emergency_team.last_scanned_door')
    }
  ];

  const sortListByStatus = (members: EmergencyTeamUser[]) => {
    return [...members].sort((a, b) => {
      if (a.presence === 'present' && b.presence !== 'present') return -1;
      if (a.presence !== 'present' && b.presence === 'present') return 1;
      return 0;
    });
  };

  const getFilteredMembers = (members: EmergencyTeamUser[]) => {
    return members.filter(member => {
      const matchesPresence = showAbsent ? true : member.presence === 'present';
      const matchesLocation = selectedLocation === 'all' ? true : member.location === selectedLocation;
      return matchesPresence && matchesLocation;
    });
  };

  useEffect(() => {
    console.log('Calling initData');
    initData();
  }, []);

  useEffect(() => {
    console.log('Emergency Members updated:', emergencyMembers);
    console.log('Sorted data:', sortListByStatus(emergencyMembers));
  }, [emergencyMembers, showAllMembers]);

  const isFilterApplied = selectedLocations.length > 0 || !showAllMembers;

  const fillEmptyRows = (dataList: EmergencyTeamUser[]) => {
    const currentRows = dataList.length;
    const emptyRowsNeeded = ROWS_PER_PAGE - currentRows;

    if (emptyRowsNeeded <= 0) return dataList;

    const emptyRows = Array(emptyRowsNeeded).fill({
      id: 'empty-' + Math.random(),
      name: '',
      location: '',
      'last scanned door': '',
      status: '',
      locationId: '',
      isEmpty: true
    });

    return [...dataList, ...emptyRows];
  };

  const getColumnWidth = (column: string) => {
    switch (column) {
      case 'name':
        return '300px'; // Brede kolom voor namen
      case 'location':
        return '250px'; // Middelbrede kolom voor locaties
      case 'lastDoor':
        return '200px'; // Kolom voor laatste deur
      default:
        return '150px';
    }
  };

  const StyledTable = styled(Table)`
    height: 100%;
    table-layout: fixed; // Dit zorgt ervoor dat de kolommen vast staan
    ${props => props.theme.breakpoints.down('xl')} {
      min-height: 300px;
    }
    ${props => props.theme.breakpoints.down('md')} {
      justify-content: space-between;
    }
  `;

  const Container = styled(TableContainer)`
    border: 1px solid ${grey[400]};
    height: calc(100% - 2px);
    width: calc(100% - 2px);
    overflow: auto;
  `;

  const InformationRow = styled(TableRow)`
    background-color: ${({ theme }) => theme.palette.common.white};
    &:nth-of-type(odd) {
      background-color: ${grey[500]};
    }
    transition: background-color 0.5s ease;
    height: 30px;
  `;

  const InformationCell = styled(TableCell)<{ columnId?: string }>`
    font-weight: 500;
    font-size: 14px;
    padding: 8px;
    height: 30px;
    width: ${({ columnId }) => getColumnWidth(columnId || '')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  const StyledTableHead = styled(TableHead)`
    height: ${rowHeight};
  `;

  const Component = styled(Box)({
    '& .MuiTableRow-root': {
      height: '10px !important'
    },
    '& .MuiTableCell-root': {
      padding: '0 !important',
      height: '10px !important'
    },
    '& .empty-row': {
      backgroundColor: '#f5f5f5',
      '& td': {
        border: 'none'
      }
    }
  });

  const PageContainer = styled(Box)`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
  `;

  const Wrapper = styled(Box)`
    display: flex;
    flex-direction: column;
    background-color: ${grey[500]};
    height: 100%;
    overflow: auto;
    flex: 1;
  `;

  const Header = styled(Box)`
    background-color: white;
    height: ${base3XL};
    display: flex;
    align-items: center;
  `;

  const Body = styled(Box)`
    background-color: white;
    height: 100%;
    overflow: auto;
  `;

  const TitleCell = styled(TableCell)<{ columnId?: string }>`
    color: ${grey[100]};
    font-weight: 700;
    font-size: 16px;
    padding: 8px;
    height: 30px;
    width: ${({ columnId }) => getColumnWidth(columnId || '')};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  const FilterContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-left: 16px;
    height: 40px;
  `;

  const LocationFilter = styled(FormControl)`
    min-width: 200px;
    height: 40px;

    .MuiOutlinedInput-root {
      height: 40px;
    }

    .MuiInputLabel-root {
      line-height: 0.8;
    }
  `;

  return (
    <PageContainer>
      <Wrapper>
        <Header>
          <FilterContainer>
            <LocationFilter size="small">
              <InputLabel>Locatie</InputLabel>
              <Select value={selectedLocation} onChange={e => setSelectedLocation(e.target.value)} label="Locatie">
                <MenuItem value="all">Alle locaties</MenuItem>
                {locations.map((location: Location) => (
                  <MenuItem key={location.id} value={location.location}>
                    {location.location}
                  </MenuItem>
                ))}
              </Select>
            </LocationFilter>
            <FormControlLabel
              control={
                <Switch
                  checked={showAbsent}
                  onChange={e => setShowAbsent(e.target.checked)}
                  color="primary"
                  size="small"
                />
              }
              label="Toon afwezigen"
            />
          </FilterContainer>
        </Header>
        <Body>
          <Container>
            <StyledTable>
              <StyledTableHead>
                <TableRow>
                  <TitleCell columnId="name">Naam</TitleCell>
                  <TitleCell columnId="location">Locatie</TitleCell>
                  <TitleCell columnId="lastDoor">Laatste Deur</TitleCell>
                </TableRow>
              </StyledTableHead>
              <TableBody>
                {sortListByStatus(getFilteredMembers(emergencyMembers)).map(member => (
                  <InformationRow key={member.userId}>
                    <InformationCell columnId="name">{member.userFullName}</InformationCell>
                    <InformationCell columnId="location">{member.location}</InformationCell>
                    <InformationCell columnId="lastDoor">{member.lastDoorName || 'Geen'}</InformationCell>
                  </InformationRow>
                ))}
                {Array.from({ length: Math.max(10 - getFilteredMembers(emergencyMembers).length, 0) }).map(
                  (_, index) => (
                    <InformationRow key={`empty-${index}`}>
                      <InformationCell columnId="name">&nbsp;</InformationCell>
                      <InformationCell columnId="location">&nbsp;</InformationCell>
                      <InformationCell columnId="lastDoor">&nbsp;</InformationCell>
                    </InformationRow>
                  )
                )}
              </TableBody>
            </StyledTable>
          </Container>
        </Body>
      </Wrapper>
    </PageContainer>
  );
};

export default PresentTeam;
