import React, { useEffect, useState, useRef, useMemo } from 'react';
import { isEmpty } from 'lodash';
import { Box, styled, Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SecondaryButton } from '../../../components/Button';

import { ReactComponent as Setting } from '../../../assets/image/system.svg';

import { theme } from '../../../styles/theme';

import ColumnSettingsDialog from './ColumSettingsDialog';
import { LatestLogActionType, FilterLevel, OrderBy, POLLING_TIME, TAKEN_NUMBER } from './types';
import { useLogHook } from './store';
import LogHistory from './LogHistory';
import { dimensions } from '../../../styles/dimensions';
import { SelectBox, SelectBoxType } from '../../../components/SelectBox';
import { StorageKeys } from '../../../types/storage';
import { Location } from '../../../models';
import SelectLocations from './SelectLocations';

const Dashboard: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const [{ logs, columns }, { getLatestLogs, getAllLogs, setInitialLog }] = useLogHook();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [_currentFilter, setCurrentFilter] = useState<FilterLevel>(FilterLevel.ALL);
  const logHistoryTableRef = useRef<HTMLDivElement>(null);
  const isTableScrolledTop = (ref: HTMLDivElement | null) => ref && ref.scrollTop === 0;

  const logLevelList: SelectBoxType[] = useMemo(
    () => [
      { id: FilterLevel.ALL, value: FilterLevel.ALL },
      { id: FilterLevel.ALARMS_WARNINGS, value: FilterLevel.ALARMS_WARNINGS },
      { id: FilterLevel.ALARMS, value: FilterLevel.ALARMS },
      { id: FilterLevel.WARNINGS, value: FilterLevel.WARNINGS }
    ],
    []
  );

  const handleOptionsChanged = async (locations: Location[]) => {
    sessionStorage.setItem(StorageKeys.LOCATIONS, JSON.stringify(locations));

    setInitialLog();

    await getAllLogs(OrderBy.DESC, TAKEN_NUMBER);
  };

  const handleChooseLogLevel = async (value: string) => {
    const level = Object.values(FilterLevel).find(item => item === value);

    if (!level) return;

    setInitialLog();

    sessionStorage.setItem(StorageKeys.LOG_LEVEL, JSON.stringify(level));

    await getAllLogs(OrderBy.DESC, TAKEN_NUMBER);
  };

  useEffect(() => {
    const pollingAction = setInterval(async () => {
      if (isEmpty(logs)) {
        await getAllLogs(OrderBy.DESC, TAKEN_NUMBER);
      } else {
        const actionType = isTableScrolledTop(logHistoryTableRef.current)
          ? LatestLogActionType.APPEND
          : LatestLogActionType.CACHE;

        await getLatestLogs(actionType);
      }
    }, POLLING_TIME);

    return () => clearInterval(pollingAction);
  }, [logs]);

  const filteredLogs = logs.filter(log => log.message.toLowerCase().includes(searchQuery.toLowerCase()));

  return (
    <Wrapper>
      <ColumnSettingsDialog open={openDialog} onClose={() => setOpenDialog(false)} />

      <HeaderContent>
        <LeftContent>
          <SelectLocations onOptionsChanged={handleOptionsChanged} />

          <SelectWrapper>
            <Typography variant="body1">{t('dashboard.alarms_warning_filter.title')}</Typography>

            <Box width="12vw" minWidth="170px">
              <SelectBox
                width="100%"
                listItem={logLevelList}
                defaultValue={FilterLevel.ALL}
                onChose={handleChooseLogLevel}
              />
            </Box>

            <SearchWrapper>
              <TextField
                variant="outlined"
                placeholder={t('dashboard.search_placeholder')}
                value={searchQuery}
                onChange={e => setSearchQuery(e.target.value)}
                fullWidth
                size="small"
              />
            </SearchWrapper>
          </SelectWrapper>
        </LeftContent>

        <SecondaryButton
          width="167px"
          content={t('dashboard.column_settings.content')}
          startIcon={<Setting fill={theme.palette.primary.main} />}
          onClick={() => setOpenDialog(true)}
        />
      </HeaderContent>

      <TableWrapper>
        <LogHistory columns={columns} logs={filteredLogs} tableRef={logHistoryTableRef} />
      </TableWrapper>
    </Wrapper>
  );
};

export default Dashboard;

const { base, baseMD, rowHeightLG } = dimensions;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderContent = styled(Box)`
  width: calc(100% - 32px);
  height: ${rowHeightLG};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${theme.palette.common.white};
  padding: 0 ${baseMD};
  border-bottom: 1px solid ${theme.palette.grey[400]};
  background-color: ${theme.palette.common.white};
`;

const LeftContent = styled(Box)`
  width: 62vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2vw;
`;

const SelectWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  column-gap: ${base};
`;

const SearchWrapper = styled(Box)`
  width: 12vw;
  min-width: 170px;
`;

const TableWrapper = styled(Box)`
  width: 100%;
  height: 100%;
  overflow: auto;
`;
