import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  Typography,
  Skeleton,
  Box,
  Grow,
  Fade
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../styles/theme';
import {
  Column,
  EMPTY_STRING,
  FIRST_INDEX,
  LATEST_HIGHLIGHTED_LOG_MINUTES,
  LogHistoryColumn,
  OrderBy,
  RowPosition,
  TAKEN_NUMBER
} from '../types';
import { Log, LogLevel, UserIcon } from '../../../../models/log';

import { ReactComponent as Employee } from '../../../../assets/image/user.svg';
import { ReactComponent as Visitor } from '../../../../assets/image/visitor.svg';
import { ReactComponent as Unknown } from '../../../../assets/image/unknown.svg';
import { ReactComponent as System } from '../../../../assets/image/system.svg';
import { dimensions } from '../../../../styles/dimensions';
import { isEmpty, isEqual } from 'lodash';
import Cell from './BodyTableCell';
import { useLogHook } from '../store';
import ContextMenu, { ContextMenuItem } from '../../../../components/ContextMenu';
import { useNavigate } from 'react-router-dom';
import { SideBarItemType } from '../../../../types/route';
import moment from 'moment';
import { formatDateTime, TimeFormatType } from '../../../../utils/date';
import { CellAlignment } from '../../../../types/cell';
import { HIDE_COLUMN_CLASS_NAME } from '../../../../types/table';

const getRowLevelColor = (logLevel: LogLevel, position?: RowPosition) => {
  const { error, warning, action, common } = theme.palette;

  switch (logLevel) {
    case LogLevel.NORMAL:
      if (position && position === RowPosition.ODD) {
        return action.hover;
      } else {
        return common.white;
      }

    case LogLevel.MEDIUM:
      return warning.light;

    case LogLevel.HIGH:
    default:
      return error.light;
  }
};

const renderUserTypeIcon = (type: UserIcon) => {
  switch (type) {
    case UserIcon.EMPLOYEE:
      return <Employee />;

    case UserIcon.VISITOR:
      return <Visitor />;

    case UserIcon.SYSTEM:
      return <System fill={grey[100]} />;

    case UserIcon.UNKNOWN:
    default:
      return <Unknown />;
  }
};

const { baseMD, inputHeight } = dimensions;
const { grey, common } = theme.palette;

type Props = {
  columns: Column[];
  logs: Log[]; // Add this line to accept logs as a prop
  tableRef: React.RefObject<HTMLDivElement>;
};

type TableRowProps = {
  status: LogLevel | undefined;
};

const LogHistory: React.FunctionComponent<Props> = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { columns, logs, tableRef } = props; // Destructure logs here

  const [{ totalLogs }, { getAllLogs, updateLogsFromCache, getColumnSetting }] = useLogHook();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [distanceBottom, setDistanceBottom] = React.useState<number | undefined>();
  const [hasMore, setHasMore] = React.useState<boolean>(false);

  const [contextMenu, setContextMenu] = React.useState<{
    show: boolean;
    userIcon: UserIcon | null;
    menuPosition: { x: number; y: number };
    userId: string;
  }>({
    show: false,
    userIcon: null,
    menuPosition: { x: 0, y: 0 },
    userId: ''
  });

  const renderContextMenuItems = (type: UserIcon | null, userId: string): ContextMenuItem[] => {
    const addNewUserOption: ContextMenuItem = {
      name: t('context_menu.add_new_user'),
      onClick: (): void => {
        navigate(`users/${SideBarItemType.User}/add-new`);
      }
    };
    const editUserOption: ContextMenuItem = {
      name: t('context_menu.edit_user'),
      onClick: (): void => {
        navigate(`users/${SideBarItemType.User}/${userId}/edit`);
      }
    };
    const addNewVisitorOption: ContextMenuItem = {
      name: t('context_menu.add_new_visitor'),
      onClick: (): void => {
        navigate(`users/${SideBarItemType.Visitor}/add-new`);
      }
    };
    const editVisitorOption: ContextMenuItem = {
      name: t('context_menu.edit_visitor'),
      onClick: (): void => {
        navigate(`users/${SideBarItemType.Visitor}/${userId}/edit`);
      }
    };

    switch (type) {
      case UserIcon.VISITOR:
        return [editVisitorOption];

      case UserIcon.EMPLOYEE:
        return [editUserOption, addNewVisitorOption];

      case UserIcon.UNKNOWN:
        return [addNewUserOption, addNewVisitorOption];

      default:
        return [];
    }
  };

  const hideColumn = React.useCallback(
    (column: LogHistoryColumn) => {
      const foundColumn = columns.find(item => item.title === column && !item.display);

      return foundColumn ? HIDE_COLUMN_CLASS_NAME : EMPTY_STRING;
    },
    [columns]
  );

  const isLatestLog = (time: string) => {
    const startTime = moment(time, TimeFormatType.HOUR_MINUTE_SECOND);
    const endTime = moment(
      formatDateTime(new Date(), TimeFormatType.HOUR_MINUTE_SECOND),
      TimeFormatType.HOUR_MINUTE_SECOND
    );
    const duration = moment.duration(endTime.diff(startTime));

    return duration.asMinutes() <= LATEST_HIGHLIGHTED_LOG_MINUTES;
  };

  const isHightLightedCell = React.useCallback(
    (column: LogHistoryColumn) => {
      const visibleColumns = columns.filter(item => item.display).map(item => item.title);

      return isEqual(visibleColumns[FIRST_INDEX], column);
    },
    [columns]
  );

  const cellAlignmentValue = (column: LogHistoryColumn) =>
    isHightLightedCell(column) ? CellAlignment.CENTER : CellAlignment.LEFT;

  const onLogsChanged = async () => {
    setLoading(true);

    await getAllLogs(OrderBy.DESC, TAKEN_NUMBER);

    setLoading(false);
  };

  const loadMore = React.useCallback(async () => {
    const lastLogIndex = logs.length - 1;

    setLoading(true);

    await getAllLogs(OrderBy.DESC, TAKEN_NUMBER, logs[lastLogIndex].id);

    setLoading(false);
  }, [logs]);

  const scrollListener = React.useCallback(async () => {
    if (tableRef.current) {
      const bottom = tableRef.current.scrollHeight - tableRef.current.clientHeight;
      const isOnTop = tableRef.current.scrollTop === 0;

      if (isOnTop) {
        updateLogsFromCache();
      }

      if (!distanceBottom) {
        setDistanceBottom(Math.round((bottom / 100) * 20));
      }

      if (distanceBottom && tableRef.current.scrollTop > bottom - distanceBottom && !loading && hasMore) {
        await loadMore();
      }
    }
  }, [loadMore, hasMore, loading, distanceBottom]);

  React.useLayoutEffect(() => {
    const tableEL = tableRef.current;

    if (tableEL) {
      tableEL.addEventListener('scroll', scrollListener);
      return () => {
        tableEL.removeEventListener('scroll', scrollListener);
      };
    }
  }, [scrollListener]);

  const handleContextMenuClick = () =>
    setContextMenu(contextMenu => {
      return {
        ...contextMenu,
        show: false
      };
    });

  const getRoutePath = (userId: string | null, userIcon: UserIcon) => {
    if (!userId) return;

    switch (userIcon) {
      case UserIcon.EMPLOYEE:
        return `/users/${SideBarItemType.User}/${userId}`;

      case UserIcon.VISITOR:
        return `/users/${SideBarItemType.Visitor}/${userId}`;
      default:
        return;
    }
  };

  React.useEffect(() => {
    getColumnSetting();

    window.addEventListener('click', handleContextMenuClick);
    if (isEmpty(logs)) {
      async () => await onLogsChanged();
    }
    return () => window.removeEventListener('click', handleContextMenuClick);
  }, []);

  React.useEffect(() => {
    setHasMore(!isEqual(logs.length, totalLogs));
  }, [logs, totalLogs]);

  // Sorteer logs op tijd in aflopende volgorde (nieuwste eerst)
  const sortedLogs = React.useMemo(() => {
    return logs.slice().sort((a, b) => moment(b.time).diff(moment(a.time)));
  }, [logs]);

  const showSkeleton = loading && hasMore;

  return (
    <Container ref={tableRef}>
      <StyledTable>
        <TableHeader>
          <TableRow>
            {columns.map((item, index) => (
              <Fade in={true} key={index}>
                <TableCell align={cellAlignmentValue(item.title)} className={hideColumn(item.title)}>
                  <Typography variant="subtitle2">{item.title}</Typography>
                </TableCell>
              </Fade>
            ))}
          </TableRow>
        </TableHeader>
        <Body>
          {sortedLogs.map(log => {
            const checkHighlightedCell = (column: LogHistoryColumn) =>
              isLatestLog(log.time) && isHightLightedCell(column);

            return (
              <Grow in={true} key={log.id}>
                <Row
                  status={log.logLevel}
                  onContextMenu={e => {
                    e.preventDefault();
                  }}>
                  <Cell
                    width="74px"
                    isHighlightedCell={checkHighlightedCell(LogHistoryColumn.TYPE)}
                    value={renderUserTypeIcon(log.userIcon)}
                    align={CellAlignment.CENTER}
                    className={hideColumn(LogHistoryColumn.TYPE)}
                  />

                  <Cell
                    width="162px"
                    isHighlightedCell={checkHighlightedCell(LogHistoryColumn.NAME)}
                    value={log.name}
                    link={getRoutePath(log.uid, log.userIcon)}
                    align={cellAlignmentValue(LogHistoryColumn.NAME)}
                    className={hideColumn(LogHistoryColumn.NAME)}
                    onContextMenu={e => {
                      setContextMenu({
                        show: true,
                        userIcon: log.userIcon,
                        menuPosition: {
                          x: e.pageX,
                          y: e.pageY
                        },
                        userId: log.uid || ''
                      });
                    }}
                  />

                  <Cell
                    value={log.time}
                    className={hideColumn(LogHistoryColumn.TIME)}
                    isHighlightedCell={checkHighlightedCell(LogHistoryColumn.TIME)}
                    align={cellAlignmentValue(LogHistoryColumn.TIME)}
                  />

                  <Cell
                    width="550px"
                    value={log.message}
                    className={hideColumn(LogHistoryColumn.MESSAGE)}
                    isHighlightedCell={checkHighlightedCell(LogHistoryColumn.MESSAGE)}
                    align={cellAlignmentValue(LogHistoryColumn.MESSAGE)}
                  />

                  <Cell
                    value={log.doorName}
                    className={hideColumn(LogHistoryColumn.DOOR)}
                    isHighlightedCell={checkHighlightedCell(LogHistoryColumn.DOOR)}
                    align={cellAlignmentValue(LogHistoryColumn.DOOR)}
                  />

                  <Cell
                    width="146px"
                    value={log.doorLocation}
                    className={hideColumn(LogHistoryColumn.DOOR_LOCATION)}
                    isHighlightedCell={checkHighlightedCell(LogHistoryColumn.DOOR_LOCATION)}
                    align={cellAlignmentValue(LogHistoryColumn.DOOR_LOCATION)}
                  />

                  <Cell
                    value={log.department}
                    className={hideColumn(LogHistoryColumn.DEPARTMENT)}
                    isHighlightedCell={checkHighlightedCell(LogHistoryColumn.DEPARTMENT)}
                    align={cellAlignmentValue(LogHistoryColumn.DEPARTMENT)}
                  />

                  <Cell
                    value={log.room}
                    className={hideColumn(LogHistoryColumn.ROOM)}
                    isHighlightedCell={checkHighlightedCell(LogHistoryColumn.ROOM)}
                    align={cellAlignmentValue(LogHistoryColumn.ROOM)}
                  />

                  <Cell
                    width="80px"
                    value={log.carPlate}
                    className={hideColumn(LogHistoryColumn.CAR_PLATE)}
                    isHighlightedCell={checkHighlightedCell(LogHistoryColumn.CAR_PLATE)}
                    align={cellAlignmentValue(LogHistoryColumn.CAR_PLATE)}
                  />
                </Row>
              </Grow>
            );
          })}
        </Body>
      </StyledTable>
      <ContextMenu
        show={contextMenu.show}
        menuItems={renderContextMenuItems(contextMenu.userIcon, contextMenu.userId)}
        menuPosition={contextMenu.menuPosition}></ContextMenu>

      {showSkeleton && (
        <Box width="98%" marginLeft="1%">
          <Skeleton height={inputHeight} />
          <Skeleton height={inputHeight} animation="wave" />
          <Skeleton height={inputHeight} animation={false} />
        </Box>
      )}
    </Container>
  );
};

export default LogHistory;

const TableHeader = styled(TableHead)`
  height: ${inputHeight};
  padding: 0 ${baseMD};
  background: ${common.white};
  position: sticky;
  top: 0;
  z-index: 1;
  font-size: 16px;
  font-weight: 700;
`;

const Container = styled(TableContainer)`
  height: 100%;
`;

const Row = styled(TableRow)(({ status }: TableRowProps) => ({
  position: 'relative',
  zIndex: '0',
  backgroundColor: status && `${getRowLevelColor(status)}`,

  '&:nth-of-type(odd)': {
    backgroundColor: status && `${getRowLevelColor(status, RowPosition.ODD)}`
  },

  '&:last-child td, &:last-child th': {
    border: 0
  }
}));

const Body = styled(TableBody)`
  position: relative;
`;

const StyledTable = styled(Table)`
  tr > .${HIDE_COLUMN_CLASS_NAME} {
    display: none;
  }
`;
