import React from 'react';
import { useTranslation } from 'react-i18next';
import { DateInput } from '../../../../components/Input';
import { formatDateTime, TimeFormatType } from '../../../../utils/date';
import { FilterOptionsWrapper } from '../../components';
import { ReportLogFormField } from '../../types';

const { START_DATE, END_DATE } = ReportLogFormField;

const DATE_INPUT_WIDTH = '58%';
const DATE_INPUT_LABEL_WIDTH = '160px';

const TimeSelect: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const currentDate = formatDateTime(new Date(), TimeFormatType.MONTH_DATE_YEAR);

  return (
    <FilterOptionsWrapper width="30%" minWidth="350px">
      <DateInput
        inputWidth={DATE_INPUT_WIDTH}
        labelWidth={DATE_INPUT_LABEL_WIDTH}
        name={START_DATE}
        label={t('report.log_history.filter_option.start_date')}
        defaultValue={currentDate}
      />
      <DateInput
        inputWidth={DATE_INPUT_WIDTH}
        labelWidth={DATE_INPUT_LABEL_WIDTH}
        name={END_DATE}
        label={t('report.log_history.filter_option.end_date')}
        defaultValue={currentDate}
      />
    </FilterOptionsWrapper>
  );
};

export default TimeSelect;
