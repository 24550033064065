import React from 'react';
import { Box, Typography, Select, MenuItem } from '@mui/material';

interface SelectBoxWithLabelProps {
  name: string;
  label: string;
  options: Array<{
    id: string;
    value: string;
    label: string;
  }>;
}

export const SelectBoxWithLabel: React.FunctionComponent<SelectBoxWithLabelProps> = ({ name, label, options }) => {
  return (
    <Box>
      <Typography variant="subtitle2">{label}</Typography>
      <Select name={name} fullWidth>
        {options.map(option => (
          <MenuItem key={option.id} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};
