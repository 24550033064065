import { ThemeProvider } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import { AppContextProvider } from './appContext';
import { ToastMuiContainer } from './components/Toast/Container';
import './index.css';
import './languages'; // Zorg ervoor dat de i18n configuratie wordt geïmporteerd
import i18n from './languages';
import { theme } from './styles/theme';

// Controleer en stel de standaardtaal in als deze niet is ingesteld
const storedLanguage = localStorage.getItem('i18nextLng');
if (!storedLanguage) {
  localStorage.setItem('i18nextLng', 'en');
  i18n.changeLanguage('en');
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppContextProvider>
        <ToastMuiContainer />
        <App />
      </AppContextProvider>
    </ThemeProvider>
  </React.StrictMode>
);
