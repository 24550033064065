import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectBoxWithLabel } from '../../components/SelectBox';
import { FilterOptionsWrapper } from '../../components';
import { ReportLogFormField } from '../../types';
import { $get } from '../../../../utils/http';

const { SELECT_LOCATION } = ReportLogFormField;

interface LocationOption {
  id: string;
  value: string;
  label: string;
}

interface LogData {
  id: string;
  uid: string;
  name: string;
  doorName: string;
  doorLocation: string | null;
  doorlocationId: string | null;
  department: string;
  timeDate: string;
}

interface LogResponse {
  data: LogData[];
  code: string;
  message: string | null;
  total: number;
}

interface PlaceSelectProps {
  name: string;
}

const PlaceSelect: React.FunctionComponent<PlaceSelectProps> = ({ name }) => {
  const { t } = useTranslation();
  const [locations, setLocations] = useState<LocationOption[]>([
    {
      id: '',
      value: '',
      label: t('report.log_history.filter_option.all_locations')
    }
  ]);

  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response: LogResponse = await $get('log/user');
        const validLogs = response.data.filter(log => log.doorlocationId !== null);

        const uniqueLocations = Array.from(
          new Map<string, LocationOption>(
            validLogs.map((log: LogData) => {
              const locationEntry: [string, LocationOption] = [
                log.doorlocationId!,
                {
                  id: log.doorlocationId!,
                  value: log.doorlocationId!,
                  label: log.doorLocation || log.doorName
                }
              ];
              return locationEntry;
            })
          ).values()
        );

        setLocations([
          {
            id: '',
            value: '',
            label: t('report.log_history.filter_option.all_locations')
          },
          ...uniqueLocations
        ]);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    };

    fetchLocations();
  }, [t]);

  return (
    <FilterOptionsWrapper width="30%" minWidth="350px">
      <SelectBoxWithLabel name={name} label={t('report.log_history.filter_option.location')} options={locations} />
    </FilterOptionsWrapper>
  );
};

export default PlaceSelect;
