import { Box, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useMatch } from 'react-router';
import { theme } from '../../../styles/theme';
import { LocationRouteEndpoints } from '../../../types/locationRouteEndpoints';
import { SideBarItemType } from '../../../types/route';
import LocationList from './components/LocationList';
import NoticeMessage from './components/NoticeMessage';
import { BLANK_STRING } from './type';
import { LocationInfo } from './LocationInfo';

const LocationET: React.FunctionComponent = () => {
  // Update the match to check for the emergencyTeam location path
  const match = useMatch(`/${SideBarItemType.EmergencyTeam}/location/:id/*`);

  const { t } = useTranslation();

  const locationId = match?.params.id;

  const getLocationId = (id?: string) =>
    !id || [LocationRouteEndpoints.ADD_NEW as string].includes(id) ? BLANK_STRING : id;

  return (
    <Wrapper>
      <LocationList id={getLocationId(locationId)} />

      <Routes>
        {/* Update the routes to use the emergencyTeam location path */}
        <Route path="location/:id/" element={<LocationInfo />} />
        <Route path="location/" element={<NoticeMessage message={t('location.view_details')} />} />
      </Routes>
    </Wrapper>
  );
};

export default LocationET;

const Wrapper = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: ${theme.palette.grey[500]};
`;
