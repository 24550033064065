import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { theme } from '../styles/theme';

type Props = {
  columns: Column[];
  /** Array of simple object string key - primitive type value */
  dataList: Record<string, string | number | Date>[];
  setDataColor?: (colName: string, rowData: string | number | Date) => string | undefined;
  cellMaxWidth?: string;
};

export type Column = {
  /** Key name used to get data value from data list, must be valid property name */
  dataKey: string;
  /** Present for the column name */
  fieldName: string;
  align?: 'left' | 'center' | 'right';
  onCellClick?: () => void;
};

const dataGetter = (data: Record<string, string | number | Date>, id: string): any => {
  return data[id];
};

export const CustomTable: React.FunctionComponent<Props> = (props: Props) => {
  const { columns, dataList, cellMaxWidth, setDataColor } = props;

  return (
    <Container>
      <TableWrapper stickyHeader>
        <TableHead>
          <TableRow>
            {columns.map(column => (
              <TableTitle key={column.dataKey} align={column.align}>
                {column.fieldName}
              </TableTitle>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {dataList &&
            dataList.map((info, index) => (
              <RowWrapper key={index}>
                {columns.map(column => (
                  <RowContent
                    key={column.dataKey}
                    align={column.align}
                    cellmaxwidth={cellMaxWidth}
                    color={setDataColor && setDataColor(column.dataKey, dataGetter(info, column.dataKey))}
                    onClick={() => column.onCellClick && column.onCellClick()}>
                    {dataGetter(info, column.dataKey)}
                  </RowContent>
                ))}
              </RowWrapper>
            ))}
        </TableBody>
      </TableWrapper>
    </Container>
  );
};

const { grey } = theme.palette;

const Container = styled(TableContainer)`
  border: 1px solid ${grey[400]};
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  overflow: auto;
`;

const TableTitle = styled(TableCell)<{ align?: string }>(({ align }) => ({
  color: `${grey[100]}`,
  fontWeight: 700,
  fontSize: '16px',
  textAlign: align
}));

const TableWrapper = styled(Table)`
  height: 100%;
  ${props => props.theme.breakpoints.down('xl')} {
    min-height: 300px;
    max-height: 300px;
  }
`;

const RowContent = styled(TableCell)<{ cellmaxwidth?: string; color?: string; align?: string }>(
  ({ cellmaxwidth, color, align }) => ({
    fontWeight: 500,
    fontSize: '14px',
    maxWidth: cellmaxwidth,
    color,
    textAlign: align
  })
);

const RowWrapper = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: ${grey[500]};
  }
`;
